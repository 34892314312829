import { FC, memo } from 'react'
import { StyledSelect } from './Select.style'
import { MenuItem } from '@mui/material'

import { ISelectProps } from './Select.types'

export const Select: FC<ISelectProps> = memo(({ options, ...props }) => {
  return (
    <StyledSelect {...props}>
      {options.map((opt) => {
        return (
          <MenuItem key={opt.value} value={opt.value}>
            {opt.name}
          </MenuItem>
        )
      })}
    </StyledSelect>
  )
})
