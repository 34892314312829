import { styled } from '@mui/system'
import { IStyledFormRowProps } from './FormRow.types'

export const StyledFormRow = styled('label')<IStyledFormRowProps>(
  ({ type = 'column' }) => ({
    display: 'flex',
    flexDirection: `${type}`,
    gap: `${type === 'row' ? '12px' : '10px'}`,
    alignItems: `${type === 'row' ? 'center' : ''}`
  })
)

export const StyledFormRowLabel = styled('span')(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 600,
  paddingLeft: `16px`,
  color: theme.palette.customColors.black
}))
