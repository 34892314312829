import { Typography } from '@mui/material'
import { styled } from '@mui/system'

export const StyledTeacherContent = styled('div')(() => ({
  marginTop: '20px'
}))

export const StyledTeacherRow = styled('div')(() => ({
  display: 'grid',
  columnCount: 3,
  gridTemplateColumns: '192px 2fr 1fr',
  gap: '6px'
}))

export const StyledTeacherImgContainer = styled('div')(() => ({
  gridColumn: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}))

export const StyledTeacherImg = styled('img')(({ theme }) => ({
  width: '192px',
  flexShrink: '0',
  borderRadius: '8px',
  overflow: 'hidden',
  display: 'block',
  backgroundColor: theme.palette.customColors.white,
  minHeight: '90px',
  maxHeight: '200px',
  marginBottom: 'auto'
}))

export const StyledTeacherInfo = styled('div')(() => ({
  gridColumn: 2,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginLeft: '18px',
  padding: '12px 0px'
}))

export const StyledTeacherInfoTitleContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column'
}))

export const StyledTeacherInfoTitle = styled(Typography)(() => ({
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '24px',
  marginBottom: '12px'
}))

export const StyledTeacherInfoSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '16px',
  color: theme.palette.customColors.gray
}))

export const StyledTeacherActions = styled('div')(() => ({
  gridColumn: 3,
  display: 'flex',
  justifyContent: 'flex-end'
}))

export const StyledTeacherInfoItem = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'baseline'
}))

export const StyledTeacherInfoItemLabel = styled('div')(({ theme }) => ({
  fontSize: '16px',
  fontWeight: '600',
  lineHeight: '20px',
  color: theme.palette.customColors.grayMedium,
  marginRight: '16px'
}))

export const StyledTeacherInfoItemContent = styled('div')(({ theme }) => ({
  minWidth: '180px',
  fontSize: '16px',
  fontWeight: '600',
  lineHeight: '20px',
  color: theme.palette.customColors.black,

  '& a': {
    color: theme.palette.customColors.black,
    '&:hover': {
      textDecoration: 'none'
    }
  }
}))

export const StyledTeacherClassesContainer = styled('div')(({ theme }) => ({
  gridColumn: '2/4',
  display: 'grid',
  columnCount: 2,
  gridTemplateColumns: '2fr 1fr',
  padding: '16px 18px',
  margin: '32px 0 12px',
  border: `1px solid ${theme.palette.customColors.linear}`,
  borderRadius: '16px',
  backgroundColor: theme.palette.customColors.white
}))

export const StyledTeacherClassItemsContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'shouldMarginBottom'
})(({ shouldMarginBottom }: { shouldMarginBottom: boolean }) => ({
  marginBottom: shouldMarginBottom ? '6px' : '0'
}))

export const StyledTeacherClassItems = styled('div')(() => ({}))

export const StyledTeacherClassItem = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '6px 0 6px 8px'
}))

export const StyledTeacherClassItemLabel = styled('div')(() => ({
  marginRight: '12px'
}))
