import { memo } from 'react'
import { StyledLogo, StyledNavMenu } from './styles'
import { CustomSvgIcon } from '../../../../components/CustomSvgIcon'
import AdminSidebar from '../AdminSidebar'

const Sidebar = memo(() => {
  return (
    <StyledNavMenu>
      <div style={{ position: 'fixed' }}>
        <StyledLogo>
          <CustomSvgIcon
            path={'/assets/logo.svg'}
            title={'Beyond Words Logo'}
          />
        </StyledLogo>
        <AdminSidebar />
      </div>
    </StyledNavMenu>
  )
})

export default Sidebar
