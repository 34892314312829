import { FormikHelpers } from 'formik/dist/types'
import {
  createUnit,
  IUnitLogoResponse,
  IUnitStatus,
  patchUnit,
  uploadUnitLogo
} from '../../../../services'
import customToast from '../../../../components/CustomToast'
import { useAppDispatch } from '../../../../hooks'
import { useCallback } from 'react'
import { IUnitForm, IUnitFormProps } from '../UnitForm.types'

type FormCbValues = Omit<IUnitForm, 'logoPath' | 'logoUrl'> & {
  logoPath: string | undefined
}

export const useUnitSubmit: (
  params: Omit<IUnitFormProps, 'onClose'>
) => (
  values: IUnitForm,
  formikHelpers: FormikHelpers<IUnitForm>
) => void | Promise<any> = ({ initialValues, onSuccess, courseId }) => {
  const dispatch = useAppDispatch()

  const pathUnitCb = useCallback(
    ({ draft, ...values }: FormCbValues) => {
      if (!initialValues?.id) return

      dispatch(
        patchUnit({
          id: initialValues.id,
          courseId,
          status: draft ? IUnitStatus.draft : IUnitStatus.published,
          ...values
        })
      ).then((params) => {
        if (params.meta.requestStatus === 'fulfilled') {
          customToast({
            type: 'success',
            message: `Unit "${values.name}" was update successful`
          })
          onSuccess && onSuccess()
        }
      })
    },
    [initialValues]
  )

  const createUnitCb = useCallback(({ draft, ...values }: FormCbValues) => {
    dispatch(
      createUnit({
        courseId,
        status: draft ? IUnitStatus.draft : IUnitStatus.published,
        ...values
      })
    ).then((params) => {
      if (params.meta.requestStatus === 'fulfilled') {
        customToast({
          type: 'success',
          message: `Unit "${values.name}" create successful`
        })
        onSuccess && onSuccess()
      }
    })
  }, [])

  return useCallback(
    (values) => {
      if (values.logoPath instanceof File) {
        dispatch(
          uploadUnitLogo({
            file: values.logoPath
          })
        ).then((params) => {
          if (params.meta.requestStatus === 'fulfilled' && params.payload) {
            const payload = params.payload as IUnitLogoResponse
            const logoPath = payload.logoPath

            const reqParams = {
              name: values.name,
              draft: values.draft,
              logoPath: logoPath
            }

            if (initialValues?.id) {
              pathUnitCb(reqParams)
            } else {
              createUnitCb(reqParams)
            }
          }
        })

        return
      }

      const reqParams = {
        name: values.name,
        draft: values.draft,
        logoPath: values.logoPath
      }

      if (initialValues?.id) {
        pathUnitCb(reqParams)
      } else {
        createUnitCb(reqParams)
      }
    },
    [initialValues, onSuccess]
  )
}
