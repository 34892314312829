import { FC } from 'react'
import {
  StyledTitle,
  StyledTitleActionContainers,
  StyledTitleBackContainers,
  StyledTitleContainer
} from './PageTitle.style'
import { IPageTitleProps } from './PageTitle.types'

export const PageTitle: FC<IPageTitleProps> = ({
  children,
  variant = 'h2',
  actions,
  back
}) => {
  return (
    <StyledTitleContainer>
      {!!back && <StyledTitleBackContainers>{back}</StyledTitleBackContainers>}
      <StyledTitle variant={variant}>{children}</StyledTitle>
      {!!actions && (
        <StyledTitleActionContainers>{actions}</StyledTitleActionContainers>
      )}
    </StyledTitleContainer>
  )
}
