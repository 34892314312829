import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAdminSchoolsState } from './schools.types'
import {
  createSchool,
  getAdminSchoolCountries,
  getAdminSchools,
  getSchool,
  IGetAdminSchoolsCountriesResponse,
  IGetAdminSchoolsResponse,
  patchSchool
} from '../../services'

const initialState: IAdminSchoolsState = {
  isPendingSchoolFrom: false,
  school: {
    isPending: false,
    isNeedRefetch: false,
    data: undefined
  },
  schools: {
    isPending: false,
    isNeedRefetch: false,
    data: {
      schools: [],
      count: 0
    }
  },
  countries: {
    isPending: false,
    data: {
      countries: []
    }
  }
}

export const schoolsSlice = createSlice({
  name: 'schools',
  initialState,
  reducers: {
    setIsNeedRefetchSchools: (state, { payload }: PayloadAction<boolean>) => {
      state.schools.isNeedRefetch = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAdminSchools.pending, (state) => {
      state.schools.isPending = true
    })
    builder.addCase(
      getAdminSchools.fulfilled,
      (state, { payload }: PayloadAction<IGetAdminSchoolsResponse>) => {
        state.schools.data.schools = payload.data ?? []
        state.schools.data.count = payload.count ?? 0
        state.schools.isPending = false
      }
    )
    builder.addCase(getAdminSchools.rejected, (state) => {
      state.schools.isPending = false
    })

    builder.addCase(getAdminSchoolCountries.pending, (state) => {
      state.countries.isPending = true
    })
    builder.addCase(
      getAdminSchoolCountries.fulfilled,
      (
        state,
        { payload }: PayloadAction<IGetAdminSchoolsCountriesResponse>
      ) => {
        state.countries.data.countries = payload ?? []
        state.countries.isPending = false
      }
    )
    builder.addCase(getAdminSchoolCountries.rejected, (state) => {
      state.countries.isPending = false
    })

    builder.addCase(getSchool.pending, (state) => {
      state.school.isPending = true
    })
    builder.addCase(getSchool.fulfilled, (state, { payload }) => {
      state.school.data = payload
      state.school.isPending = false
    })
    builder.addCase(getSchool.rejected, (state) => {
      state.school.isPending = false
    })

    builder.addCase(createSchool.pending, (state) => {
      state.isPendingSchoolFrom = true
    })
    builder.addCase(createSchool.fulfilled, (state) => {
      state.isPendingSchoolFrom = false
    })
    builder.addCase(createSchool.rejected, (state) => {
      state.isPendingSchoolFrom = false
    })

    builder.addCase(patchSchool.pending, (state) => {
      state.isPendingSchoolFrom = true
    })
    builder.addCase(patchSchool.fulfilled, (state) => {
      state.isPendingSchoolFrom = false
    })
    builder.addCase(patchSchool.rejected, (state) => {
      state.isPendingSchoolFrom = false
    })
  }
})

export const { reducer: schoolsReducer, actions: schoolsActions } = schoolsSlice
