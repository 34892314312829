import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAdminClassesState } from './adminClasses.types'
import {
  createAdminClass,
  getAdminClass,
  getAdminClasses,
  getSchools,
  getStudents,
  getTeachers,
  IBaseSchool,
  IBaseStudent,
  IBaseTeacher,
  ICommonResponse,
  patchAdminClass
} from '../../services'

const initialState: IAdminClassesState = {
  isPendingAdminClassesFrom: false,
  adminClass: {
    isPending: false,
    data: undefined
  },
  adminClasses: {
    isPending: false,
    isNeedRefetch: false,
    data: {
      adminClasses: [],
      count: 0
    }
  },
  schools: {
    isPending: false,
    data: {
      schools: []
    }
  },
  teachers: {
    isPending: false,
    data: {
      teachers: []
    }
  },
  students: {
    isPending: false,
    data: {
      students: []
    }
  }
}

export const adminClassesSlice = createSlice({
  name: 'adminClasses',
  initialState,
  reducers: {
    setIsNeedRefetchAdminClasses: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.adminClasses.isNeedRefetch = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAdminClasses.pending, (state) => {
      state.adminClasses.isPending = true
    })
    builder.addCase(getAdminClasses.fulfilled, (state, { payload }) => {
      state.adminClasses.data.adminClasses = payload.data ?? []
      state.adminClasses.data.count = payload.count ?? 0
      state.adminClasses.isPending = false
    })
    builder.addCase(getAdminClasses.rejected, (state) => {
      state.adminClasses.isPending = false
    })

    builder.addCase(getAdminClass.pending, (state) => {
      state.adminClass.isPending = true
    })
    builder.addCase(getAdminClass.fulfilled, (state, { payload }) => {
      state.adminClass.data = {
        name: payload.name,
        school: payload.school,
        teachers: payload.teachers.map((teacher) => ({
          name: teacher.fullName,
          value: teacher.id
        })),
        students: payload.students.map((student) => ({
          name: student.fullName,
          value: student.id
        }))
      }

      state.adminClass.isPending = false
    })
    builder.addCase(getAdminClass.rejected, (state) => {
      state.adminClass.isPending = false
    })

    builder.addCase(getSchools.pending, (state) => {
      state.schools.isPending = true
    })
    builder.addCase(
      getSchools.fulfilled,
      (state, { payload }: PayloadAction<ICommonResponse<IBaseSchool>>) => {
        state.schools.data.schools = payload.data ?? []
        state.schools.isPending = false
      }
    )
    builder.addCase(getSchools.rejected, (state) => {
      state.schools.isPending = false
    })

    builder.addCase(getTeachers.pending, (state) => {
      state.teachers.isPending = true
    })
    builder.addCase(
      getTeachers.fulfilled,
      (state, { payload }: PayloadAction<ICommonResponse<IBaseTeacher>>) => {
        state.teachers.data.teachers = payload.data ?? []
        state.teachers.isPending = false
      }
    )
    builder.addCase(getTeachers.rejected, (state) => {
      state.teachers.isPending = false
    })

    builder.addCase(getStudents.pending, (state) => {
      state.students.isPending = true
    })
    builder.addCase(
      getStudents.fulfilled,
      (state, { payload }: PayloadAction<ICommonResponse<IBaseStudent>>) => {
        state.students.data.students = payload.data ?? []
        state.students.isPending = false
      }
    )
    builder.addCase(getStudents.rejected, (state) => {
      state.students.isPending = false
    })

    builder.addCase(createAdminClass.pending, (state) => {
      state.isPendingAdminClassesFrom = true
    })
    builder.addCase(createAdminClass.fulfilled, (state) => {
      state.isPendingAdminClassesFrom = false
    })
    builder.addCase(createAdminClass.rejected, (state) => {
      state.isPendingAdminClassesFrom = false
    })

    builder.addCase(patchAdminClass.pending, (state) => {
      state.isPendingAdminClassesFrom = true
    })
    builder.addCase(patchAdminClass.fulfilled, (state) => {
      state.isPendingAdminClassesFrom = false
    })
    builder.addCase(patchAdminClass.rejected, (state) => {
      state.isPendingAdminClassesFrom = false
    })
  }
})

export const { reducer: adminClassesReducer, actions: adminClassesActions } =
  adminClassesSlice
