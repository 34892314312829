import { styled as muiStyled } from '@mui/system'
import { NavLink } from 'react-router-dom'

export const StyledSidebarItem = muiStyled('div')<any>(
  ({ theme, isActive }) => ({
    display: 'flex',
    flexDirection: 'row',
    boxSizing: 'border-box',
    alignItems: 'center',
    padding: '8px 12px',
    marginTop: '32px',
    width: '168px',
    backgroundColor: isActive ? theme.palette.customColors.blue : '',
    borderRadius: '20px'
  })
)

export const StyledNavLink = muiStyled(NavLink)(({ theme }) => ({
  color: theme.palette.customColors.white,
  textDecoration: 'none',
  marginLeft: '8px',
  display: 'flex',
  width: '100%',
  alignItems: 'center',
  '& :first-of-type': {
    marginRight: '8px'
  }
}))
