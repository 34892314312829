import { FC, useCallback, useEffect, useMemo } from 'react'
import { Box, Page, PageTitle } from '../../components/_Page'
import { StyledBackButton } from '../../components/CustomTable/styles'
import { useNavigate, useParams } from 'react-router-dom'
import { APP_ROUTES } from '../../core/router/appRoutes'
import { TeacherForm } from '../_Common'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { getAdminTeacher } from '../../services'
import { editTeacherFormSchema } from '../_Common/TeacherForm/schemas/TeacherForm.schema'

export const EditTeacher: FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const queryParams = useParams()

  const teacherUsername = queryParams.username!

  const { isPending: isPendingTeacher, data: teacher } = useAppSelector(
    (state) => state.teachers.teacher
  )

  const handleBackButton = useCallback(() => {
    navigate(`${APP_ROUTES.TEACHERS}`)
  }, [])

  const onSuccessHandler = useCallback(() => {
    navigate(`${APP_ROUTES.TEACHERS}`)
  }, [])

  const validationSchema = useMemo(
    () => editTeacherFormSchema(!!teacher?.email),
    [teacher]
  )

  useEffect(() => {
    dispatch(getAdminTeacher({ username: teacherUsername }))
  }, [teacherUsername])

  if (isPendingTeacher || !teacher) {
    return <Page />
  }

  return (
    <Box>
      <PageTitle
        back={
          <StyledBackButton
            aria-label={'Previous page button'}
            onClick={handleBackButton}
          />
        }
      >
        Edit a Teacher
      </PageTitle>
      <TeacherForm
        type="edit"
        validationSchema={validationSchema}
        onSuccess={onSuccessHandler}
        initialValues={{
          id: teacher.id,
          username: teacher.username,
          avatarPath: teacher.avatarUrl,
          fullName: teacher.fullName,
          email: teacher.email || '',
          schoolIds: teacher.schools.map((school) => school.id),
          classIds: teacher.classes.map((classItem) => classItem.id)
        }}
      />
    </Box>
  )
}
