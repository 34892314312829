import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Dropdown,
  ITooltipItemsProps,
  TooltipItems
} from '../../../components/_UI'
import { ICourseViewTable } from '../CourseView.types'
import { IUnitShort } from '../../../services'
import { ICoursesTable } from '../../Courses/Courses.types'

export const useCourseViewTableRows = (
  units: IUnitShort[],
  options: {
    editUnit: (id: string) => void
    deleteUnit: (id: string) => void
  }
) => {
  const navigate = useNavigate()

  const { editUnit, deleteUnit } = options

  return useMemo(
    () =>
      units.map((unit) => {
        const { name, activitiesCount, id, status } = unit

        const tooltipItems: ITooltipItemsProps['items'] = [
          {
            type: 'button',
            title: 'delete',
            handler: () => deleteUnit(id)
          },
          {
            type: 'button',
            title: 'edit',
            handler: () => editUnit(id)
          }
        ]

        const result: ICourseViewTable & {
          rowAction: () => void
          excludeAction: (keyof ICoursesTable)[]
        } = {
          status,
          name,
          activityCounts: activitiesCount,
          rowAction: () => navigate(`/units/${id}`),
          excludeAction: ['actions'],

          actions: (
            <Dropdown>
              <TooltipItems items={tooltipItems} />
            </Dropdown>
          )
        }

        return result
      }),
    [units]
  )
}
