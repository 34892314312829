import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  createActivity,
  getActivities,
  getActivity,
  patchActivity
} from '../../services'
import { IActivitiesState } from './activities.types'

const initialState: IActivitiesState = {
  isPendingActivityForm: false,
  activity: {
    isPending: false,
    isNeedRefetch: false,
    data: undefined
  },
  activities: {
    isPending: false,
    isNeedRefetch: false,
    data: {
      count: 0,
      activities: []
    }
  }
}

export const activitySlice = createSlice({
  name: 'activity',
  initialState,
  reducers: {
    setIsNeedRefetchActivity: (state, { payload }: PayloadAction<boolean>) => {
      state.activity.isNeedRefetch = payload
    },

    setIsNeedRefetchActivities: (
      state,
      { payload }: PayloadAction<boolean>
    ) => {
      state.activities.isNeedRefetch = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getActivities.pending, (state) => {
      state.activities.isPending = true
    })
    builder.addCase(getActivities.fulfilled, (state, { payload }) => {
      state.activities.data.activities = payload.data
      state.activities.data.count = payload.count
      state.activities.isPending = false
    })
    builder.addCase(getActivities.rejected, (state) => {
      state.activities.isPending = false
    })

    builder.addCase(getActivity.pending, (state) => {
      state.activity.isPending = true
    })
    builder.addCase(getActivity.fulfilled, (state, { payload }) => {
      state.activity.data = payload
      state.activity.isPending = false
    })
    builder.addCase(getActivity.rejected, (state) => {
      state.activity.isPending = false
    })

    builder.addCase(createActivity.pending, (state) => {
      state.isPendingActivityForm = true
    })
    builder.addCase(createActivity.fulfilled, (state) => {
      state.isPendingActivityForm = false
    })
    builder.addCase(createActivity.rejected, (state) => {
      state.isPendingActivityForm = false
    })

    builder.addCase(patchActivity.pending, (state) => {
      state.isPendingActivityForm = true
    })
    builder.addCase(patchActivity.fulfilled, (state) => {
      state.isPendingActivityForm = false
    })
    builder.addCase(patchActivity.rejected, (state) => {
      state.isPendingActivityForm = false
    })
  }
})

export const { reducer: activityReducer, actions: activityActions } =
  activitySlice
