import { FC, memo, useCallback, useMemo, useRef } from 'react'
import CustomModal from '../../../../components/CustomModal'
import { StyledIFrame } from './QuestionPreviewModal.style'
import Config from '../../../../config'
import { IQuestionFormQuestion } from '../QuestionForm/QuestionForm.type'
import { Language } from '../../../../types'

interface Props {
  toggle: (newQuestion: IQuestionFormQuestion | null) => void
  language: Language
  question: IQuestionFormQuestion
}

const QuestionPreviewModal: FC<Props> = ({ toggle, question, language }) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null)
  const src = useMemo(() => `${Config.vrUrl}/vr/question/preview`, [])

  const prepareAnswers = ({ answers, wrongAnswers }: IQuestionFormQuestion) => {
    return [
      ...answers.map((answer) => ({
        ...answer,
        isCorrect: true
      })),
      ...wrongAnswers.map((answer) => ({
        ...answer,
        isCorrect: false
      }))
    ]
  }

  const onLoad = () => {
    if (iframeRef.current && iframeRef.current.contentWindow) {
      const data = {
        text: question.text,
        answers: prepareAnswers(question),
        hintText: question.hintText,
        language
      }

      iframeRef.current.contentWindow.postMessage(
        {
          key: 'question',
          data
        },
        '*'
      )
    }
  }

  const handleClose = useCallback(() => toggle(null), [toggle])

  return (
    <CustomModal
      open
      title="Question Preview"
      handleClose={handleClose}
      width="90%"
    >
      <StyledIFrame
        src={src}
        ref={iframeRef}
        onLoad={onLoad}
        allowFullScreen={true}
      />
    </CustomModal>
  )
}

export default memo(QuestionPreviewModal)
