import { useCallback, useEffect, useState } from 'react'
import { deleteAdminClass, getAdminClass } from '../../../services'
import { useNavigate, useParams } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { APP_ROUTES } from '../../../core/router/appRoutes'
import { adminClassesActions } from '../../../store/adminClasses/adminClasses.reducer'

export const useAdminClassView = () => {
  const dispatch = useAppDispatch()
  const queryParams = useParams()
  const navigate = useNavigate()

  const [showAllStudents, setShowAllStudents] = useState(false)
  const [showAllTeachers, setShowAllTeachers] = useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)

  const { isPendingAdminClass, adminClass } = useAppSelector((state) => {
    return {
      isPendingAdminClass: state.adminClasses.adminClass.isPending,
      adminClass: state.adminClasses.adminClass.data
    }
  })

  const showStudentsHandler = useCallback(() => {
    setShowAllStudents(!showAllStudents)
  }, [showAllStudents])

  const showTeachersHandler = useCallback(() => {
    setShowAllTeachers(!showAllTeachers)
  }, [showAllTeachers])

  const onBackHandler = useCallback(() => {
    navigate(APP_ROUTES.ADMIN_CLASSES)
  }, [])

  const onEditHandler = useCallback(() => {
    navigate(`${APP_ROUTES.ADMIN_CLASSES}/edit/${queryParams.id}`)
  }, [queryParams])

  const onDeleteHandler = useCallback(() => {
    setIsOpenDeleteModal(true)
  }, [])

  const closeDeleteModal = useCallback(() => {
    setIsOpenDeleteModal(false)
  }, [])

  const deleteHandler = useCallback(() => {
    if (!queryParams.id) {
      return
    }

    setIsOpenDeleteModal(false)
    dispatch(deleteAdminClass({ id: queryParams.id })).then((params) => {
      if (params.meta.requestStatus === 'fulfilled') {
        dispatch(adminClassesActions.setIsNeedRefetchAdminClasses(true))
        navigate(`${APP_ROUTES.ADMIN_CLASSES}`)
      }
    })
  }, [queryParams])

  useEffect(() => {
    if (!queryParams.id) {
      return
    }

    dispatch(getAdminClass({ id: queryParams.id }))
  }, [queryParams])

  return {
    isPendingAdminClass,
    isOpenDeleteModal,
    showAllStudents,
    showAllTeachers,
    showStudentsHandler,
    showTeachersHandler,
    adminClass,
    onBackHandler,
    onEditHandler,
    onDeleteHandler,
    deleteHandler,
    closeDeleteModal
  }
}
