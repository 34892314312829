import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { Box, Page, PageTitle } from '../../components/_Page'
import { useNavigate, useParams } from 'react-router-dom'
import {
  deleteTeacher,
  getAdminTeacher,
  getAdminTeacherClass,
  patchAdminTeacherClass
} from '../../services'
import { useAppDispatch, useAppSelector } from '../../hooks'
import {
  StyledTeacherActions,
  StyledTeacherClassItem,
  StyledTeacherClassItemLabel,
  StyledTeacherClassItems,
  StyledTeacherClassItemsContainer,
  StyledTeacherClassesContainer,
  StyledTeacherContent,
  StyledTeacherImg,
  StyledTeacherImgContainer,
  StyledTeacherInfo,
  StyledTeacherInfoItem,
  StyledTeacherInfoItemContent,
  StyledTeacherInfoItemLabel,
  StyledTeacherInfoSubtitle,
  StyledTeacherInfoTitle,
  StyledTeacherInfoTitleContainer,
  StyledTeacherRow
} from './TeacherView.style'
import { APP_ROUTES } from '../../core/router/appRoutes'
import {
  StyledBackButton,
  StyledButton
} from '../../components/CustomTable/styles'
import { CustomSvgIcon } from '../../components/CustomSvgIcon'
import { Modal } from '../../components/_UI'

const baseImg = '/assets/teacher-student-image-for-form.png'

const TeacherInfoItem: FC<PropsWithChildren<{ label: string }>> = ({
  label,
  children
}) => {
  return (
    <StyledTeacherInfoItem>
      <StyledTeacherInfoItemLabel>{label}</StyledTeacherInfoItemLabel>
      <StyledTeacherInfoItemContent>{children}</StyledTeacherInfoItemContent>
    </StyledTeacherInfoItem>
  )
}

const TeacherClassItems: FC<
  PropsWithChildren<{
    classes: {
      id: string
      name: string
    }[]
    onClassDeleteClick: (id: string) => void
    minClassesToShow?: number
  }>
> = ({ classes, onClassDeleteClick, minClassesToShow = 2 }) => {
  const [showAll, setShowAll] = useState(false)

  const classesToRender = useMemo(() => {
    if (showAll || classes.length <= minClassesToShow) {
      return classes
    }

    return classes.slice(0, minClassesToShow)
  }, [showAll])

  const moreToShowCount = classes.length - classesToRender.length

  const showMoreClickHandler = useCallback(() => {
    setShowAll((prevState) => !prevState)
  }, [])

  const onButtonClickHandler = useCallback((id: string) => {
    onClassDeleteClick(id)
  }, [])

  return (
    <StyledTeacherClassItems>
      <StyledTeacherClassItemsContainer
        shouldMarginBottom={classes.length > minClassesToShow}
      >
        {classesToRender.map((classItem) => (
          <TeacherClassItem
            key={classItem.id}
            id={classItem.id}
            onButtonClick={onButtonClickHandler}
          >
            {classItem.name}
          </TeacherClassItem>
        ))}
      </StyledTeacherClassItemsContainer>
      {classes.length > minClassesToShow && (
        <StyledButton
          variant="outlined"
          size="large"
          onClick={showMoreClickHandler}
        >
          {moreToShowCount > 0 ? `Show more ${moreToShowCount}` : 'Show less'}
        </StyledButton>
      )}
    </StyledTeacherClassItems>
  )
}

const TeacherClassItem: FC<
  PropsWithChildren<{ id: string; onButtonClick: (id: string) => void }>
> = ({ children, id, onButtonClick }) => {
  return (
    <StyledTeacherClassItem>
      <StyledTeacherClassItemLabel>{children}</StyledTeacherClassItemLabel>
      <StyledButton onClick={() => onButtonClick(id)}>
        <CustomSvgIcon path="/assets/delete.svg" color="black" />
      </StyledButton>
    </StyledTeacherClassItem>
  )
}

export const TeacherView: FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const queryParams = useParams()

  const { isPending: isPendingTeacher, data: teacher } = useAppSelector(
    (state) => state.teachers.teacher
  )

  const teacherUsername = queryParams.username!

  const imgRef = useRef<HTMLImageElement>(null)
  const [isError, setIsError] = useState(false)
  const [isOpenDeleteModal, setIsOpeDeleteModal] = useState(false)

  useEffect(() => {
    dispatch(getAdminTeacher({ username: teacherUsername }))
  }, [teacherUsername])

  const handleBackButton = useCallback(() => {
    navigate(`${APP_ROUTES.TEACHERS}`)
  }, [])

  const closeDeleteModal = useCallback(() => {
    setIsOpeDeleteModal(false)
  }, [])

  const onErrorHandler = useCallback(() => {
    if (isError || !imgRef.current) return

    setIsError(true)
    imgRef.current.src = baseImg
  }, [isError])

  const onClassDeleteHandler = useCallback((id: string) => {
    dispatch(getAdminTeacherClass({ id }))
      .unwrap()
      .then(({ id, name, school, teachers, students }) => {
        dispatch(
          patchAdminTeacherClass({
            id,
            data: {
              name,
              schoolId: school.id,
              teacherIds: teachers
                .filter((teacher) => teacher.username !== teacherUsername)
                .map((teacher) => teacher.id),
              studentIds: students.map((student) => student.id)
            }
          })
        )
          .unwrap()
          .then(() => {
            dispatch(getAdminTeacher({ username: teacherUsername }))
          })
      })
  }, [])

  const onTeacherEditHandler = useCallback(
    () => navigate(`${APP_ROUTES.EDIT_TEACHER}/${teacherUsername}`),
    [teacherUsername]
  )

  const onTeacherDeleteHandler = useCallback(() => {
    setIsOpeDeleteModal(true)
  }, [])

  const teacherDeleteHandler = useCallback(() => {
    if (teacher && teacher.id) {
      dispatch(deleteTeacher({ id: teacher.id }))
        .unwrap()
        .finally(() => {
          setIsOpeDeleteModal(false)
          navigate(APP_ROUTES.TEACHERS)
        })
    }
  }, [teacher])

  if (isPendingTeacher || !teacher) {
    return <Page />
  }

  return (
    <Page>
      <Box>
        <PageTitle
          back={
            <StyledBackButton
              aria-label={'Previous page button'}
              onClick={handleBackButton}
            />
          }
        >
          {teacher.fullName}
        </PageTitle>

        <StyledTeacherContent>
          <StyledTeacherRow>
            <StyledTeacherImgContainer>
              <StyledTeacherImg
                src={teacher.avatarUrl || baseImg}
                onError={onErrorHandler}
                ref={imgRef}
              />
            </StyledTeacherImgContainer>
            <StyledTeacherInfo>
              <StyledTeacherInfoTitleContainer>
                <StyledTeacherInfoTitle>
                  {teacher.fullName}
                </StyledTeacherInfoTitle>
                <StyledTeacherInfoSubtitle>
                  {teacher.email}
                </StyledTeacherInfoSubtitle>
              </StyledTeacherInfoTitleContainer>
              <TeacherInfoItem label={'School'}>
                {teacher.schools.map((school) => school.shortName).join(', ')}
              </TeacherInfoItem>
            </StyledTeacherInfo>
            <StyledTeacherActions>
              <StyledButton
                type={'button'}
                size={'large'}
                variant={'outlined'}
                color={'primary'}
                onClick={onTeacherEditHandler}
              >
                Edit information
              </StyledButton>
            </StyledTeacherActions>
          </StyledTeacherRow>
          <StyledTeacherRow>
            <StyledTeacherClassesContainer>
              <TeacherInfoItem label={'Classes'}>
                <TeacherClassItems
                  classes={teacher.classes}
                  onClassDeleteClick={onClassDeleteHandler}
                />
              </TeacherInfoItem>
            </StyledTeacherClassesContainer>
          </StyledTeacherRow>
          <StyledTeacherRow>
            <StyledTeacherActions>
              <StyledButton
                type={'button'}
                size={'large'}
                variant={'outlined'}
                color={'error'}
                onClick={onTeacherDeleteHandler}
              >
                Delete teacher
              </StyledButton>
            </StyledTeacherActions>
          </StyledTeacherRow>
        </StyledTeacherContent>

        <Modal
          isOpen={isOpenDeleteModal}
          title={'Delete user'}
          onClose={closeDeleteModal}
          onSuccess={teacherDeleteHandler}
          confirmText={'Delete'}
        >
          You are about to delete user, this action is permanent. Do you want to
          proceed?
        </Modal>
      </Box>
    </Page>
  )
}
