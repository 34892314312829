import { FC, useCallback, useMemo } from 'react'
import { Box, PageTitle } from '../../components/_Page'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '../../core/router/appRoutes'
import { StyledBackButton } from '../../components/CustomTable/styles'
import { TeacherForm } from '../_Common'
import { createTeacherFormSchema } from '../_Common/TeacherForm/schemas/TeacherForm.schema'

export const NewTeacher: FC = () => {
  const navigate = useNavigate()

  const handleBackButton = useCallback(() => {
    navigate(`${APP_ROUTES.TEACHERS}`)
  }, [])

  const onSuccessHandler = useCallback(() => {
    navigate(`${APP_ROUTES.TEACHERS}`)
  }, [])

  const validationSchema = useMemo(() => createTeacherFormSchema(), [])

  return (
    <Box>
      <PageTitle
        back={
          <StyledBackButton
            aria-label={'Previous page button'}
            onClick={handleBackButton}
          />
        }
      >
        Add a Teacher
      </PageTitle>
      <TeacherForm
        type="create"
        validationSchema={validationSchema}
        onSuccess={onSuccessHandler}
      />
    </Box>
  )
}
