import { styled } from '@mui/system'

export const StyledImage = styled('img')(({ theme }) => ({
  display: 'block',
  width: '100%',
  height: 'auto',
  minWidth: '50px',
  minHeight: '90px',
  maxWidth: '100%',
  maxHeight: '200px',
  margin: '0 auto',
  background: theme.palette.customColors.white
}))
export const StyledImageContainer = styled('div')<{
  isError?: boolean
  disabled?: boolean
}>(({ theme, isError = false, disabled = false }) => ({
  display: 'block',
  borderRadius: '8px',
  border: `2px dashed ${
    theme.palette.customColors[isError ? 'error' : 'linear']
  }`,
  overflow: 'hidden',
  cursor: disabled ? 'default' : 'pointer'
}))

export const StyledLabelText = styled('div')(() => ({
  fontSize: '14px',
  fontWeight: '500',
  textAlign: 'center',
  margin: '8px auto',
  cursor: 'pointer'
}))
export const ImgLoaderContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column'
}))

export const ImgLoaderMessage = styled('div')(({ theme }) => ({
  margin: '3px 14px 0',
  fontSize: '12px',
  color: `${theme.palette.customColors.error}`
}))
