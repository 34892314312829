import { styled } from '@mui/material/styles'
import TableCell, { tableCellClasses } from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { Button, Paper, Typography } from '@mui/material'

export const StyledTableHeadCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.customColors.bg,
    color: theme.palette.common.black,
    fontSize: '10px',
    fontStyle: 'normal',
    fontWeight: '600',
    lineHeight: '12px'
  },
  border: 0,
  '&:last-child': {
    textAlign: 'center'
  }
}))

export const StyledTableBodyCell = styled(TableCell)(() => ({
  border: 0,
  wordBreak: 'break-word',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  boxSizing: 'border-box',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '16px',
  '&:last-of-type': {
    textAlign: 'center'
  }
}))

export const StyledTableRow = styled(TableRow)<{ hovered: 'true' | 'false' }>(
  ({ theme, hovered }) => {
    const isHovered = hovered === 'true'
    return {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.customColors.white
      },
      '&:nth-of-type(1)': {
        'td:first-of-type': {
          borderRadius: '16px 0 0 0'
        },
        'td:last-of-type': {
          borderRadius: '0 16px 0 0'
        }
      },
      '&:nth-of-type(even)': {
        backgroundColor: theme.palette.customColors.bg
      },
      '&:hover': {
        cursor: isHovered ? 'pointer' : '',
        boxShadow: isHovered ? '0px 2px 8px 0px rgba(55, 82, 102, 0.16)' : '',
        transform: isHovered ? 'translateY(-2px)' : '',
        transition: isHovered ? 'transform 0.2s ease-in-out' : ''
      }
    }
  }
)

export const StyledPaper = styled(Paper)(({ theme }) => ({
  minWidth: 250,
  border: 'none',
  boxShadow: 'none',
  borderRadius: '16px',
  backgroundColor: theme.palette.customColors.bg
}))

export const StyledTableWrapper = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.customColors.bg,
  border: `1px solid ${theme.palette.customColors.bg}`,
  borderRadius: '24px',
  boxSizing: 'border-box',
  padding: '24px'
}))

export const StyledPaginationWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '24px'
}))

export const StyledWrapper = styled('div')(() => ({}))

export const StyledTableActionsWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '8px'
}))

export const StyledTableSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '20px',
  color: theme.palette.customColors.gray
}))

export const StyledTableFiltersWrapper = styled('div')(() => ({
  display: 'flex'
}))

export const StyledButton = styled(Button)(() => ({
  boxSizing: 'border-box',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
  width: 'fit-content',
  padding: '10px 32px'
}))

export const StyledBackButton = styled('button')(({ theme }) => ({
  border: 'none',
  backgroundColor: theme.palette.customColors.bg,
  '&:before': {
    content: 'url(/assets/arrow-left-icon.svg)',
    cursor: 'pointer'
  }
}))

export const StyledButtonWrapper = styled('div')(() => ({
  display: 'flex',
  position: 'absolute',
  right: '24px',
  top: '119px'
}))
