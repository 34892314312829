import { useCallback, useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { useCoursesTableHeader } from './hooks/useCoursesTableHeader'
import { useCoursesTableRows } from './hooks/useCoursesTableRows'
import { ISortCallbackParams } from '../../types'
import { Page, PageTitle } from '../../components/_Page'
import {
  deleteCourse,
  getAdminCourse,
  getCourse,
  getCourseCategory,
  ICourseResponse
} from '../../services'
import CustomTable from '../../components/CustomTable'
import { ADMIN_COURSES_PAGE_SIZE } from '../../utils/constants'
import { CourseForm, ICourseForm } from '../_Common'
import { coursesActions } from '../../store/courses/courses.reducer'
import { StyledButton } from '../../components/CustomTable/styles'
import CustomModal from '../../components/CustomModal'
import { Modal } from '../../components/_UI'

export const Courses = () => {
  const {
    isNeedRefetch: isNeedRefetchCourses,
    data: { count: coursesCount, courses }
  } = useAppSelector((state) => state.courses.courses)

  const dispatch = useAppDispatch()

  const [isOpenFormModal, setIsOpeFormModal] = useState(false)
  const [isOpenDeleteModal, setIsOpeDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState<string | undefined>(undefined)
  const [getCourseParams, setGetCourseParams] = useState<ISortCallbackParams>({
    page: 1
  })
  const [formInitialValues, setFormInitialValues] = useState<
    ICourseForm | undefined
  >()

  const toggleFormHandler = useCallback(() => {
    setFormInitialValues(undefined)
    setIsOpeFormModal(!isOpenFormModal)
  }, [isOpenFormModal])

  const sortCallbackHandler = useCallback((params: ISortCallbackParams) => {
    setGetCourseParams(params)
  }, [])

  const editCourse = useCallback((courseId: string) => {
    dispatch(getCourse({ id: courseId })).then((params) => {
      if (params.meta.requestStatus === 'fulfilled') {
        const payload = params.payload as ICourseResponse
        setFormInitialValues({
          id: payload.id,
          name: payload.name,
          categoryId: payload.category.id,
          logoUrl: payload.logoUrl,
          logoPath: payload.logoPath,
          language: payload.language
        })
        setIsOpeFormModal(true)
      }
    })
  }, [])

  const setDeleteCourseHandler = useCallback((courseId: string) => {
    setIsOpeDeleteModal(true)
    setDeleteId(courseId)
  }, [])

  const tableHeader = useCoursesTableHeader()
  const tableRows = useCoursesTableRows(courses, {
    editCourse,
    deleteCourse: setDeleteCourseHandler
  })

  const closeDeleteModal = useCallback(() => {
    setIsOpeDeleteModal(false)
  }, [])

  useEffect(() => {
    dispatch(getCourseCategory())
  }, [])

  useEffect(() => {
    dispatch(getAdminCourse(getCourseParams))
  }, [getCourseParams])

  useEffect(() => {
    if (isNeedRefetchCourses) {
      dispatch(getAdminCourse(getCourseParams))
      dispatch(coursesActions.setIsNeedRefetchCourses(false))
    }
  }, [isNeedRefetchCourses, getCourseParams])

  const deleteCourseHandler = useCallback(() => {
    if (!deleteId) return

    setIsOpeDeleteModal(false)
    dispatch(deleteCourse({ id: deleteId })).then((params) => {
      if (params.meta.requestStatus === 'fulfilled') {
        dispatch(coursesActions.setIsNeedRefetchCourses(true))
      }
    })
  }, [deleteId])

  const formSubmitSuccessHandler = useCallback(() => {
    setIsOpeFormModal(false)
    dispatch(coursesActions.setIsNeedRefetchCourses(true))
  }, [])

  return (
    <Page>
      <PageTitle
        actions={
          <StyledButton
            type={'button'}
            size={'large'}
            variant={'contained'}
            color={'primary'}
            onClick={toggleFormHandler}
          >
            Add course
          </StyledButton>
        }
      >
        Courses
      </PageTitle>

      <CustomTable
        tableAriaLabel={'Courses table'}
        dataCount={coursesCount}
        tableHead={tableHeader}
        rowsCount={ADMIN_COURSES_PAGE_SIZE}
        rows={tableRows}
        defaultSortColumn={''}
        sortCallback={sortCallbackHandler}
        tableSubtitle={`You have ${coursesCount} active courses`}
        searchPlaceholder={'Look for a course'}
      />

      <CustomModal
        open={isOpenFormModal}
        title={formInitialValues ? 'Edit Course ' : 'Add a Course'}
        handleClose={toggleFormHandler}
        width={'404px'}
      >
        <CourseForm
          initialValues={formInitialValues}
          onSuccess={formSubmitSuccessHandler}
          onClose={toggleFormHandler}
          mode={formInitialValues ? 'edit' : 'create'}
        />
      </CustomModal>

      <Modal
        isOpen={isOpenDeleteModal}
        title={'Delete course'}
        onClose={closeDeleteModal}
        onSuccess={deleteCourseHandler}
        confirmText={'Delete'}
      >
        You are about to delete course, this action is permanent. Do you want to
        proceed?
      </Modal>
    </Page>
  )
}
