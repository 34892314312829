import { FC, Fragment, memo } from 'react'
import {
  StyledMenuItems,
  StyledMenuLink,
  StyledSendMessageButton
} from './TooltipItems.style'
import { ITooltipItemsProps } from './TooltipItems.types'

export const TooltipItems: FC<ITooltipItemsProps> = memo(({ items }) => {
  return (
    <StyledMenuItems>
      {items.map((item, index) => {
        switch (item.type) {
          case 'button': {
            return (
              <StyledSendMessageButton key={index} onClick={item.handler}>
                {item.title}
              </StyledSendMessageButton>
            )
          }
          case 'link': {
            return (
              <StyledMenuLink
                key={index}
                aria-label={item.ariaLabel}
                to={item.to}
              >
                {item.title}
              </StyledMenuLink>
            )
          }
          case 'custom':
            return <Fragment key={index}>{item.content}</Fragment>
          default:
            return null
        }
      })}
    </StyledMenuItems>
  )
})
