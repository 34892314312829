import { styled as muiStyled } from '@mui/system'
import { Select, Typography } from '@mui/material'

export const StyledSidebar = muiStyled('nav')(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: '24px',
  marginBottom: '24px',
  justifyContent: 'space-between',
  height: '100%'
}))

export const StyledSidebarMainItems = muiStyled('div')(() => ({
  display: 'flex',
  flexDirection: 'column'
}))

export const BreakLine = muiStyled('hr')(() => ({
  marginTop: '32px',
  size: '1px',
  width: '100%',
  border: '0.5px solid #C7DAFE'
}))

export const SidebarLogout = muiStyled('button')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: '32px',
  border: 'none',
  textDecoration: 'none',
  backgroundColor: theme.palette.customColors.bg,
  padding: '4px 8px',
  boxSizing: 'border-box',
  width: '100%',
  '&:hover': {
    cursor: 'pointer'
  }
}))
export const StyledText = muiStyled(Typography)(() => ({
  color: '#374957',
  fontSize: '12px',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '16px'
}))

export const StyledSelect = muiStyled(Select)(({ theme }) => ({
  width: '70px',
  height: '32px',
  marginTop: '24px',
  backgroundColor: theme.palette.customColors.bg,
  border: '0px solid #F0F7FC !important',
  '.MuiOutlinedInput-notchedOutline': { border: 'none !important' }
}))
