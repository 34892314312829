import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  ICommonChunkConfig,
  IErrorResponse,
  ICommonResponse,
  ICommonPageRequest,
  IBaseStudent
} from '..'
import { del, get, patch, post } from '../../axios'

import { API_ROUTES } from '../../core/router/apiRoutes'
import {
  ALL_LIST_PAGE_SIZE,
  BASE_SIZE,
  ADMIN_STUDENTS_PAGE_SIZE
} from '../../utils/constants'

import {} from '../../utils/constants'
import {
  ICreateStudentRequest,
  ICreateStudentResponse,
  IDeleteStudentRequest,
  IDelteAdminStudentCourseRequest,
  IEditStudentClassesRequest,
  IEditStudentRequest,
  IGetAdminStudentActivitiesRequest,
  IGetAdminStudentActivitiesResponse,
  IGetAdminStudentRequest,
  IGetAdminStudentResponse,
  IGetAdminStudentsRequest,
  IGetAdminStudentsResponse
} from './students.types'
import { UserType } from '../../types'

export const getStudents = createAsyncThunk<
  ICommonResponse<IBaseStudent>,
  ICommonPageRequest,
  ICommonChunkConfig
>('find/students', async ({ allList, page, ..._data }, { rejectWithValue }) => {
  const params = allList
    ? {
        page: 1,
        pageSize: ALL_LIST_PAGE_SIZE,
        ..._data
      }
    : {
        page,
        pageSize: BASE_SIZE,
        ..._data
      }

  try {
    return await get<ICommonResponse<IBaseStudent>>({
      path: API_ROUTES.FIND_STUDENTS,
      params
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const getAdminStudents = createAsyncThunk<
  IGetAdminStudentsResponse,
  IGetAdminStudentsRequest,
  ICommonChunkConfig
>('adminStudents/getAdminStudents', async (_data, { rejectWithValue }) => {
  try {
    return await get<IGetAdminStudentsResponse>({
      path: API_ROUTES.GET_ADMIN_STUDENTS,
      params: {
        pageSize: ADMIN_STUDENTS_PAGE_SIZE,
        ..._data
      }
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const deleteOrphanedStudents = createAsyncThunk<
  void,
  void,
  ICommonChunkConfig
>('adminStudents/deleteOrphanedStudents', async (_, { rejectWithValue }) => {
  try {
    return await del<void>(
      {
        path: API_ROUTES.DELETE_ORPHANED,
        data: {}
      },
      {
        type: 'students'
      }
    )
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const importUsers = createAsyncThunk<
  void,
  { formData: FormData; type: UserType; separator: string },
  ICommonChunkConfig
>(
  'adminStudents/importUsers',
  async ({ formData, separator, type }, { rejectWithValue }) => {
    try {
      return await post<void>(
        {
          path: API_ROUTES.IMPORT_USERS,
          data: formData
        },
        {
          type,
          separator
        }
      )
    } catch (error: any) {
      return rejectWithValue(error as IErrorResponse)
    }
  }
)

export const deleteStudent = createAsyncThunk<
  void,
  IDeleteStudentRequest,
  ICommonChunkConfig
>('adminStudents/deleteStudent', async (_data, { rejectWithValue }) => {
  try {
    return await del<void>({
      path: API_ROUTES.DELETE_ACCOUNT(_data.id),
      data: {
        id: _data.id
      }
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const getAdminStudent = createAsyncThunk<
  IGetAdminStudentResponse,
  IGetAdminStudentRequest,
  ICommonChunkConfig
>('adminStudents/getAdminStudent', async (_data, { rejectWithValue }) => {
  try {
    return await get<IGetAdminStudentResponse>({
      path: API_ROUTES.GET_ADMIN_STUDENT(_data.username),
      params: {
        username: _data.username
      }
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const getAdminStudentActivities = createAsyncThunk<
  IGetAdminStudentActivitiesResponse,
  IGetAdminStudentActivitiesRequest,
  ICommonChunkConfig
>(
  'adminStudents/getAdminStudentActivities',
  async (_data, { rejectWithValue }) => {
    try {
      return await get<IGetAdminStudentActivitiesResponse>({
        path: API_ROUTES.GET_ADMIN_STUDENT_ACTIVITIES(_data.username),
        params: {
          username: _data.username
        }
      })
    } catch (error: any) {
      return rejectWithValue(error as IErrorResponse)
    }
  }
)

export const deleteAdminStudentCourse = createAsyncThunk<
  void,
  IDelteAdminStudentCourseRequest,
  ICommonChunkConfig
>(
  'adminStudents/deleteAdminStudentCourse',
  async (_data, { rejectWithValue }) => {
    try {
      return await del<void>({
        path: API_ROUTES.DELETE_ADMIN_STUDENT_COURSE(_data.courseId),
        data: {
          studentId: _data.studentId,
          sendNotification: _data.sendNotification
        }
      })
    } catch (error: any) {
      return rejectWithValue(error as IErrorResponse)
    }
  }
)

export const createStudent = createAsyncThunk<
  ICreateStudentResponse,
  ICreateStudentRequest,
  ICommonChunkConfig
>('adminStudents/createStudent', async (_data, { rejectWithValue }) => {
  const { username, fullName, email, password } = _data
  try {
    return await post<ICreateStudentResponse>({
      path: API_ROUTES.CREATE_ACCOUNT,
      data: {
        type: 'student',
        username,
        fullName,
        email,
        password
      }
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const editStudent = createAsyncThunk<
  void,
  IEditStudentRequest,
  ICommonChunkConfig
>('adminStudents/editStudent', async (_data, { rejectWithValue }) => {
  const { id, fullName, email, password } = _data
  try {
    return await patch<void>(
      {
        path: API_ROUTES.PATCH_ACCOUNT(id),
        data: {
          fullName,
          email,
          password,
          type: 'student'
        }
      },
      {
        id
      }
    )
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const editStudentClasses = createAsyncThunk<
  void,
  IEditStudentClassesRequest,
  ICommonChunkConfig
>('adminStudents/editStudentClasses', async (_data, { rejectWithValue }) => {
  const { id, ...data } = _data
  try {
    return await patch<void>(
      {
        path: API_ROUTES.PATCH_STUDENT_CLASSES(id),
        data
      },
      {
        id
      }
    )
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})
