import { styled } from '@mui/system'

export const StyledFormContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px'
}))

export const StyledButtonContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',

  '& > *': {
    flexGrow: 1,
    '&:last-child': {
      flexGrow: 3
    }
  }
}))
