import { styled } from '@mui/system'

export const StyledAdminClassViewContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '24px',
  marginTop: '24px'
}))

export const StyledAdminClassViewDetailList = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 2fr',
  gap: '16px'
}))

export const StyledAdminClassViewDetailedLabel = styled('div')(({ theme }) => ({
  fontSize: '16px',
  fontWeight: '600',
  lineHeight: '20px',
  color: theme.palette.customColors.grayMedium
}))

export const StyledAdminClassViewDetailedContent = styled('div')(
  ({ theme }) => ({
    fontSize: '16px',
    fontWeight: '600',
    lineHeight: '20px',
    color: theme.palette.customColors.black,

    '& a': {
      color: theme.palette.customColors.black,
      '&:hover': {
        textDecoration: 'none'
      }
    }
  })
)

export const StyledAdminClassViewContainerActions = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,
  marginLeft: 'auto'
}))

export const StyledAdminClassViewContainerActionsTop = styled('div')(() => ({
  textAlign: 'right'
}))

export const StyledAdminClassViewContainerActionsBottom = styled('div')(() => ({
  marginTop: 'auto',
  textAlign: 'right'
}))
