export const useAdminSchoolTableHeader = () => {
  return [
    {
      name: 'School',
      ariaLabel: 'School',
      cellName: 'shorName',
      sortable: true
    },
    {
      name: 'Country',
      ariaLabel: 'Country',
      cellName: 'country',
      sortable: false
    },
    {
      name: 'Teachers',
      ariaLabel: 'Teachers',
      cellName: 'teachers',
      sortable: false
    },
    {
      name: 'Classes',
      ariaLabel: 'Classes',
      cellName: 'classes',
      sortable: false
    },
    {
      name: 'Students',
      ariaLabel: 'Students',
      cellName: 'students',
      sortable: false
    },
    {
      name: 'Average Score in school',
      ariaLabel: 'Average Score in school',
      cellName: 'score',
      sortable: false
    },
    {
      name: 'Manager of the school',
      ariaLabel: 'Manager of the school',
      cellName: 'manager',
      sortable: false
    },
    {
      name: '',
      ariaLabel: '',
      cellName: 'actions',
      sortable: false
    }
  ]
}
