import { useNavigate } from 'react-router-dom'
import {
  Dropdown,
  ITooltipItemsProps,
  TooltipItems
} from '../../../components/_UI'
import { Student } from '../../../services/students/students.types'
import { useMemo } from 'react'
import { APP_ROUTES } from '../../../core/router/appRoutes'
import dayjs from 'dayjs'

export const useAdminStudentsTableRows = (
  students: Student[],
  { setMessageStudentId }: { setMessageStudentId: (val: string) => void }
) => {
  const navigate = useNavigate()

  return useMemo(
    () =>
      students.map((student) => {
        const {
          id,
          fullName,
          username,
          activitiesDoneCount,
          lastScoreDate,
          classes,
          schools,
          averageScore
        } = student

        const tooltipItems: ITooltipItemsProps['items'] = [
          {
            type: 'button',
            title: 'Send message to student',
            handler: () => setMessageStudentId(id)
          },
          {
            type: 'link',
            title: 'Go to student details',
            to: `${APP_ROUTES.STUDENTS}/${username}`
          },
          {
            type: 'link',
            title: 'Edit student',
            to: `${APP_ROUTES.EDIT_STUDENT}/${username}`
          }
        ]

        const schoolNames = schools.map((el) => el.shortName).join(', ')

        const classNames = classes.map((el) => el.name).join(', ')

        const lastActivity =
          lastScoreDate !== 'undefined'
            ? dayjs(lastScoreDate).format('DD/MM/YYYY')
            : '-'

        return {
          fullName,
          schols: schoolNames || '-',
          classes: classNames || '-',
          activitiesDone: activitiesDoneCount,
          averageScore: averageScore || '-',
          lastActivity: lastActivity,
          rowAction: () => navigate(`${APP_ROUTES.STUDENTS}/${username}`),
          excludeAction: ['actions'],
          actions: (
            <Dropdown>
              <TooltipItems items={tooltipItems} />
            </Dropdown>
          )
        }
      }),
    [students]
  )
}
