import { useCallback, useEffect, useState, ChangeEvent } from 'react'
import { ISortCallbackParams } from '../../../types'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { useAdminClassesTableHeader } from '../hooks/useAdminClassesTableHeader'
import { useAdminClassesTableRows } from '../hooks/useAdminClassesTableRows'
import { adminClassesActions } from '../../../store/adminClasses/adminClasses.reducer'
import {
  getAdminClasses,
  getSchools,
  sendAdminClassMessage,
  sendAdminClassTeachersMessage
} from '../../../services'
import { useAdminClassesTableFilter } from '../hooks/useAdminClassesTableFilter'
import { APP_ROUTES } from '../../../core/router/appRoutes'
import { useNavigate } from 'react-router-dom'

export const useAdminClasses = () => {
  const [adminClassesParams, setAdminClassesParams] =
    useState<ISortCallbackParams>({
      page: 1
    })
  const [classMessageModalId, setClassMessageModalId] = useState('')
  const [teachersMessageModalId, setTeachersMessageModalId] = useState('')
  const [classMessageText, setClassMessageText] = useState('')
  const [teachersMessageText, setTeachersMessageText] = useState('')

  const {
    isNeedRefetchAdminClasses,
    adminClasses: { count: classesCount, adminClasses }
  } = useAppSelector((state) => {
    return {
      isNeedRefetchAdminClasses: state.adminClasses.adminClasses.isNeedRefetch,
      adminClasses: state.adminClasses.adminClasses.data
    }
  })

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const closeTeachersMessageModal = useCallback(() => {
    setTeachersMessageModalId('')
  }, [])

  const openTeachersMessageModal = useCallback((id: string) => {
    setTeachersMessageModalId(id)
  }, [])

  const onTeachersMessageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setTeachersMessageText(event.target.value)
    },
    []
  )

  const closeClassMessageModal = useCallback(() => {
    setClassMessageModalId('')
  }, [])

  const openClassMessageModal = useCallback((id: string) => {
    setClassMessageModalId(id)
  }, [])

  const onClassMessageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setClassMessageText(event.target.value)
    },
    []
  )

  const addClassHandler = useCallback(() => {
    navigate(APP_ROUTES.NEW_ADMIN_CLASS)
  }, [])

  const sortCallbackHandler = useCallback((params: ISortCallbackParams) => {
    setAdminClassesParams(params)
  }, [])

  const sendMessageToTeachers = useCallback(() => {
    if (!!teachersMessageText.length) {
      dispatch(
        sendAdminClassTeachersMessage({
          id: teachersMessageModalId,
          text: teachersMessageText
        })
      )
        .unwrap()
        .finally(() => {
          setTeachersMessageModalId('')
          setTeachersMessageText('')
        })
    }
  }, [teachersMessageText, teachersMessageModalId])

  const sendMessageToClass = useCallback(() => {
    if (!!classMessageText.length) {
      dispatch(
        sendAdminClassMessage({
          id: classMessageModalId,
          text: classMessageText
        })
      )
        .unwrap()
        .finally(() => {
          setClassMessageModalId('')
          setClassMessageText('')
        })
    }
  }, [classMessageText, classMessageModalId])

  const openClass = useCallback((id: string) => {
    navigate(`${APP_ROUTES.ADMIN_CLASSES}/${id}`)
  }, [])

  const tableFilters = useAdminClassesTableFilter()
  const tableHeader = useAdminClassesTableHeader()
  const tableRows = useAdminClassesTableRows(adminClasses, {
    openTeachersMessageModal,
    openClassMessageModal,
    openClass
  })

  useEffect(() => {
    dispatch(getSchools({ allList: true }))
  }, [])

  useEffect(() => {
    dispatch(getAdminClasses(adminClassesParams))
  }, [adminClassesParams])

  useEffect(() => {
    if (isNeedRefetchAdminClasses) {
      dispatch(getAdminClasses(adminClassesParams))
      dispatch(adminClassesActions.setIsNeedRefetchAdminClasses(false))
    }
  }, [isNeedRefetchAdminClasses, adminClassesParams])

  return {
    teachersMessageModalId,
    classMessageModalId,
    teachersMessageText,
    classMessageText,
    tableFilters,
    tableHeader,
    tableRows,
    classesCount,
    sortCallbackHandler,
    addClassHandler,
    closeTeachersMessageModal,
    closeClassMessageModal,
    onTeachersMessageChange,
    onClassMessageChange,
    sendMessageToTeachers,
    sendMessageToClass
  }
}
