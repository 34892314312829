import { FC, memo } from 'react'
import { Formik } from 'formik'
import {
  FormImgLoader,
  FormSwitchInput,
  FormTextInput
} from '../../../components/_Form'

import { StyledButton } from '../../../components/CustomTable/styles'
import { IUnitForm, IUnitFormProps } from './UnitForm.types'
import { unitFormSchema } from './schemas/UnitForm.schema'
import { StyledButtonContainer, StyledFormContainer } from './UnitForm.style'
import { useUnitSubmit } from './hooks/useUnitSubmit'
import { useAppSelector } from '../../../hooks'

export const UnitForm: FC<IUnitFormProps> = memo(
  ({ initialValues, onSuccess, courseId, onClose }) => {
    const isPendingUnitForm = useAppSelector(
      (state) => state.units.isPendingUnitForm
    )

    const formSubmit = useUnitSubmit({ initialValues, onSuccess, courseId })
    return (
      <Formik<IUnitForm>
        initialValues={
          initialValues ?? {
            name: '',
            logoPath: undefined,
            logoUrl: undefined,
            draft: false
          }
        }
        onSubmit={formSubmit}
        validationSchema={unitFormSchema}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <StyledFormContainer>
              <FormImgLoader<IUnitForm>
                name={'logoPath'}
                currentImgSrc={initialValues?.logoUrl}
              />

              <FormTextInput<IUnitForm>
                name={'name'}
                label={'Name of unit'}
                placeholder={'Enter name of the unit'}
                autoComplete={'off'}
              />

              <FormSwitchInput name={'draft'} label={'Draft'} />

              <StyledButtonContainer>
                <StyledButton
                  type={'button'}
                  size={'large'}
                  variant={'outlined'}
                  color={'primary'}
                  onClick={onClose}
                >
                  Cancel
                </StyledButton>

                <StyledButton
                  type={'submit'}
                  size={'large'}
                  variant={'contained'}
                  color={'primary'}
                  disabled={isPendingUnitForm}
                >
                  Confirm
                </StyledButton>
              </StyledButtonContainer>
            </StyledFormContainer>
          </form>
        )}
      </Formik>
    )
  }
)
