import * as Yup from 'yup'
import { passwordSchema } from '../../../../validation/primitives'

export const createStudentFormSchema = () =>
  Yup.object().shape({
    fullName: Yup.string()
      .required('Name is required')
      .min(4, 'Minimum 4 characters'),
    schoolIds: Yup.array().of(Yup.string()).min(1, 'School is required'),
    classIds: Yup.array().of(Yup.string()).min(1, 'Class is required'),
    email: Yup.string().email('Invalid email'),
    password: passwordSchema.when('email', ([email], schema) => {
      if (!email || email.trim() === '') {
        return schema.required('Password is required')
      }

      return schema.nullable()
    })
  })

export const editStudentFormSchema = (isExistEmail?: boolean) =>
  Yup.object().shape({
    fullName: Yup.string()
      .required('Name is required')
      .min(4, 'Minimum 4 characters'),
    schoolIds: Yup.array().of(Yup.string()).min(1, 'School is required'),
    classIds: Yup.array().of(Yup.string()).min(1, 'Class is required'),
    email: isExistEmail
      ? Yup.string().email('Invalid email').required('Email is required')
      : Yup.string().email('Invalid email'),
    password: passwordSchema
  })

export const studentFormSchema = Yup.object().shape({
  fullName: Yup.string()
    .required('Name is required')
    .min(4, 'Minimum 4 characters'),
  email: Yup.string().email('Invalid email'),
  schoolIds: Yup.array().of(Yup.string()).min(1, 'School is required'),
  classIds: Yup.array().of(Yup.string()).min(1, 'Class is required')
})
