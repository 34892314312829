import { FC, memo } from 'react'
import { StyledFormRow, StyledFormRowLabel } from './FormRow.style'
import { IFormRowProps } from './FormRow.types'

export const FormRow: FC<IFormRowProps> = memo(({ type, children, label }) => {
  return (
    <StyledFormRow type={type}>
      <StyledFormRowLabel>{label}</StyledFormRowLabel>
      {children}
    </StyledFormRow>
  )
})
