import { FC, memo, useCallback, useMemo, useState } from 'react'
import { TICKET_STATUSES } from '../../../utils/constants'
import { TicketStatusType } from '../../../store/tickets/tickets.types'
import { SelectChangeEvent } from '@mui/material'
import { updateTicket } from '../../../services/tickets/tickets.service'
import { useAppDispatch } from '../../../hooks'
import customToast from '../../../components/CustomToast'
import { Select } from '../../../components/_UI'

export const RowCustomStatusSelect: FC<{
  initStatus: TicketStatusType
  ticketId: string
}> = memo(({ initStatus, ticketId }) => {
  const dispatch = useAppDispatch()

  const [currentStatus, setCurrentStatus] = useState(initStatus)
  const [isPendingStatus, setIsPendingStatus] = useState(false)

  const currentStatusConst = TICKET_STATUSES[currentStatus]

  const statusOptions = useMemo(
    () =>
      Object.keys(TICKET_STATUSES).map((status) => ({
        value: status,
        name: status
      })),
    []
  )

  const onChangeHandler = useCallback((event: SelectChangeEvent<unknown>) => {
    const savedStatus = currentStatus
    const newStatus = event.target.value as TicketStatusType
    setCurrentStatus(newStatus)

    setIsPendingStatus(true)

    dispatch(updateTicket({ id: ticketId, status: newStatus })).then(
      (params) => {
        setIsPendingStatus(false)

        if (params.meta.requestStatus === 'rejected') {
          setCurrentStatus(savedStatus)
          customToast({ type: 'error', message: 'Error during status change' })
          return
        }

        customToast({ type: 'success', message: 'Status has been changed' })
      }
    )
  }, [])

  return (
    <Select
      value={currentStatus}
      disabled={isPendingStatus}
      onChange={onChangeHandler}
      options={statusOptions}
      width={'100%'}
      aria-label={'Change status'}
      style={{
        border: `1px solid ${currentStatusConst.mainColor}`,
        backgroundColor: currentStatusConst.supportColor
      }}
    />
  )
})
