import { FC, useCallback, useState } from 'react'
import { useAppDispatch } from '../../hooks'
import { getCurrentUser, login } from '../../services/auth/auth.service'
import { Formik } from 'formik'
import FormInput from '../../components/FormInput'
import { StyledForm, StyledLink, StyledLoginFormWrapper } from './Login.style'
import { getDefaultFormValues } from '../../utils/helpers'
import v from '../../validation/validation'
import schemas from './schemas/Login.schemas'
import { CustomSvgIcon } from '../../components/CustomSvgIcon'
import FormOutlinedInput from '../../components/FormOutlinedInput'
import { Button } from '@mui/material'
import { ILoginRequest } from '../../services/auth/auth.types'

export const loginFields = ['login', 'password']
export const loginValues = getDefaultFormValues({}, loginFields)

export const Login: FC = () => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false)
  const loginValidationSchema = v.utils.createPartialObjectSchema(
    schemas(),
    loginFields
  )

  const dispatch = useAppDispatch()
  const onSubmit = (values: ILoginRequest) => {
    dispatch(login(values)).then((res) => {
      if (login.fulfilled.match(res)) {
        dispatch(getCurrentUser())
      }
    })
  }

  const changePasswordVisibility = useCallback(() => {
    setIsPasswordVisible(!isPasswordVisible)
  }, [isPasswordVisible])

  return (
    <StyledLoginFormWrapper>
      <CustomSvgIcon
        path={'/assets/logo.svg'}
        width={'450px'}
        height={'225px'}
        title={'Beyond Words Logo'}
      />
      <Formik
        onSubmit={onSubmit}
        initialValues={loginValues}
        validationSchema={loginValidationSchema}
      >
        <StyledForm>
          <FormInput
            label={'emailUser'}
            name={'login'}
            width={'296px'}
            placeholder={'yourEmail'}
          />
          <FormOutlinedInput
            label={'password'}
            name={'password'}
            type={isPasswordVisible ? 'text' : 'password'}
            width={'296px'}
            placeholder={'yourPassword'}
            iconPath={'/assets/eye-icon.svg'}
            iconAlt={'Eye icon'}
            iconOnClick={changePasswordVisibility}
          />
          <Button
            type={'submit'}
            size={'large'}
            variant={'contained'}
            color={'primary'}
          >
            login
          </Button>
          <StyledLink to={'/reset-password'}>forgot</StyledLink>
        </StyledForm>
      </Formik>
    </StyledLoginFormWrapper>
  )
}
