import { useMemo } from 'react'

import { IAdminSchool } from '../../../store/schools/schools.types'

import {
  Dropdown,
  ITooltipItemsProps,
  TooltipItems
} from '../../../components/_UI'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '../../../core/router/appRoutes'

export const useAdminSchoolTableRows = (
  schools: IAdminSchool[],
  { deleteSchool }: { deleteSchool: (id: string) => void }
) => {
  const navigate = useNavigate()

  return useMemo(
    () =>
      schools.map((school) => {
        const {
          id,
          shortName,
          averageScore,
          managerFullName,
          studentsCount,
          classCount,
          country,
          teachersCount
        } = school

        const tooltipItems: ITooltipItemsProps['items'] = [
          {
            type: 'button',
            title: 'delete',
            handler: () => {
              deleteSchool(id)
            }
          },
          {
            type: 'link',
            title: 'edit',
            to: `${APP_ROUTES.EDIT_SCHOOL}/${id}`
          }
        ]

        return {
          shorName: shortName,
          country,
          teachers: teachersCount,
          classes: classCount,
          students: studentsCount,
          score: averageScore,
          manager: managerFullName,
          rowAction: () => navigate(`${APP_ROUTES.SCHOOLS}/${id}`),
          excludeAction: ['actions'],
          actions: (
            <Dropdown>
              <TooltipItems items={tooltipItems} />
            </Dropdown>
          )
        }
      }),
    [schools]
  )
}
