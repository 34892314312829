import { FC, memo } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import {
  ActivityView,
  Courses,
  CourseView,
  EditSchool,
  EditTeacher,
  Home,
  Login,
  NewSchool,
  NewTeacher,
  Schools,
  SchoolView,
  Teachers,
  TeacherView,
  Tickets,
  UnitView,
  AdminClasses,
  NewAdminClass,
  EditAdminClass,
  AdminClassView,
  Students,
  StudentView,
  NewStudent,
  EditStudent
} from '../../containers'
import { APP_ROUTES } from './appRoutes'
import { RegisteredUserGuard, UnregisteredUserGuard } from '../guards'
import BaseLayout from '../Layouts/BaseLayout'

const Router: FC = memo(() => (
  <BaseLayout>
    <Routes>
      <Route
        path={APP_ROUTES.LOGIN}
        element={
          <UnregisteredUserGuard>
            <Login />
          </UnregisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.MAIN}
        element={
          <RegisteredUserGuard>
            <Home />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.ADMIN_CLASSES}
        element={
          <RegisteredUserGuard>
            <AdminClasses />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.ADMIN_CLASSES + '/:id'}
        element={
          <RegisteredUserGuard>
            <AdminClassView />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.NEW_ADMIN_CLASS}
        element={
          <RegisteredUserGuard>
            <NewAdminClass />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.EDIT_ADMIN_CLASS + '/:id'}
        element={
          <RegisteredUserGuard>
            <EditAdminClass />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.COURSES}
        element={
          <RegisteredUserGuard>
            <Courses />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.COURSES + '/:id'}
        element={
          <RegisteredUserGuard>
            <CourseView />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.UNITS + '/:id'}
        element={
          <RegisteredUserGuard>
            <UnitView />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.ACTIVITY + '/:id'}
        element={
          <RegisteredUserGuard>
            <ActivityView />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.TICKETS}
        element={
          <RegisteredUserGuard>
            <Tickets />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.SCHOOLS}
        element={
          <RegisteredUserGuard>
            <Schools />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.SCHOOLS + '/:id'}
        element={
          <RegisteredUserGuard>
            <SchoolView />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.EDIT_SCHOOL + '/:id'}
        element={
          <RegisteredUserGuard>
            <EditSchool />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.NEW_SCHOOL}
        element={
          <RegisteredUserGuard>
            <NewSchool />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.TEACHERS}
        element={
          <RegisteredUserGuard>
            <Teachers />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.NEW_TEACHER}
        element={
          <RegisteredUserGuard>
            <NewTeacher />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.EDIT_TEACHER + '/:username'}
        element={
          <RegisteredUserGuard>
            <EditTeacher />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.TEACHERS + '/:username'}
        element={
          <RegisteredUserGuard>
            <TeacherView />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.STUDENTS}
        element={
          <RegisteredUserGuard>
            <Students />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.STUDENTS + '/:username'}
        element={
          <RegisteredUserGuard>
            <StudentView />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.ADD_STUDENT}
        element={
          <RegisteredUserGuard>
            <NewStudent />
          </RegisteredUserGuard>
        }
      />
      <Route
        path={APP_ROUTES.EDIT_STUDENT + '/:username'}
        element={
          <RegisteredUserGuard>
            <EditStudent />
          </RegisteredUserGuard>
        }
      />
      <Route path="*" element={<Navigate to={APP_ROUTES.MAIN} />} />
    </Routes>
  </BaseLayout>
))

export default Router
