import { useMemo } from 'react'
import { TICKET_STATUSES } from '../../../utils/constants'

export const useTicketTableFilter = () => {
  return useMemo(() => {
    const statuses = Object.keys(TICKET_STATUSES).map((statusName) => ({
      value: statusName,
      name: statusName
    }))

    return { status: statuses }
  }, [])
}
