import { FC } from 'react'
import { StyledActivityLevel } from './ActivityLevel.style'
import { IActivityLevel } from '../../services'
import { ACTIVITY_LEVELS_MAP } from '../../utils/constants'

export const ActivityLevel: FC<{
  activityLevel: IActivityLevel
}> = ({ activityLevel }) => {
  const { name, level, ...props } = ACTIVITY_LEVELS_MAP[activityLevel]
  return (
    <StyledActivityLevel {...props}>
      {name} {level}
    </StyledActivityLevel>
  )
}
