import { useMemo } from 'react'
import { useAppSelector } from '../../../hooks'
import { IBaseSchool } from '../../../services'

export const useAdminClassesTableFilter = () => {
  const {
    data: { schools }
  } = useAppSelector((state) => state.adminClasses.schools)

  return useMemo(() => {
    const schoolFilter = schools.map((school: IBaseSchool) => ({
      value: school.id,
      name: school.shortName
    }))

    return { school: schoolFilter }
  }, [schools])
}
