import { useFormikContext } from 'formik'
import { FieldValue } from '../_Form.types'
import {
  ChangeEvent,
  DragEvent,
  memo,
  useCallback,
  useMemo,
  useState
} from 'react'
import { IFormImgLoaderProps } from './FormImgLoader.types'
import {
  ImgLoaderContainer,
  ImgLoaderMessage,
  StyledImage,
  StyledImageContainer,
  StyledLabelText
} from './FormImgLoader.styles'

const FormImgLoaderNonMemo = <FormValues extends FieldValue>({
  name: fieldName,
  currentImgSrc,
  labelText,
  type,
  ...props
}: IFormImgLoaderProps<FormValues>) => {
  const [initImage, setInitImage] = useState<string | undefined>(currentImgSrc)
  const formContext = useFormikContext<FormValues>()
  const isTouched = formContext.touched[fieldName]

  const isError = isTouched && !!formContext.errors[fieldName]

  const defaultImage = useMemo(() => {
    if (type === 'school') {
      return '/assets/school-image-for-form.png'
    }
    if (type === 'teacher') {
      return '/assets/teacher-student-image-for-form.png'
    }
    if (type === 'student') {
      return '/assets/teacher-student-image-for-form.png'
    }
    return '/assets/default-form-image-small.svg'
  }, [type])

  const message = isError
    ? (formContext.errors as Record<string, string>)[fieldName]
    : ''

  const updateFile = useCallback((files: FileList | null) => {
    const file = files ? files[0] : null

    formContext.setFieldTouched(fieldName, true, false)

    if (file && file.type.startsWith('image/')) {
      formContext.setFieldValue(fieldName, file)
      setInitImage(undefined)
      return
    }

    setInitImage(undefined)
    formContext.setFieldValue(fieldName, null, false)
    formContext.setFieldError(fieldName, 'File is not images')
  }, [])

  const onChangeHandler: (event: ChangeEvent<HTMLInputElement>) => void =
    useCallback(
      (event) => {
        updateFile(event.target.files)
      },
      [updateFile]
    )

  const handleDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    updateFile(event.dataTransfer.files)
  }

  const src = useMemo(() => {
    if (typeof initImage === 'string') {
      return initImage
    }

    const source = formContext.values[fieldName]

    if (!source && !initImage) return defaultImage

    return URL.createObjectURL(source)
  }, [formContext.values[fieldName]])

  const isLoadingDisabled = type === 'teacher' || type === 'student'

  return (
    <ImgLoaderContainer>
      <label>
        <StyledImageContainer
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
          isError={isError}
          disabled={isLoadingDisabled}
        >
          <StyledImage src={src} alt={fieldName} />
        </StyledImageContainer>

        {!!labelText && <StyledLabelText>{labelText}</StyledLabelText>}
        <input
          type="file"
          accept={'image'}
          alt={fieldName}
          onChange={onChangeHandler}
          style={{ display: 'none' }}
          disabled={isLoadingDisabled}
          {...props}
        />
      </label>
      {!!message && <ImgLoaderMessage>{message}</ImgLoaderMessage>}
    </ImgLoaderContainer>
  )
}

export const FormImgLoader = memo(
  FormImgLoaderNonMemo
) as typeof FormImgLoaderNonMemo
