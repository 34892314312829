import { Typography } from '@mui/material'
import { styled } from '@mui/system'

export const StyledStudentContent = styled('div')(() => ({
  marginTop: '20px'
}))

export const StyledStudentRow = styled('div')(() => ({
  display: 'grid',
  columnCount: 3,
  gridTemplateColumns: '192px 2fr 1fr',
  gap: '6px'
}))

export const StyledStudentImgContainer = styled('div')(() => ({
  gridColumn: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}))

export const StyledStudentImg = styled('img')(({ theme }) => ({
  width: '192px',
  flexShrink: '0',
  borderRadius: '8px',
  overflow: 'hidden',
  display: 'block',
  backgroundColor: theme.palette.customColors.white,
  minHeight: '90px',
  maxHeight: '200px',
  marginBottom: 'auto'
}))

export const StyledStudentInfo = styled('div')(() => ({
  gridColumn: 2,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  marginLeft: '18px',
  padding: '12px 0px'
}))

export const StyledStudentInfoTitleContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column'
}))

export const StyledStudentInfoTitle = styled(Typography)(() => ({
  fontSize: '20px',
  fontWeight: 700,
  lineHeight: '24px',
  marginBottom: '12px'
}))

export const StyledStudentInfoSubtitle = styled(Typography)(({ theme }) => ({
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '16px',
  color: theme.palette.customColors.gray
}))

export const StyledStudentActions = styled('div')(() => ({
  gridColumn: 3,
  display: 'flex',
  justifyContent: 'flex-end'
}))

export const StyledStudentListContainer = styled('div')(({ theme }) => ({
  gridColumn: '2/4',
  display: 'grid',
  columnCount: 2,
  gridTemplateColumns: '2fr 1fr',
  padding: '16px 18px',
  margin: '32px 0 12px',
  border: `1px solid ${theme.palette.customColors.linear}`,
  borderRadius: '16px',
  backgroundColor: theme.palette.customColors.white
}))

export const StyledLoading = styled('div')(() => ({
  display: 'flex',
  position: 'absolute',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  width: '100%'
}))
