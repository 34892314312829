import { styled as muiStyled } from '@mui/system'

export const StyledMainLayout = muiStyled('div')(({ theme }) => ({
  display: 'flex',
  width: '100%',
  height: '100%',
  backgroundColor: theme.palette.customColors.bg
}))

export const StyledContentWrapper = muiStyled('div')(({ theme }) => ({
  overflowX: 'hidden',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  borderRadius: '28px 0px 0px 28px',
  padding: '24px 24px 24px 24px',
  width: '100%',
  minHeight: '100vh',
  height: 'fit-content',
  boxSizing: 'border-box',
  backgroundColor: theme.palette.customColors.white
}))
