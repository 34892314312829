import { FC, memo, useMemo } from 'react'
import { StyledFieldLabel } from '../../QuestionForm.style'
import { FormRow } from '../../../../../../components/_Form'
import { TextInput } from '../../../../../../components/_UI'
import { getIn, useFormikContext } from 'formik'
import {
  StyledBreakpointBlock,
  StyledBreakpointError,
  StyledBreakpointItemContainer
} from './QuestionFormBreakpointField.style'

export const QuestionFormBreakpointField: FC<{
  questionIndex: number
}> = memo(({ questionIndex }) => {
  const { setFieldValue, touched, values, errors } = useFormikContext()

  const breakpointName = `questions[${questionIndex}].breakpoint`
  const isTouchedBreakpoint = getIn(touched, breakpointName)
  const breakpointError = isTouchedBreakpoint
    ? getIn(errors, breakpointName)
    : ''

  const breakpointValue = Number(
    getIn(values, `questions[${questionIndex}].breakpoint`) ?? '0'
  )

  const { minutes, seconds } = useMemo(
    () => ({
      minutes: Math.floor(breakpointValue / 60),
      seconds: breakpointValue % 60
    }),
    [breakpointValue]
  )

  return (
    <StyledBreakpointBlock>
      <StyledFieldLabel>Breakpoint</StyledFieldLabel>
      <StyledBreakpointItemContainer>
        <FormRow label={'min'} type={'row'}>
          <TextInput
            name={`questions[${questionIndex}].breakpoint.minutes`}
            type={'number'}
            error={!!breakpointError}
            placeholder={'mm'}
            value={minutes ? minutes : ''}
            onChange={(e) => {
              let value = parseInt(e.target.value ?? 0)
              if (value < 0 || isNaN(value)) value = 0
              const breakpoint = value * 60 + seconds

              setFieldValue(
                `questions[${questionIndex}].breakpoint`,
                breakpoint
              )
            }}
          />
        </FormRow>

        <FormRow label={'sec'} type={'row'}>
          <TextInput
            type={'number'}
            name={`questions[${questionIndex}].breakpoint.seconds`}
            placeholder={'ss'}
            error={!!breakpointError}
            autoComplete={'off'}
            value={seconds ? seconds : ''}
            onChange={(e) => {
              let value = parseInt(e.target.value ?? 0)
              if (value < 0 || isNaN(value)) value = 0
              if (value > 59) value = 59
              const breakpoint = minutes * 60 + value

              setFieldValue(
                `questions[${questionIndex}].breakpoint`,
                breakpoint
              )
            }}
          />
        </FormRow>
      </StyledBreakpointItemContainer>
      {!!breakpointError && (
        <StyledBreakpointError>{breakpointError}</StyledBreakpointError>
      )}
    </StyledBreakpointBlock>
  )
})
