import * as Yup from 'yup'
import { IMAGE_MAX_SIZE } from '../../../../core/router/apiRoutes'

const courseEditFormSchema = {
  name: Yup.string().required('Name is required'),
  logoPath: Yup.mixed()
    .nullable()
    .test('fileFormat', 'Invalid file format', (value: any) => {
      if (!value) return true

      if (value.type && value.size) {
        const supportedFormats = ['image/jpeg', 'image/png', 'image/gif']
        return supportedFormats.includes(value.type)
      }

      return typeof value === 'string'
    })
    .test('fileSize', 'File is too large', (value: any) => {
      if (!value || typeof value === 'string') return true

      return value.size <= IMAGE_MAX_SIZE
    }),
  categoryId: Yup.string().required('Category is required')
}

const courseCreateFormSchema = {
  ...courseEditFormSchema,
  language: Yup.string().required('Language is required')
}

export const getCurrentSchema = (mode: 'create' | 'edit') => {
  let currentSchema = courseEditFormSchema
  if (mode === 'create') {
    currentSchema = courseCreateFormSchema
  }
  return Yup.object().shape(currentSchema)
}
