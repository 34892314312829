import { FC } from 'react'
import { Props } from './types'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { Modal } from '../../../../components/_UI'
import { deleteAdminStudentCourse } from '../../../../services'

export const DeleteStudentCourseModal: FC<Props> = ({
  courseId,
  closeModal
}) => {
  const dispatch = useAppDispatch()

  const student = useAppSelector((state) => state.students.student.data)
  const course = (
    useAppSelector((state) => state.students.studentActivities.data?.courses) ||
    []
  ).find((el) => el.id === courseId)

  const handleDelete = () => {
    dispatch(
      deleteAdminStudentCourse({
        studentId: student?.id || '',
        courseId: courseId || '',
        sendNotification: false
      })
    )
      .unwrap()
      .finally(closeModal)
  }

  return (
    <Modal
      isOpen={!!courseId}
      title={'Delete course'}
      onClose={closeModal}
      onSuccess={handleDelete}
      confirmText={'Delete'}
    >
      Are you sure you want to delete student {student?.fullName} course “
      {course?.name}”?
    </Modal>
  )
}
