import { useMemo } from 'react'
import { useAppSelector } from '../../../hooks'

export const useAdminStudentsTableFilter = () => {
  const {
    data: { schools }
  } = useAppSelector((state) => state.students.schools)
  const {
    data: { courses }
  } = useAppSelector((state) => state.students.courses)
  const {
    data: { classes }
  } = useAppSelector((state) => state.students.classes)
  const {
    data: { teachers }
  } = useAppSelector((state) => state.students.teachers)

  return useMemo(() => {
    const schoolFilter = schools.map((school) => ({
      value: school.id,
      name: school.shortName
    }))

    const courseFilter = courses.map((course) => ({
      value: course.id,
      name: course.name
    }))

    const classFilter = classes.map((classItem) => ({
      value: classItem.id,
      name: classItem.name
    }))

    const teacherFilter = teachers.map((teachersItem) => ({
      value: teachersItem.id,
      name: teachersItem.username
    }))

    return {
      school: schoolFilter,
      course: courseFilter,
      teacher: teacherFilter,
      class: classFilter
    }
  }, [schools, courses, classes])
}
