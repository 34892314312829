import { IAdminClassesTable } from '../AdminClasses.types'

export const useAdminClassesTableHeader: () => {
  name: string
  ariaLabel: string
  cellName: keyof IAdminClassesTable
  sortable: boolean
}[] = () => {
  return [
    {
      name: 'Name of Class',
      ariaLabel: 'Name of Class',
      cellName: 'name',
      sortable: true
    },
    {
      name: 'School',
      ariaLabel: 'School',
      cellName: 'school',
      sortable: true
    },
    {
      name: 'Number of students',
      ariaLabel: 'Number of students',
      cellName: 'students',
      sortable: true
    },
    {
      name: 'Actions',
      ariaLabel: 'Actions',
      cellName: 'actions',
      sortable: false
    }
  ]
}
