import { FC, memo } from 'react'
import { Formik } from 'formik'
import { ICourseForm, ICourseFormProps } from './CourseForm.types'
import { FormImgLoader, FormTextInput } from '../../../components/_Form'

import { StyledButtonContainer, StyledFormContainer } from './CourseForm.style'
import { FormCategoryField } from './components/FormCategoryField/FormCategoryField'
import { getCurrentSchema } from './schemas/CourseForm.schema'
import { StyledButton } from '../../../components/CustomTable/styles'
import { useCourseSubmit } from './hooks/useCourseSubmit'
import { useAppSelector } from '../../../hooks'
import { FormLanguageField } from './components/FormLanguageField/FormLanguageField'
import { Language } from '../../../types'

const baseInitialValues: ICourseForm = {
  name: '',
  logoPath: undefined,
  logoUrl: undefined,
  categoryId: '',
  language: Language.en
}
export const CourseForm: FC<ICourseFormProps> = memo(
  ({
    initialValues = baseInitialValues,
    onSuccess,
    onClose,
    mode = 'edit'
  }) => {
    const isPendingCourseFrom = useAppSelector(
      (state) => state.courses.isPendingCourseFrom
    )

    const formSubmit = useCourseSubmit({ initialValues, onSuccess, mode })
    return (
      <Formik<ICourseForm>
        initialValues={initialValues}
        onSubmit={formSubmit}
        validationSchema={getCurrentSchema(mode)}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <StyledFormContainer>
              <FormImgLoader<ICourseForm>
                name={'logoPath'}
                currentImgSrc={initialValues?.logoUrl}
              />
              <FormTextInput<ICourseForm>
                name={'name'}
                label={'Name of course'}
                placeholder={'Enter name of the course'}
                autoComplete={'off'}
              />
              {mode === 'create' && <FormLanguageField />}
              <FormCategoryField />
              <StyledButtonContainer>
                <StyledButton
                  type={'button'}
                  size={'large'}
                  variant={'outlined'}
                  color={'primary'}
                  onClick={onClose}
                >
                  Cancel
                </StyledButton>

                <StyledButton
                  type={'submit'}
                  size={'large'}
                  variant={'contained'}
                  color={'primary'}
                  disabled={isPendingCourseFrom}
                >
                  Confirm
                </StyledButton>
              </StyledButtonContainer>
            </StyledFormContainer>
          </form>
        )}
      </Formik>
    )
  }
)
