import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IUnitsState } from './units.types'
import { createUnit, getUnit, getUnits, patchUnit } from '../../services'

const initialState: IUnitsState = {
  isPendingUnitForm: false,
  unit: {
    isPending: false,
    data: undefined
  },
  units: {
    isPending: false,
    isNeedRefetch: false,
    data: {
      count: 0,
      units: []
    }
  }
}

export const unitsSlice = createSlice({
  name: 'units',
  initialState,
  reducers: {
    setIsNeedRefetchUnits: (state, { payload }: PayloadAction<boolean>) => {
      state.units.isNeedRefetch = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getUnits.pending, (state) => {
      state.units.isPending = true
    })
    builder.addCase(getUnits.fulfilled, (state, { payload }) => {
      state.units.data.units = payload.data
      state.units.data.count = payload.count
      state.units.isPending = false
    })
    builder.addCase(getUnits.rejected, (state) => {
      state.units.isPending = false
    })

    builder.addCase(getUnit.pending, (state) => {
      state.unit.isPending = true
    })
    builder.addCase(getUnit.fulfilled, (state, { payload }) => {
      state.unit.data = payload
      state.unit.isPending = false
    })
    builder.addCase(getUnit.rejected, (state) => {
      state.unit.isPending = false
    })

    builder.addCase(createUnit.pending, (state) => {
      state.isPendingUnitForm = true
    })
    builder.addCase(createUnit.fulfilled, (state) => {
      state.isPendingUnitForm = false
    })
    builder.addCase(createUnit.rejected, (state) => {
      state.isPendingUnitForm = false
    })

    builder.addCase(patchUnit.pending, (state) => {
      state.isPendingUnitForm = true
    })
    builder.addCase(patchUnit.fulfilled, (state) => {
      state.isPendingUnitForm = false
    })
    builder.addCase(patchUnit.rejected, (state) => {
      state.isPendingUnitForm = false
    })
  }
})

export const { reducer: unitsReducer, actions: unitsActions } = unitsSlice
