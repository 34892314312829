import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAdminTeachersState } from './teachers.types'
import {
  createTeacher,
  deleteTeacher,
  editTeacher,
  editTeacherClasses,
  getAdminClasses,
  getAdminCourse,
  getAdminSchools,
  getAdminTeacher,
  getAdminTeacherClass,
  getAdminTeachers,
  IGetAdminSchoolsResponse,
  IGetAdminTeachersResponse,
  importUsers,
  ITeacher
} from '../../services'

const initialState: IAdminTeachersState = {
  isPendingTeacherFrom: false,
  teacher: {
    isPending: false,
    isNeedRefetch: false,
    data: undefined
  },
  teachers: {
    isPending: false,
    isNeedRefetch: false,
    data: {
      teachers: [],
      count: 0
    }
  },
  schools: {
    isPending: false,
    isNeedRefetch: false,
    data: {
      schools: [],
      count: 0
    }
  },
  courses: {
    isPending: false,
    isNeedRefetch: false,
    data: {
      courses: [],
      count: 0
    }
  },
  class: {
    isPending: false,
    data: undefined
  },
  classes: {
    isPending: false,
    isNeedRefetch: false,
    data: {
      classes: [],
      count: 0
    }
  }
}

export const teachersSlice = createSlice({
  name: 'teachers',
  initialState,
  reducers: {
    setIsNeedRefetchTeachers: (state, { payload }: PayloadAction<boolean>) => {
      state.teachers.isNeedRefetch = payload
    },
    setIsNeedRefetchTeacher: (state, { payload }: PayloadAction<boolean>) => {
      state.teacher.isNeedRefetch = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAdminTeachers.pending, (state) => {
      state.teachers.isPending = true
    })
    builder.addCase(
      getAdminTeachers.fulfilled,
      (state, { payload }: PayloadAction<IGetAdminTeachersResponse>) => {
        state.teachers.data.teachers = payload.data ?? []
        state.teachers.data.count = payload.count ?? 0
        state.teachers.isPending = false
      }
    )
    builder.addCase(getAdminTeachers.rejected, (state) => {
      state.teachers.isPending = false
    })
    //import teachers
    builder.addCase(importUsers.fulfilled, (state) => {
      state.teachers.isNeedRefetch = true
    })
    builder.addCase(getAdminSchools.pending, (state) => {
      state.schools.isPending = true
    })
    builder.addCase(
      getAdminSchools.fulfilled,
      (state, { payload }: PayloadAction<IGetAdminSchoolsResponse>) => {
        state.schools.data.schools = payload.data ?? []
        state.schools.data.count = payload.count ?? 0
        state.schools.isPending = false
      }
    )
    builder.addCase(getAdminSchools.rejected, (state) => {
      state.schools.isPending = false
    })

    builder.addCase(getAdminCourse.pending, (state) => {
      state.courses.isPending = true
    })
    builder.addCase(getAdminCourse.fulfilled, (state, { payload }) => {
      state.courses.data.courses = payload.data ?? []
      state.courses.data.count = payload.count ?? 0
      state.courses.isPending = false
    })
    builder.addCase(getAdminCourse.rejected, (state) => {
      state.courses.isPending = false
    })

    builder.addCase(getAdminClasses.pending, (state) => {
      state.classes.isPending = true
    })
    builder.addCase(getAdminClasses.fulfilled, (state, { payload }) => {
      state.classes.data.classes = payload.data ?? []
      state.classes.data.count = payload.count ?? 0
      state.classes.isPending = false
    })
    builder.addCase(getAdminClasses.rejected, (state) => {
      state.classes.isPending = false
    })

    builder.addCase(getAdminTeacher.pending, (state) => {
      state.teacher.isPending = true
    })
    builder.addCase(
      getAdminTeacher.fulfilled,
      (state, { payload }: PayloadAction<ITeacher>) => {
        state.teacher.data = payload
        state.teacher.isPending = false
      }
    )
    builder.addCase(getAdminTeacher.rejected, (state) => {
      state.teacher.isPending = false
    })

    builder.addCase(deleteTeacher.pending, (state) => {
      state.teacher.isPending = true
    })
    builder.addCase(deleteTeacher.fulfilled, (state) => {
      state.teacher.data = undefined
      state.teacher.isPending = false
    })
    builder.addCase(deleteTeacher.rejected, (state) => {
      state.teacher.isPending = false
    })

    builder.addCase(getAdminTeacherClass.pending, (state) => {
      state.class.isPending = true
    })
    builder.addCase(getAdminTeacherClass.fulfilled, (state, { payload }) => {
      state.class.data = payload
      state.class.isPending = false
    })
    builder.addCase(getAdminTeacherClass.rejected, (state) => {
      state.class.isPending = false
    })

    builder.addCase(createTeacher.pending, (state) => {
      state.isPendingTeacherFrom = true
    })
    builder.addCase(createTeacher.fulfilled, (state) => {
      state.isPendingTeacherFrom = false
    })
    builder.addCase(createTeacher.rejected, (state) => {
      state.isPendingTeacherFrom = false
    })

    builder.addCase(editTeacher.pending, (state) => {
      state.isPendingTeacherFrom = true
    })
    builder.addCase(editTeacher.fulfilled, (state) => {
      state.isPendingTeacherFrom = false
    })
    builder.addCase(editTeacher.rejected, (state) => {
      state.isPendingTeacherFrom = false
    })

    builder.addCase(editTeacherClasses.pending, (state) => {
      state.isPendingTeacherFrom = true
    })
    builder.addCase(editTeacherClasses.fulfilled, (state) => {
      state.isPendingTeacherFrom = false
    })
    builder.addCase(editTeacherClasses.rejected, (state) => {
      state.isPendingTeacherFrom = false
    })
  }
})

export const { reducer: teachersReducer, actions: teachersActions } =
  teachersSlice
