import { useFormikContext } from 'formik'
import { FieldValue } from '../_Form.types'
import { memo, useCallback } from 'react'
import { FormRow } from '../FormRow/FormRow'
import { IFormSelectInputProps } from './FormRadioGroup.types'
import { RadioGroup } from '../../_UI'

const FormRadioGroupNonMemo = <FormValues extends FieldValue>({
  name: fieldName,
  label,
  options,
  direction
}: IFormSelectInputProps<FormValues>) => {
  const formContext = useFormikContext<FormValues>()

  const onChangeHandler: (event: unknown, value: string | number) => void =
    useCallback((_, value) => {
      formContext.setFieldValue(fieldName, value)
    }, [])

  return (
    <FormRow label={label}>
      <RadioGroup
        value={formContext.values[fieldName]}
        onChange={onChangeHandler}
        options={options}
        direction={direction}
      />
    </FormRow>
  )
}

export const FormRadioGroup = memo(
  FormRadioGroupNonMemo
) as typeof FormRadioGroupNonMemo
