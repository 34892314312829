import { styled as muiStyled } from '@mui/system'

export const StyledNavMenu = muiStyled('div')(({ theme }) => ({
  backgroundColor: theme.palette.customColors.bg,
  minWidth: '224px',
  maxHeight: '100%',
  display: 'flex',
  flexDirection: 'column'
}))

export const StyledLogo = muiStyled('div')(() => ({
  margin: '24px 0 0 24px'
}))
