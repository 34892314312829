import { StyledTeacherPage } from './Teachers.style'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { ADMIN_TEACHERS_PAGE_SIZE } from '../../utils/constants'
import CustomTable from '../../components/CustomTable'
import { useAdminTeachersTableHeader } from './hooks/useAdminTeachersTableHeader'
import { useAdminTeachersTableRows } from './hooks/useAdminTeachersTableRows'
import { useAdminTeachersTableFilter } from './hooks/useAdminTeachersTableFilter'
import { PageTitle } from '../../components/_Page/'
import { usePreparationTeachers } from './hooks/usePreparationTeachers'
import { ChangeEvent, useCallback, useState } from 'react'
import {
  Dropdown,
  ITooltipItemsProps,
  Modal,
  TextInput,
  TooltipItems
} from '../../components/_UI'
import { APP_ROUTES } from '../../core/router/appRoutes'
import { StyledButton } from '../../components/CustomTable/styles'
import { CustomSvgIcon } from '../../components/CustomSvgIcon'
import { deleteOrphanedTeachers, sendAdminMessage } from '../../services'
import { teachersActions } from '../../store/teachers/teachers.reducer'
import { ImportUsersModal } from '../../components/ImportUsersModal'
import { UserType } from '../../types'

export const Teachers = () => {
  const dispatch = useAppDispatch()

  const {
    data: { teachers, count: teachersCount }
  } = useAppSelector((state) => state.teachers.teachers)

  const [teacherId, setTeacherId] = useState<string>()
  const [messageText, setMessageText] = useState<string>()
  const [isOpenMessageModal, setIsOpeMessageModal] = useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [isOpenImportModal, setIsOpenImportModal] = useState<boolean>(false)

  const toggleImportModal = useCallback(() => {
    setIsOpenImportModal((prev) => !prev)
  }, [setIsOpenImportModal])

  const closeMessageModal = useCallback(() => {
    setIsOpeMessageModal(false)
  }, [])

  const closeDeleteModal = useCallback(() => {
    setIsOpenDeleteModal(false)
  }, [])

  const onSuccessMessageHandler = useCallback(() => {
    if (teacherId && messageText && !!messageText.length) {
      dispatch(sendAdminMessage({ id: teacherId, text: messageText }))
        .unwrap()
        .finally(() => {
          setIsOpeMessageModal(false)
          setTeacherId(undefined)
          setMessageText(undefined)
        })
    }
  }, [teacherId, messageText])

  const onSuccessDeleteHandler = useCallback(() => {
    dispatch(deleteOrphanedTeachers())
      .unwrap()
      .finally(() => {
        dispatch(teachersActions.setIsNeedRefetchTeachers(true))
        setIsOpenDeleteModal(false)
      })
  }, [])

  const onMessageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setMessageText(event.target.value)
    },
    []
  )

  const setSendMessageHandler = useCallback((teacherId: string) => {
    setIsOpeMessageModal(true)
    setTeacherId(teacherId)
  }, [])

  const tableHeader = useAdminTeachersTableHeader()
  const tableRows = useAdminTeachersTableRows(teachers, {
    sendMessage: setSendMessageHandler
  })
  const tableFilters = useAdminTeachersTableFilter()
  const sortCallbackHandler = usePreparationTeachers()

  const tooltipItems: ITooltipItemsProps['items'] = [
    {
      type: 'button',
      title: 'Import',
      handler: () => {
        toggleImportModal()
      }
    },
    {
      type: 'link',
      title: 'Add a teacher',
      to: `${APP_ROUTES.NEW_TEACHER}`
    },
    {
      type: 'button',
      title: 'Delete teachers without school',
      handler: () => {
        setIsOpenDeleteModal(true)
      }
    }
  ]

  const teachersWithoutClassesCount = teachers.filter(
    (teacher) => teacher.classes.length === 0
  ).length

  return (
    <StyledTeacherPage>
      <PageTitle
        actions={
          <Dropdown
            title={
              <StyledButton
                type={'button'}
                size={'large'}
                variant={'outlined'}
                color={'primary'}
              >
                <CustomSvgIcon path="/assets/burger-icon.svg" />
              </StyledButton>
            }
            tooltipTopInset={12}
          >
            <TooltipItems items={tooltipItems} />
          </Dropdown>
        }
      >
        Teachers
      </PageTitle>

      <CustomTable
        tableFiltersData={tableFilters}
        tableAriaLabel={'Teachers table'}
        dataCount={teachersCount}
        tableHead={tableHeader}
        rowsCount={ADMIN_TEACHERS_PAGE_SIZE}
        rows={tableRows}
        defaultSortColumn={''}
        sortCallback={sortCallbackHandler}
        tableSubtitle={`You have ${teachersCount} teachers`}
        searchPlaceholder={'Look for a teacher'}
      />

      <Modal
        isOpen={isOpenMessageModal}
        title={'Send message to teacher'}
        onClose={closeMessageModal}
        onSuccess={onSuccessMessageHandler}
        confirmText={'Send a message'}
        confirmDisabled={!messageText?.length}
      >
        <TextInput multiline value={messageText} onChange={onMessageChange} />
      </Modal>
      <Modal
        isOpen={isOpenDeleteModal}
        title={'Delete teachers without school'}
        onClose={closeDeleteModal}
        onSuccess={onSuccessDeleteHandler}
        confirmText={'Delete'}
        confirmDisabled={!teachersWithoutClassesCount}
      >
        {!!teachersWithoutClassesCount
          ? `You are about to delete all users: ${teachersWithoutClassesCount} on this page. This action is permanent. Do you want to proceed?`
          : 'There are no teachers without school'}
      </Modal>
      <ImportUsersModal
        isOpen={isOpenImportModal}
        toggle={toggleImportModal}
        title="Import teachers"
        type={UserType.teacher}
      />
    </StyledTeacherPage>
  )
}
