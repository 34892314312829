import { memo } from 'react'
import {
  BreakLine,
  SidebarLogout,
  StyledSidebar,
  StyledSidebarMainItems,
  StyledText
} from './styles'
import SidebarItem from '../SidebarItem'
import { CustomSvgIcon } from '../../../../components/CustomSvgIcon'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch } from '../../../../hooks'
import Cookies from 'js-cookie'
import { authSlice } from '../../../../store/auth/auth.reducer'

const AdminSidebar = memo(() => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { resetCurrentUser } = authSlice.actions
  const logout = () => {
    Cookies.remove('accessToken')
    Cookies.remove('refreshToken')
    localStorage.removeItem('accessToken')
    localStorage.removeItem('refreshToken')
    dispatch(resetCurrentUser())
    navigate('/login')
  }

  return (
    <StyledSidebar aria-label="Navigation menu">
      <StyledSidebarMainItems>
        <SidebarItem
          locationName={'Dashboard'}
          path={'/'}
          iconPath={'/assets/home-icon.svg'}
          color={'gray'}
        />
      </StyledSidebarMainItems>
      <SidebarItem
        locationName={'Schools'}
        path={'/schools'}
        iconPath={'/assets/school-icon.svg'}
        color={'gray'}
      />
      <SidebarItem
        locationName={'Teachers'}
        path={'/teachers'}
        iconPath={'/assets/teachers-icon.svg'}
        color={'gray'}
      />
      <SidebarItem
        locationName={'Students'}
        path={'/students'}
        iconPath={'/assets/students-icon.svg'}
        color={'gray'}
      />
      <SidebarItem
        locationName={'Classes'}
        path={'/classes'}
        iconPath={'/assets/classes-icon.svg'}
        color={'gray'}
      />
      <SidebarItem
        locationName={'Courses'}
        path={'/courses'}
        iconPath={'/assets/courses-icon.svg'}
        color={'gray'}
      />
      <SidebarItem
        locationName={'Tickets'}
        path={'/tickets'}
        iconPath={'/assets/info-icon.svg'}
        color={'gray'}
      />
      <BreakLine />
      <SidebarLogout aria-label={'Logout button'} onClick={logout}>
        <CustomSvgIcon path={'/assets/logout.svg'} title={'LogOut'} />
        <StyledText>Log out</StyledText>
      </SidebarLogout>
    </StyledSidebar>
  )
})

export default AdminSidebar
