import { FC } from 'react'
import { Formik } from 'formik'
import {
  FormMultySelect,
  FormSelectInput,
  FormTextInput
} from '../../../components/_Form'
import {
  FormContainer,
  FormMainContainer,
  FormSupportContainer,
  FormSupportContainerBottom,
  Page
} from '../../../components/_Page'
import { StyledButton } from '../../../components/CustomTable/styles'
import { IAdminClassForm } from './AdminClassForm.types'
import { adminClassFormSchema } from './schemas/AdminClassForm.schema'
import { useAdminClassForm } from './hooks/useAdminClassForm'

export const AdminClassForm: FC = () => {
  const {
    isNew,
    isPendingAdminClass,
    isPendingAdminClassesFrom,
    schoolItems,
    studentItems,
    teacherItems,
    formValues,
    formSubmit
  } = useAdminClassForm()

  if ((!isNew && isPendingAdminClass) || !formValues) {
    return <Page />
  }

  return (
    <Formik<IAdminClassForm>
      initialValues={formValues}
      onSubmit={formSubmit}
      validationSchema={adminClassFormSchema}
    >
      {({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            <FormContainer>
              <FormMainContainer>
                <FormTextInput<IAdminClassForm>
                  name={'name'}
                  label={'Name of class'}
                  placeholder={'Enter name'}
                />

                <FormSelectInput<IAdminClassForm>
                  name={'schoolId'}
                  label={'School'}
                  placeholder={'Select a school name'}
                  options={schoolItems}
                />

                <FormMultySelect<IAdminClassForm>
                  name="studentIds"
                  items={studentItems}
                  label="Students"
                  placeholder="Select students"
                />

                <FormMultySelect<IAdminClassForm>
                  name="teacherIds"
                  items={teacherItems}
                  label="Teacher"
                  placeholder="Select a teacher"
                />
              </FormMainContainer>
              <FormSupportContainer>
                <FormSupportContainerBottom>
                  <StyledButton
                    type={'submit'}
                    size={'large'}
                    variant={'contained'}
                    color={'primary'}
                    disabled={isPendingAdminClassesFrom}
                  >
                    Confirm
                  </StyledButton>
                </FormSupportContainerBottom>
              </FormSupportContainer>
            </FormContainer>
          </form>
        )
      }}
    </Formik>
  )
}
