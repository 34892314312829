import { useFormikContext } from 'formik'
import { FieldValue } from '../_Form.types'
import { ChangeEvent, memo, useCallback, useState } from 'react'
import { IFormImgLoaderProps } from './FormFileLoader.types'
import { ErrorText, StyledLabelText } from './FormFileLoader.styles'
import { FormRow } from '../FormRow/FormRow'

const FormFileLoaderNonMemo = <FormValues extends FieldValue>({
  name: fieldName,
  label,
  type,
  accept,
  ...props
}: IFormImgLoaderProps<FormValues>) => {
  const [fileName, setFileName] = useState<string | undefined>(undefined)
  const formContext = useFormikContext<FormValues>()
  const isTouched = formContext.touched[fieldName]

  const isError = isTouched && !!formContext.errors[fieldName]

  const errorText = isError
    ? (formContext.errors as Record<string, string>)[fieldName]
    : ''

  const updateFile = useCallback((files: FileList | null) => {
    const file = files ? files[0] : null
    setFileName(file?.name)
    formContext.setFieldTouched(fieldName, true, false)
    formContext.setFieldValue(fieldName, file, true)
  }, [])

  const onChangeHandler: (event: ChangeEvent<HTMLInputElement>) => void =
    useCallback(
      (event) => {
        updateFile(event.target.files)
      },
      [updateFile]
    )

  const isLoadingDisabled = type === 'teacher' || type === 'student'

  return (
    <FormRow label={label}>
      <label>
        <StyledLabelText>{fileName || 'Upload file'}</StyledLabelText>
        <input
          type="file"
          accept={accept}
          alt={fieldName}
          onChange={onChangeHandler}
          style={{ display: 'none' }}
          disabled={isLoadingDisabled}
          {...props}
        />
      </label>
      {!!errorText && <ErrorText>{errorText}</ErrorText>}
    </FormRow>
  )
}

export const FormFileLoader = memo(
  FormFileLoaderNonMemo
) as typeof FormFileLoaderNonMemo
