import { styled } from '@mui/system'
import { colors } from '../../types'
import { ACTIVITY_LEVELS_MAP } from '../../utils/constants'
import { IActivityLevel } from '../../services'

const initLevel = ACTIVITY_LEVELS_MAP[IActivityLevel.a1_beginner]
export const StyledActivityLevel = styled('div')<{
  bgColor: colors
  textColor: colors
  borderColor: colors
}>(
  ({
    bgColor = initLevel.bgColor,
    textColor = initLevel.textColor,
    borderColor = initLevel.borderColor
  }) => ({
    display: 'inline-block',
    fontSize: '12px',
    lineHeight: '18px',
    height: '18px',
    borderRadius: '12px',
    padding: '2px 12px',
    backgroundColor: bgColor,
    border: `1px solid ${borderColor}`,
    color: textColor
  })
)
