import { useCallback, useEffect } from 'react'
import { StyledTicketPage } from './Tickets.style'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { getTickets } from '../../services/tickets/tickets.service'
import { TICKET_PAGE_SIZE } from '../../utils/constants'
import CustomTable from '../../components/CustomTable'
import { useTicketTableHeader } from './hooks/useTicketTableHeader'
import { useTicketTableRows } from './hooks/useTicketTableRows'
import { ISortCallbackParams } from '../../types'
import { useTicketTableFilter } from './hooks/useTicketTableFilter'
import { PageTitle } from '../../components/_Page/'

export const Tickets = () => {
  const {
    data: { tickets, count: ticketsCount }
  } = useAppSelector((state) => state.tickets)

  const dispatch = useAppDispatch()

  const tableHeader = useTicketTableHeader()
  const tableRows = useTicketTableRows(tickets)
  const tableFilters = useTicketTableFilter()

  const sortCallbackHandler = useCallback((params: ISortCallbackParams) => {
    dispatch(getTickets(params))
  }, [])

  useEffect(() => {
    dispatch(getTickets({ page: 1 }))
  }, [])

  return (
    <StyledTicketPage>
      <PageTitle>Tickets</PageTitle>
      <CustomTable
        tableFiltersData={tableFilters}
        tableAriaLabel={'Tickets table'}
        dataCount={ticketsCount}
        tableHead={tableHeader}
        rowsCount={TICKET_PAGE_SIZE}
        rows={tableRows}
        defaultSortColumn={''}
        sortCallback={sortCallbackHandler}
        tableSubtitle={`You have ${ticketsCount} Asks`}
        searchPlaceholder={'Look a ticket'}
      />
    </StyledTicketPage>
  )
}
