import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Dropdown,
  ITooltipItemsProps,
  TooltipItems
} from '../../../components/_UI'
import { IAdminCourse } from '../../../store/courses/courses.types'
import { ICoursesTable } from '../Courses.types'

export const useCoursesTableRows = (
  courses: IAdminCourse[],
  options: {
    editCourse: (id: string) => void
    deleteCourse: (id: string) => void
  }
) => {
  const navigate = useNavigate()

  const { editCourse, deleteCourse } = options
  return useMemo(
    () =>
      courses.map((course) => {
        const { name, activitiesCount, unitsCount, id } = course

        const tooltipItems: ITooltipItemsProps['items'] = [
          {
            type: 'button',
            title: 'delete',
            handler: () => deleteCourse(id)
          },
          {
            type: 'button',
            title: 'edit',
            handler: () => editCourse(id)
          }
        ]

        const result: ICoursesTable & {
          rowAction: () => void
          excludeAction: (keyof ICoursesTable)[]
        } = {
          name,
          units: unitsCount,
          activities: activitiesCount,
          rowAction: () => navigate(`/courses/${id}`),
          excludeAction: ['actions'],
          actions: (
            <Dropdown>
              <TooltipItems items={tooltipItems} />
            </Dropdown>
          )
        }

        return result
      }),
    [courses]
  )
}
