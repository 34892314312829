import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Page, PageTitleBox } from '../../components/_Page'
import { StyledButton } from '../../components/CustomTable/styles'
import { useAppDispatch, useAppSelector } from '../../hooks'
import {
  deleteCourse,
  deleteUnit,
  getCourse,
  getCourseCategory,
  getUnit,
  getUnits,
  IGetUnitResponse,
  IUnitStatus
} from '../../services'
import { ISortCallbackParams } from '../../types'
import CustomTable from '../../components/CustomTable'
import { UNITS_PAGE_SIZE } from '../../utils/constants'
import { useCourseViewTableHeader } from './hooks/useCourseViewTableHeader'
import { useCourseViewTableRows } from './hooks/useCourseViewTableRows'
import CustomModal from '../../components/CustomModal'
import { unitsActions } from '../../store/units/units.reducer'
import { CourseForm, ICourseForm, IUnitForm, UnitForm } from '../_Common'
import { Modal } from '../../components/_UI'
import { APP_ROUTES } from '../../core/router/appRoutes'
import {
  StyledCourseViewActions,
  StyledCourseViewActionsTop
} from './CourseView.style'

export const CourseView: FC = () => {
  const queryParams = useParams()

  const courseId = queryParams.id!

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const course = useAppSelector((state) => state.courses.course)
  const {
    isNeedRefetch: isNeedRefetchUnits,
    data: { units, count: unitsCount }
  } = useAppSelector((state) => state.units.units)

  const [isOpenFormModal, setIsOpeFormModal] = useState(false)
  const [isOpenEditFormModal, setIsOpenEditFormModal] = useState(false)
  const [getUnitsParams, setGetUnitsParams] = useState<ISortCallbackParams>({
    page: 1,
    sort: 'name:asc'
  })
  const [deleteId, setDeleteId] = useState<string | undefined>(undefined)
  const [isOpenDeleteModal, setIsOpeDeleteModal] = useState(false)
  const [isOpenDeleteCourseModal, setIsOpeDeleteCourseModal] = useState(false)
  const [formInitialValues, setFormInitialValues] = useState<
    IUnitForm | undefined
  >()

  const [editFormInitialValues, setEditFormInitialValues] = useState<
    ICourseForm | undefined
  >(undefined)

  const isPending = course.isPending
  const courseName = course.data?.name ?? ''
  const logoUrl = course.data?.logoUrl
  const categoryName = course.data?.category.name ?? ''

  const toggleFormHandler = useCallback(() => {
    setFormInitialValues(undefined)
    setIsOpeFormModal(!isOpenFormModal)
  }, [isOpenFormModal])

  const toggleEditFormHandler = useCallback(() => {
    setIsOpenEditFormModal(!isOpenEditFormModal)
  }, [isOpenEditFormModal])

  const toggleDeleteCourseHandler = useCallback(() => {
    setIsOpeDeleteCourseModal(!isOpenDeleteCourseModal)
  }, [isOpenDeleteCourseModal])

  useEffect(() => {
    dispatch(getCourseCategory())
  }, [])

  useEffect(() => {
    if (!course.data) return

    const {
      logoUrl,
      logoPath,
      category: courseCategory,
      ...currentCourse
    } = course.data

    setEditFormInitialValues({
      logoUrl,
      logoPath,
      categoryId: courseCategory.id,
      ...currentCourse
    })
  }, [course.data])

  useEffect(() => {
    if (courseId !== course.data?.id) {
      dispatch(getCourse({ id: courseId }))
    }
  }, [courseId, course.data])

  useEffect(() => {
    if (isNeedRefetchUnits) {
      dispatch(getUnits({ courseId, ...getUnitsParams }))
      dispatch(unitsActions.setIsNeedRefetchUnits(false))
    }
  }, [isNeedRefetchUnits, courseId, getUnitsParams])

  useEffect(() => {
    dispatch(getUnits({ courseId, ...getUnitsParams }))
  }, [courseId, getUnitsParams])

  const sortCallbackHandler = useCallback((params: ISortCallbackParams) => {
    setGetUnitsParams(params)
  }, [])

  const setDeleteUnitHandler = useCallback((courseId: string) => {
    setIsOpeDeleteModal(true)
    setDeleteId(courseId)
  }, [])

  const closeDeleteModal = useCallback(() => {
    setIsOpeDeleteModal(false)
  }, [])

  const closeDeleteCourseModal = useCallback(() => {
    setIsOpeDeleteCourseModal(false)
  }, [])

  const deleteUnitHandler = useCallback(() => {
    if (!deleteId) return

    setIsOpeDeleteModal(false)
    dispatch(deleteUnit({ id: deleteId })).then((params) => {
      if (params.meta.requestStatus === 'fulfilled') {
        dispatch(unitsActions.setIsNeedRefetchUnits(true))
      }
    })
  }, [deleteId])

  const deleteCourseHandler = useCallback(() => {
    if (!courseId) return

    setIsOpeDeleteCourseModal(false)
    dispatch(deleteCourse({ id: courseId })).then((params) => {
      if (params.meta.requestStatus === 'fulfilled') {
        navigate(APP_ROUTES.COURSES)
      }
    })
  }, [courseId])

  const editUnit = useCallback((unitId: string) => {
    dispatch(getUnit({ id: unitId })).then((params) => {
      if (params.meta.requestStatus === 'fulfilled') {
        const { status, logoUrl, logoPath, ...payload } =
          params.payload as IGetUnitResponse

        setFormInitialValues({
          draft: status === IUnitStatus.draft,
          logoUrl,
          logoPath,
          ...payload
        })
        setIsOpeFormModal(true)
      }
    })
  }, [])

  const tableHeader = useCourseViewTableHeader()
  const tableRows = useCourseViewTableRows(units, {
    editUnit,
    deleteUnit: setDeleteUnitHandler
  })

  const formSubmitSuccessHandler = useCallback(() => {
    setIsOpeFormModal(false)
    dispatch(unitsActions.setIsNeedRefetchUnits(true))
  }, [])

  const editFormSubmitSuccessHandler = useCallback(() => {
    setIsOpenEditFormModal(false)
    dispatch(getCourse({ id: courseId }))
  }, [])

  if (isPending) return <Page />

  return (
    <Page>
      <PageTitleBox
        logoSrc={logoUrl}
        title={courseName}
        actions={
          <StyledCourseViewActions>
            <StyledCourseViewActionsTop>
              <StyledButton
                type={'button'}
                size={'large'}
                variant={'outlined'}
                color={'primary'}
                onClick={toggleEditFormHandler}
              >
                Edit
              </StyledButton>
              <StyledButton
                type={'button'}
                size={'large'}
                variant={'contained'}
                color={'primary'}
                onClick={toggleFormHandler}
              >
                Add unit
              </StyledButton>
            </StyledCourseViewActionsTop>

            <StyledButton
              type={'button'}
              size={'large'}
              variant={'outlined'}
              color={'error'}
              onClick={toggleDeleteCourseHandler}
            >
              Delete Course
            </StyledButton>
          </StyledCourseViewActions>
        }
      >
        {categoryName}
      </PageTitleBox>

      <CustomTable
        tableAriaLabel={'Units table'}
        dataCount={unitsCount}
        tableHead={tableHeader}
        rowsCount={UNITS_PAGE_SIZE}
        rows={tableRows}
        defaultSortColumn={'name'}
        sortCallback={sortCallbackHandler}
        tableSubtitle={''}
        searchPlaceholder={'Look for a unit'}
      />

      <CustomModal
        open={isOpenEditFormModal}
        title={'Edit Course'}
        handleClose={toggleEditFormHandler}
        width={'404px'}
      >
        <CourseForm
          initialValues={editFormInitialValues}
          onSuccess={editFormSubmitSuccessHandler}
          onClose={toggleEditFormHandler}
          mode="edit"
        />
      </CustomModal>

      <CustomModal
        open={isOpenFormModal}
        title={formInitialValues ? 'Edit a Unit' : 'Add a Unit'}
        handleClose={toggleFormHandler}
        width={'404px'}
      >
        <UnitForm
          courseId={courseId}
          initialValues={formInitialValues}
          onSuccess={formSubmitSuccessHandler}
          onClose={toggleFormHandler}
        />
      </CustomModal>

      <Modal
        isOpen={isOpenDeleteModal}
        title={'Delete unit'}
        onClose={closeDeleteModal}
        onSuccess={deleteUnitHandler}
        confirmText={'Delete'}
      >
        You are about to delete unit, this action is permanent. Do you want to
        proceed?
      </Modal>

      <Modal
        isOpen={isOpenDeleteCourseModal}
        title={'Delete course'}
        onClose={closeDeleteCourseModal}
        onSuccess={deleteCourseHandler}
        confirmText={'Delete'}
      >
        You are about to delete course, this action is permanent. Do you want to
        proceed?
      </Modal>
    </Page>
  )
}
