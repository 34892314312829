export const separatorOptions = [
  {
    name: 'Comma',
    value: ','
  },
  {
    name: 'Semicolon',
    value: ';'
  },
  {
    name: 'Colon',
    value: ':'
  }
]
