import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Dropdown,
  ITooltipItemsProps,
  TooltipItems
} from '../../../components/_UI'
import { IAdminClass } from '../../../store/adminClasses/adminClasses.types'
import { IAdminClassesTable } from '../AdminClasses.types'
import { APP_ROUTES } from '../../../core/router/appRoutes'

export const useAdminClassesTableRows = (
  adminClasses: IAdminClass[],
  options: {
    openTeachersMessageModal: (id: string) => void
    openClassMessageModal: (id: string) => void
    openClass: (id: string) => void
  }
) => {
  const navigate = useNavigate()

  const { openTeachersMessageModal, openClassMessageModal, openClass } = options

  return useMemo(
    () =>
      adminClasses.map((adminClass) => {
        const { id, name, school, studentsCount } = adminClass

        const tooltipItems: ITooltipItemsProps['items'] = [
          {
            type: 'button',
            title: 'Send message to teachers',
            handler: () => openTeachersMessageModal(id)
          },
          {
            type: 'button',
            title: 'Send message to class',
            handler: () => openClassMessageModal(id)
          },
          {
            type: 'button',
            title: 'Go to class details',
            handler: () => openClass(id)
          }
        ]

        const result: IAdminClassesTable & {
          rowAction: () => void
          excludeAction: (keyof IAdminClassesTable)[]
        } = {
          name,
          students: studentsCount,
          rowAction: () => navigate(`${APP_ROUTES.EDIT_ADMIN_CLASS}/${id}`),
          school: school.shortName,
          excludeAction: ['actions'],
          actions: (
            <Dropdown>
              <TooltipItems items={tooltipItems} />
            </Dropdown>
          )
        }

        return result
      }),
    [adminClasses]
  )
}
