import { createAsyncThunk } from '@reduxjs/toolkit'
import { ICommonChunkConfig, IErrorResponse } from '../service.types'
import { del, get, patch, post, put } from '../../axios'
import { API_ROUTES } from '../../core/router/apiRoutes'
import { UNITS_PAGE_SIZE } from '../../utils/constants'
import {
  IActivityCRUD,
  IActivityPatchRequest,
  ICreateActivityRequest,
  IGetActivitiesRequest,
  IGetActivitiesResponse,
  IGetActivityRequest,
  IGetActivityResponse,
  IQuestionForActivityRequest
} from './activities.types'

export const getActivities = createAsyncThunk<
  IGetActivitiesResponse,
  IGetActivitiesRequest,
  ICommonChunkConfig
>(
  'activities/getActivities',
  async ({ unitId, ...data }, { rejectWithValue }) => {
    try {
      return await get<IGetActivitiesResponse>({
        path: API_ROUTES.GET_ACTIVITIES(unitId),
        params: {
          pageSize: UNITS_PAGE_SIZE,
          ...data
        }
      })
    } catch (error: any) {
      return rejectWithValue(error as IErrorResponse)
    }
  }
)

export const getActivity = createAsyncThunk<
  IGetActivityResponse,
  IGetActivityRequest,
  ICommonChunkConfig
>('activities/getActivity', async ({ id }, { rejectWithValue }) => {
  try {
    return await get<IGetActivityResponse>({
      path: `${API_ROUTES.ACTIVITIES}/${id}`,
      params: {}
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const updateQuestions = createAsyncThunk<
  void,
  IQuestionForActivityRequest,
  ICommonChunkConfig
>(
  'activities/updateQuestions',
  async ({ activityId, ...data }, { rejectWithValue }) => {
    try {
      return await put({
        path: `${API_ROUTES.PUT_QUESTIONS(activityId)}`,
        data
      })
    } catch (error: any) {
      return rejectWithValue(error as IErrorResponse)
    }
  }
)

export const createActivity = createAsyncThunk<
  IActivityCRUD,
  ICreateActivityRequest,
  ICommonChunkConfig
>('activities/createActivity', async (data, { rejectWithValue }) => {
  try {
    return await post<IActivityCRUD>({
      path: API_ROUTES.ACTIVITIES,
      data: data
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const patchActivity = createAsyncThunk<
  IActivityCRUD,
  IActivityPatchRequest,
  ICommonChunkConfig
>('activities/patchActivity', async ({ id, ...data }, { rejectWithValue }) => {
  try {
    return await patch<IActivityCRUD>({
      path: `${API_ROUTES.ACTIVITIES}/${id}`,
      data: data
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const deleteActivity = createAsyncThunk<
  void,
  { id: string },
  ICommonChunkConfig
>('activities/deleteActivity', async ({ id }, { rejectWithValue }) => {
  try {
    return await del<void>({
      path: `${API_ROUTES.ACTIVITIES}/${id}`,
      data: {}
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})
