import * as Yup from 'yup'
import 'yup-phone-lite'
import { SchoolLevel } from '../../../../types'
import { IMAGE_MAX_SIZE } from '../../../../core/router/apiRoutes'

export const schoolFormSchema = Yup.object().shape({
  longName: Yup.string().required('Name is required'),
  country: Yup.string().required('Country is required'),
  shortName: Yup.string().required('Short name is required'),
  level: Yup.string()
    .oneOf(Object.values(SchoolLevel))
    .required('Level is required'),
  managerFullName: Yup.string().required('Manager name is required'),
  managerEmail: Yup.string()
    .email('Invalid email')
    .required('Manager email is required'),
  managerPhone: Yup.string()
    .phone('IN', 'Invalid phone')
    .required('Manager phone is required'),
  licenseStart: Yup.string().required('Date is required'),
  licenseEnd: Yup.string().required('Date is required'),
  courseIds: Yup.array().of(Yup.string()),
  logoPath: Yup.mixed()
    .nullable()
    .test('fileFormat', 'Invalid file format', (value: any) => {
      if (!value) return true

      if (value.type && value.size) {
        const supportedFormats = ['image/jpeg', 'image/png', 'image/gif']
        return supportedFormats.includes(value.type)
      }

      return typeof value === 'string'
    })
    .test('fileSize', 'File is too large', (value: any) => {
      if (!value || typeof value === 'string') return true

      return value.size <= IMAGE_MAX_SIZE
    }),
  isCompany: Yup.boolean()
})
