import { StyledNavLink, StyledSidebarItem } from './styles'
import { CustomSvgIcon } from '../../../../components/CustomSvgIcon'
import { colors } from '../../../../types'
import { memo } from 'react'
import { ISidebarItemProps } from './types'
import { useLocation } from 'react-router-dom'

const SidebarItem = memo((props: ISidebarItemProps) => {
  const { pathname } = useLocation()
  const isActive = pathname === props.path
  const textColor = props.color ? colors[props.color] : colors.black
  const iconColor = isActive ? 'white' : props.color || 'black'
  return (
    <StyledSidebarItem isActive={isActive}>
      <StyledNavLink
        to={props.path}
        style={() => {
          return {
            color: isActive ? colors.white : textColor,
            fontWeight: isActive ? 700 : 500,
            fontSize: '12px'
          }
        }}
      >
        {props.iconPath && (
          <CustomSvgIcon
            path={props.iconPath}
            color={iconColor}
            title={`${props.locationName} icon`}
          />
        )}
        {props.locationName}
      </StyledNavLink>
    </StyledSidebarItem>
  )
})

export default SidebarItem
