import { ChangeEvent, KeyboardEvent } from 'react'
import {
  StyledIconButton,
  StyledInputLabel,
  StyledInputWrapper,
  StyledOutlinedInput
} from './styles'
import { IFormInputProps } from './types'

const CustomOutlinedInput = (props: IFormInputProps) => {
  const {
    label,
    value,
    onChange,
    type,
    width,
    placeholder,
    iconAlt,
    iconPath,
    iconOnClick,
    adornmentBg,
    onKeyDown
  } = props

  return (
    <StyledInputWrapper>
      {label && <StyledInputLabel>{label}</StyledInputLabel>}
      <StyledOutlinedInput
        value={value}
        width={width}
        type={type}
        placeholder={placeholder}
        disabled={props.disabled}
        onChange={(e: ChangeEvent<HTMLInputElement>) => {
          onChange(e.target.value)
        }}
        onKeyDown={
          onKeyDown ? (e: KeyboardEvent) => onKeyDown(e.key) : undefined
        }
        endAdornment={
          <StyledIconButton
            type={'button'}
            onClick={iconOnClick}
            adornmentBg={adornmentBg}
          >
            <img src={iconPath} alt={iconAlt} />
          </StyledIconButton>
        }
      />
    </StyledInputWrapper>
  )
}

export default CustomOutlinedInput
