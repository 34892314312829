import { IModalProps } from './types'
import { memo } from 'react'
import {
  StyledBox,
  StyledCloseModalButton,
  StyledModal,
  StyledModalHeader,
  StyledModalTitle
} from './styles'

const CustomModal = (props: IModalProps) => {
  const { open, handleClose, children, title, width } = props

  return (
    <StyledModal
      open={open}
      onClick={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <StyledBox onClick={(e) => e.stopPropagation()} width={width}>
        <StyledModalHeader>
          <StyledModalTitle id="modal-modal-title" variant="h4">
            {title}
          </StyledModalTitle>
          <StyledCloseModalButton type={'button'} onClick={handleClose} />
        </StyledModalHeader>
        {children}
      </StyledBox>
    </StyledModal>
  )
}

export default memo(CustomModal)
