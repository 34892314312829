import { FC, memo, useCallback, useRef, useState } from 'react'
import { questionFormSchema } from './schemas/QuestionForm.schema'
import { FieldArray, Formik } from 'formik'
import {
  StyledAddQuestionContainer,
  StyledAnswersContainer,
  StyledAnswersContainerItem,
  StyledDeleteForTextField,
  StyledDeleteIcon,
  StyledFieldLabel,
  StyledHintsContainer,
  StyledPreviewIcon,
  StyledQuestion,
  StyledQuestionContainer,
  StyledQuestionItem,
  StyledSubmitButton
} from './QuestionForm.style'
import { FormRow, FormTextInput } from '../../../../components/_Form'
import { StyledButton } from '../../../../components/CustomTable/styles'
import { IQuestionForm, IQuestionFormProps } from './QuestionForm.type'
import { Modal } from '../../../../components/_UI'
import { useFormQuestionSubmit } from './hooks/useFormQuestionSubmit'
import { QuestionFormBreakpointField } from './components/QuestionFormBreakpointField/QuestionFormBreakpointField'

const baseInitialValues: IQuestionForm = {
  questions: [
    {
      text: '',
      breakpoint: undefined,
      hintText: '',
      answers: [{ text: '' }],
      wrongAnswers: [{ text: '' }]
    }
  ]
}

export const QuestionForm: FC<IQuestionFormProps> = memo(
  ({
    initialValues = baseInitialValues,
    activityId,
    toggleQuestionPreview
  }) => {
    const formSubmit = useFormQuestionSubmit(activityId)

    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)

    const [removeCb, setRemoveCb] = useState<{
      remove: (() => void) | undefined
    }>({
      remove: undefined
    })

    const lastAddedInputName = useRef<string | undefined>()

    const closeDeleteModal = useCallback(() => {
      setIsOpenDeleteModal(false)
    }, [])

    const deleteQuestionHandler = useCallback(() => {
      if (removeCb.remove) {
        removeCb.remove()
        setIsOpenDeleteModal(false)
      }
    }, [removeCb])

    return (
      <>
        <Formik<IQuestionForm>
          initialValues={initialValues}
          onSubmit={formSubmit}
          validationSchema={questionFormSchema}
        >
          {({ values, handleSubmit }) => {
            return (
              <form onSubmit={handleSubmit}>
                <FieldArray name="questions">
                  {({ push: pushQuestion, remove: removeQuestion }) => (
                    <>
                      <StyledQuestion>
                        {values.questions.map((question, questionIndex) => {
                          const name = `questions[${questionIndex}].text`
                          const isAutofocus =
                            name === lastAddedInputName.current

                          return (
                            <StyledQuestionItem key={questionIndex}>
                              <StyledFieldLabel>{`${
                                questionIndex + 1
                              }.`}</StyledFieldLabel>

                              <StyledQuestionContainer>
                                <FormTextInput
                                  name={name}
                                  label={`Question`}
                                  placeholder={'Enter a question'}
                                  autoComplete={'off'}
                                  autoFocus={isAutofocus}
                                />

                                <QuestionFormBreakpointField
                                  questionIndex={questionIndex}
                                />

                                <FieldArray
                                  name={`questions[${questionIndex}].answers`}
                                >
                                  {({
                                    push: pushAnswer,
                                    remove: removeAnswer
                                  }) => {
                                    return (
                                      <StyledAnswersContainer>
                                        <FormRow label={'Answers'} />
                                        {question.answers.map(
                                          (_, answerIndex) => {
                                            const name = `questions[${questionIndex}].answers[${answerIndex}].text`
                                            const isAutofocus =
                                              lastAddedInputName.current ===
                                              name

                                            return (
                                              <StyledAnswersContainerItem
                                                key={answerIndex}
                                              >
                                                <FormTextInput
                                                  name={name}
                                                  placeholder={'Enter a answer'}
                                                  autoComplete={'off'}
                                                  autoFocus={isAutofocus}
                                                />

                                                {!!answerIndex && (
                                                  <StyledDeleteForTextField
                                                    onClick={() =>
                                                      removeAnswer(answerIndex)
                                                    }
                                                  />
                                                )}
                                              </StyledAnswersContainerItem>
                                            )
                                          }
                                        )}
                                        <StyledButton
                                          type="button"
                                          variant={'contained'}
                                          size={'medium'}
                                          onClick={() => {
                                            lastAddedInputName.current = `questions[${questionIndex}].answers[${question.answers.length}].text`
                                            pushAnswer({ text: '' })
                                          }}
                                        >
                                          Add
                                        </StyledButton>
                                      </StyledAnswersContainer>
                                    )
                                  }}
                                </FieldArray>
                                <FieldArray
                                  name={`questions[${questionIndex}].wrongAnswers`}
                                >
                                  {({
                                    push: pushWrongAnswer,
                                    remove: removeWrongAnswer
                                  }) => (
                                    <StyledAnswersContainer>
                                      <FormRow label={'Wrong answers'} />
                                      {question.wrongAnswers.map(
                                        (_, answerIndex) => {
                                          const name = `questions[${questionIndex}].wrongAnswers[${answerIndex}].text`
                                          const isAutofocus =
                                            lastAddedInputName.current === name

                                          return (
                                            <StyledAnswersContainerItem
                                              key={answerIndex}
                                            >
                                              <FormTextInput
                                                name={name}
                                                placeholder={
                                                  'Enter a wrong answer'
                                                }
                                                autoComplete={'off'}
                                                autoFocus={isAutofocus}
                                              />

                                              <StyledDeleteForTextField
                                                onClick={() =>
                                                  removeWrongAnswer(answerIndex)
                                                }
                                              />
                                            </StyledAnswersContainerItem>
                                          )
                                        }
                                      )}
                                      <StyledButton
                                        type="button"
                                        variant={'contained'}
                                        size={'medium'}
                                        onClick={() => {
                                          lastAddedInputName.current = `questions[${questionIndex}].wrongAnswers[${question.wrongAnswers.length}].text`
                                          pushWrongAnswer({ text: '' })
                                        }}
                                      >
                                        Add
                                      </StyledButton>
                                    </StyledAnswersContainer>
                                  )}
                                </FieldArray>
                                <StyledHintsContainer>
                                  <FormRow label="Hint to the question" />
                                  <FormTextInput
                                    multiline
                                    name={`questions[${questionIndex}].hintText`}
                                    placeholder={'Enter a hint text'}
                                    autoComplete={'off'}
                                    autoFocus={
                                      lastAddedInputName.current ===
                                      `questions[${questionIndex}].hintText`
                                    }
                                  />
                                </StyledHintsContainer>
                              </StyledQuestionContainer>

                              <StyledPreviewIcon
                                onClick={() => toggleQuestionPreview(question)}
                              />

                              <StyledDeleteIcon
                                onClick={() => {
                                  setRemoveCb({
                                    remove: () => removeQuestion(questionIndex)
                                  })
                                  setIsOpenDeleteModal(true)
                                }}
                              />
                            </StyledQuestionItem>
                          )
                        })}
                      </StyledQuestion>
                      <StyledAddQuestionContainer>
                        <StyledButton
                          type="button"
                          variant={'contained'}
                          size={'large'}
                          onClick={() => {
                            lastAddedInputName.current = `questions[${values.questions.length}].text`
                            pushQuestion(baseInitialValues.questions[0])
                          }}
                        >
                          Add Question
                        </StyledButton>
                      </StyledAddQuestionContainer>
                    </>
                  )}
                </FieldArray>

                <StyledSubmitButton
                  type={'submit'}
                  variant={'contained'}
                  size={'large'}
                >
                  Save Activity
                </StyledSubmitButton>
              </form>
            )
          }}
        </Formik>

        <Modal
          isOpen={isOpenDeleteModal}
          title={'Delete question'}
          onClose={closeDeleteModal}
          onSuccess={deleteQuestionHandler}
          confirmText={'Delete'}
        >
          Delete this question?
        </Modal>
      </>
    )
  }
)
