import { styled } from '@mui/material/styles'
import { Button, Tooltip, tooltipClasses, TooltipProps } from '@mui/material'

export const HtmlTooltip = styled(
  ({
    className,
    children,
    ...props
  }: TooltipProps & { tooltipTopInset?: number }) => (
    <Tooltip {...props} classes={{ popper: className }}>
      {children}
    </Tooltip>
  ),
  {
    shouldForwardProp: (prop) => prop !== 'tooltipTopInset'
  }
)(({ theme, tooltipTopInset }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.customColors.white,
    maxWidth: 400,
    padding: '8px',
    boxSizing: 'border-box',
    margin: tooltipTopInset ? '0' : '0 !important',
    marginTop: tooltipTopInset ?? `${tooltipTopInset}px !important`,
    borderRadius: '16px',
    boxShadow: '0px 2px 8px 0px rgba(55, 82, 102, 0.16)',
    color: theme.palette.customColors.black
  }
}))

export const StyledTooltipButton = styled(Button)(() => ({
  display: 'flex',
  alignItems: 'center',
  width: '38px',
  height: '24px',
  margin: '0 auto',
  '&::after': {
    width: '24px',
    height: '24px',
    content: 'url(/assets/ellipsis-icon.svg)'
  }
}))
