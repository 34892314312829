import { configureStore } from '@reduxjs/toolkit'
import { schoolsReducer } from './schools/schools.reducer'
import { ticketReducer } from './tickets/tickets.reducer'
import { authReducer } from './auth/auth.reducer'
import { unitsReducer } from './units/units.reducer'
import { coursesReducer } from './courses/courses.reducer'
import { activityReducer } from './activities/activities.reducer'
import { adminClassesReducer } from './adminClasses/adminClasses.reducer'
import { teachersReducer } from './teachers/teachers.reducer'
import { studentsReducer } from './students/students.reducer'

export const store = configureStore({
  reducer: {
    auth: authReducer,
    tickets: ticketReducer,
    schools: schoolsReducer,
    adminClasses: adminClassesReducer,
    teachers: teachersReducer,
    courses: coursesReducer,
    units: unitsReducer,
    activity: activityReducer,
    students: studentsReducer
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
