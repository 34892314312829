export interface IErrorResponse {
  message: string | Record<string, string[]>
}

export interface ICommonChunkConfig {
  rejectValue: IErrorResponse
}

export interface ICommonPageTableProps {
  page: number
  search?: string
  filters?: string[]
}

export interface ICommonPageRequest
  extends Omit<ICommonPageTableProps, 'page'> {
  page?: number
  allList?: boolean
}

export interface ICommonResponse<T> {
  data: T[]
  count: number
}

export interface IPerson {
  id: string
  username: string
  email: string
  fullName: string
}

export enum IQuestionType {
  test = 'test',
  voice = 'voice'
}

export enum IUnitStatus {
  draft = 'draft',
  published = 'published'
}

export enum IActivityLevel {
  a1_beginner = 'a1_beginner',
  a2_elementary = 'a2_elementary',
  b1_preintermediate = 'b1_preintermediate',
  b2_intermediate = 'b2_intermediate',
  c1_upperIntermediate = 'c1_upperIntermediate',
  c2_advanced = 'c2_advanced'
}

export enum IActivityStatus {
  published = 'published',
  draft = 'draft'
}

export enum SchoolType {
  school = 'school',
  company = 'company'
}

export interface IPersonFull {
  id: string
  username: string
  fullName: string
  email: string
  language: string
  type: 'admin' | 'student' | 'teacher'
  avatarUrl: string
  interfaceType: 'school'
  schools: {
    id: string
    shortName: string
    longName: string
  }[]
  classes: {
    id: string
    name: string
  }[]
  scoreFormat: 'percent'
}
