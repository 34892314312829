import { useAppDispatch, useAppSelector } from '../../../hooks'
import { useCallback, useEffect, useState } from 'react'
import { ISortCallbackParams } from '../../../types'
import {
  getAdminSchoolCountries,
  getAdminSchools
} from '../../../services/schools/schools.service'
import { schoolsActions } from '../../../store/schools/schools.reducer'

export const usePreparationSchools = () => {
  const dispatch = useAppDispatch()

  const { isNeedRefetch: isNeedRefetchSchools } = useAppSelector(
    (state) => state.schools.schools
  )

  const [getSchoolsParams, setGetSchoolsParams] = useState<ISortCallbackParams>(
    {
      page: 1
    }
  )

  useEffect(() => {
    dispatch(getAdminSchoolCountries())
  }, [])

  useEffect(() => {
    dispatch(getAdminSchools(getSchoolsParams))
  }, [getSchoolsParams])

  useEffect(() => {
    if (isNeedRefetchSchools) {
      dispatch(getAdminSchools(getSchoolsParams))
      dispatch(schoolsActions.setIsNeedRefetchSchools(false))
    }
  }, [isNeedRefetchSchools, getSchoolsParams])

  return useCallback((params: ISortCallbackParams) => {
    setGetSchoolsParams(params)
  }, [])
}
