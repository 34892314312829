import { useFormikContext } from 'formik'
import { FieldValue } from '../_Form.types'
import { ChangeEvent, memo, useCallback } from 'react'
import { IFormSwitchInputProps } from './FormSwitchInput.types'
import { FormRow } from '..'
import { Switch } from '../../_UI'

const FormSwitchInputNonMemo = <FormValues extends FieldValue>({
  name: fieldName,
  label
}: IFormSwitchInputProps<FormValues>) => {
  const formContext = useFormikContext<FormValues>()

  const onChangeHandler: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => void = useCallback((_, checked) => {
    formContext.setFieldValue(fieldName, checked)
  }, [])

  return (
    <FormRow type={'row'} label={label}>
      <Switch
        checked={formContext.values[fieldName]}
        onChange={onChangeHandler}
        name={fieldName}
      />
    </FormRow>
  )
}

export const FormSwitchInput = memo(
  FormSwitchInputNonMemo
) as typeof FormSwitchInputNonMemo
