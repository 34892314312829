import { styled as muiStyled } from '@mui/system'

export const StyledPage = muiStyled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  boxSizing: 'border-box',
  marginTop: '24px',
  gap: '8px'
}))
