import { JSX, memo, useEffect } from 'react'
import Cookies from 'js-cookie'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { getCurrentUserSelector } from '../../selectors'
import { ACCESS_TOKEN_NAME } from '../../../utils/constants'
import MainLayout from '../MainLayout'
import { getCurrentUser } from '../../../services/auth/auth.service'

interface IBaseLayoutProps {
  children: JSX.Element
}

const BaseLayout = memo<IBaseLayoutProps>(({ children }) => {
  const dispatch = useAppDispatch()

  const currentUser = useAppSelector(getCurrentUserSelector)
  const accessToken = Cookies.get(ACCESS_TOKEN_NAME)

  useEffect(() => {
    if (!currentUser && accessToken) {
      dispatch(getCurrentUser())
    }
  }, [currentUser, accessToken, dispatch])

  return <>{!currentUser ? children : <MainLayout>{children}</MainLayout>}</>
})

export default BaseLayout
