import { styled } from '@mui/system'
import { Box } from '../Box/Box'
import { Typography } from '@mui/material'

export const StyledTitleBoxContainer = styled(Box)(() => ({
  display: 'flex',
  gap: '16px'
}))

export const StyledTitleBoxContent = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  flexGrow: '1'
}))

export const StyledTitle = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '32px',
  marginBottom: '4px'
}))

export const StyledTitleActionContainers = styled('div')(() => ({
  display: 'flex',
  marginLeft: 'auto',
  flexDirection: 'row',
  gap: '12px'
}))

export const StyledTitleBoxMain = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexShrink: '0',
  flexGrow: '1',
  minWidth: '0'
}))
export const StyledTitleLogo = styled('img')(({ theme }) => ({
  display: 'inline-block',
  width: '88px',
  height: '88px',
  borderRadius: '12px',
  overflow: 'hidden',
  flexShrink: '0',
  background: theme.palette.customColors.white
}))
