import { useMemo } from 'react'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import { FormRow } from '../../../../../components/_Form'
import { useFormikContext } from 'formik'
import { ICourseForm } from '../../CourseForm.types'
import {
  StyledErrorMessage,
  StyledLanguageContainer,
  StyledLanguageItem
} from './FormLanguageField.styles'
import { COURSE_LANGUAGES } from '../../../../../utils/constants'

export const FormLanguageField = () => {
  const fieldName = 'language'

  const formContext = useFormikContext<ICourseForm>()
  const { setFieldValue, values } = formContext

  const isError = !!formContext.errors[fieldName]

  const message = isError
    ? (formContext.errors as Record<string, string>)[fieldName]
    : ''

  const languagesItems = useMemo(() => {
    const langItems: { name: string; value: string }[] = []

    for (const key in COURSE_LANGUAGES) {
      langItems.push({
        name: COURSE_LANGUAGES[key as keyof typeof COURSE_LANGUAGES],
        value: key
      })
    }

    return langItems
  }, [])

  return (
    <div>
      <FormRow label={'Languages'} />

      {!!message && <StyledErrorMessage>{message}</StyledErrorMessage>}

      <StyledLanguageContainer>
        {
          <RadioGroup
            onChange={(e) => {
              setFieldValue(fieldName, e.target.value)
            }}
          >
            {languagesItems.map(({ name, value }) => (
              <StyledLanguageItem key={value}>
                <FormControlLabel
                  value={value}
                  control={<Radio />}
                  label={name}
                  checked={values[fieldName] === value}
                />
              </StyledLanguageItem>
            ))}
          </RadioGroup>
        }
      </StyledLanguageContainer>
    </div>
  )
}
