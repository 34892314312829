import { FC, memo, useState } from 'react'
import { HtmlTooltip, StyledTooltipButton } from './Dropdown.style'
import { ClickAwayListener } from '@mui/material'
import { ICustomDropdownProps } from './Dropdown.types'

export const Dropdown: FC<ICustomDropdownProps> = memo(
  ({ title, children, tooltipTopInset }) => {
    const [isOpen, setIsOpen] = useState(false)

    return (
      <ClickAwayListener
        onClickAway={() => {
          setIsOpen(false)
        }}
      >
        <div>
          <HtmlTooltip
            PopperProps={{
              disablePortal: false
            }}
            open={isOpen}
            onClose={() => setIsOpen(false)}
            title={
              <div
                onClick={() => {
                  setIsOpen(false)
                }}
              >
                {children}
              </div>
            }
            disableFocusListener
            disableHoverListener
            disableTouchListener
            tooltipTopInset={tooltipTopInset}
            placement="bottom-end"
          >
            <div
              onClick={() => {
                setIsOpen(!isOpen)
              }}
            >
              {title ?? <StyledTooltipButton />}
            </div>
          </HtmlTooltip>
        </div>
      </ClickAwayListener>
    )
  }
)
