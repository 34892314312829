import { FC, PropsWithChildren } from 'react'
import CustomModal from '../../CustomModal'
import { Button } from '@mui/material'
import { IModalProps } from './Modal.types'
import { ModalBottomContainer, ModalContainer } from './Modal.style'

export const Modal: FC<PropsWithChildren<IModalProps>> = ({
  isOpen,
  title,
  onClose,
  onSuccess,
  width = '404px',
  children,
  confirmText = 'Confirm',
  confirmDisabled
}) => {
  return (
    <CustomModal
      open={isOpen}
      title={title}
      handleClose={onClose}
      width={width}
    >
      <ModalContainer>
        <div>{children}</div>
        <ModalBottomContainer>
          <Button
            type={'button'}
            size={'large'}
            variant={'outlined'}
            color={'primary'}
            onClick={() => onClose()}
          >
            Cancel
          </Button>

          <Button
            type={'button'}
            size={'large'}
            variant={'contained'}
            color={'primary'}
            onClick={onSuccess}
            disabled={confirmDisabled}
          >
            {confirmText}
          </Button>
        </ModalBottomContainer>
      </ModalContainer>
    </CustomModal>
  )
}
