import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  ICommonChunkConfig,
  IErrorResponse,
  IGetSchoolByIdRequest,
  ISchoolLogoRequest,
  ISchoolLogoResponse,
  ISchoolPatchRequest,
  ISchool,
  ICommonResponse,
  ICommonPageRequest
} from '..'
import { del, get, patch, post } from '../../axios'

import { API_ROUTES } from '../../core/router/apiRoutes'
import {
  ADMIN_SCHOOLS_PAGE_SIZE,
  ALL_LIST_PAGE_SIZE,
  BASE_SIZE
} from '../../utils/constants'
import {
  IBaseSchool,
  IGetAdminSchoolsCountriesResponse,
  IGetAdminSchoolsRequest,
  IGetAdminSchoolsResponse,
  ISchoolCreateRequest,
  ISchoolCRUD
} from './schools.types'

export const getAdminSchools = createAsyncThunk<
  IGetAdminSchoolsResponse,
  IGetAdminSchoolsRequest,
  ICommonChunkConfig
>(
  'adminSchools/getAdminSchools',
  async ({ allList, page, ..._data }, { rejectWithValue }) => {
    const params = allList
      ? {
          page: 1,
          pageSize: ALL_LIST_PAGE_SIZE,
          ..._data
        }
      : {
          page,
          pageSize: ADMIN_SCHOOLS_PAGE_SIZE,
          ..._data
        }
    try {
      return await get<IGetAdminSchoolsResponse>({
        path: API_ROUTES.GET_ADMIN_SCHOOLS,
        params
      })
    } catch (error: any) {
      return rejectWithValue(error as IErrorResponse)
    }
  }
)

export const getAdminSchoolCountries = createAsyncThunk<
  IGetAdminSchoolsCountriesResponse,
  void,
  ICommonChunkConfig
>(
  'adminSchools/getAdminSchoolCountries',
  async (_data, { rejectWithValue }) => {
    try {
      return await get<IGetAdminSchoolsCountriesResponse>({
        path: API_ROUTES.GET_ADMIN_SCHOOLS_COUNTRIES,
        params: {}
      })
    } catch (error: any) {
      return rejectWithValue(error as IErrorResponse)
    }
  }
)

export const getSchools = createAsyncThunk<
  ICommonResponse<IBaseSchool>,
  ICommonPageRequest,
  ICommonChunkConfig
>('find/schools', async ({ allList, page, ..._data }, { rejectWithValue }) => {
  const params = allList
    ? {
        page: 1,
        pageSize: ALL_LIST_PAGE_SIZE,
        ..._data
      }
    : {
        page,
        pageSize: BASE_SIZE,
        ..._data
      }

  try {
    return await get<ICommonResponse<IBaseSchool>>({
      path: API_ROUTES.FIND_SCHOOLS,
      params
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const createSchool = createAsyncThunk<
  ISchoolCRUD,
  ISchoolCreateRequest,
  ICommonChunkConfig
>(
  'adminSchools/createSchool',
  async ({ logoPath, ..._data }, { rejectWithValue }) => {
    const logoParams = logoPath ? { logoPath } : {}
    try {
      return await post<ISchoolCRUD>({
        path: API_ROUTES.SCHOOLS,
        data: {
          ...logoParams,
          ..._data
        }
      })
    } catch (error: any) {
      return rejectWithValue(error as IErrorResponse)
    }
  }
)

export const getSchool = createAsyncThunk<
  ISchool,
  IGetSchoolByIdRequest,
  ICommonChunkConfig
>('adminSchools/getSchool', async (_data, { rejectWithValue }) => {
  try {
    return await get<ISchool>({
      path: `${API_ROUTES.SCHOOLS}/${_data.id}`,
      params: {}
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const patchSchool = createAsyncThunk<
  ISchoolCRUD,
  ISchoolPatchRequest,
  ICommonChunkConfig
>(
  'adminSchools/patchSchool',
  async ({ id, logoPath, ..._data }, { rejectWithValue }) => {
    const logoParams = logoPath ? { logoPath } : {}
    try {
      return await patch<ISchoolCRUD>({
        path: `${API_ROUTES.SCHOOLS}/${id}`,
        data: {
          ..._data,
          ...logoParams
        }
      })
    } catch (error: any) {
      return rejectWithValue(error as IErrorResponse)
    }
  }
)

export const deleteSchool = createAsyncThunk<
  void,
  { id: string },
  ICommonChunkConfig
>('adminSchools/deleteSchool', async ({ id }, { rejectWithValue }) => {
  try {
    return await del<void>({
      path: `${API_ROUTES.SCHOOLS}/${id}`,
      data: {}
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const uploadSchoolLogo = createAsyncThunk<
  ISchoolLogoResponse,
  ISchoolLogoRequest,
  ICommonChunkConfig
>('adminSchools/uploadSchoolLogo', async (_data, { rejectWithValue }) => {
  try {
    return await post<ISchoolLogoResponse>(
      {
        path: API_ROUTES.SCHOOL_LOGO,
        data: _data
      },
      {},
      {
        'Content-Type': 'multipart/form-data'
      }
    )
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})
