import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAdminCoursesState } from './courses.types'
import {
  createCourse,
  createCourseCategory,
  getAdminCourse,
  getCourse,
  getCourseCategory,
  ICourse,
  patchCourse
} from '../../services/'

const initialState: IAdminCoursesState = {
  isPendingCourseFrom: false,
  course: {
    isPending: false,
    data: undefined
  },
  courses: {
    isPending: false,
    isNeedRefetch: false,
    data: {
      courses: [],
      count: 0
    }
  },
  categories: {
    isPending: false,
    isNeedRefetch: false,
    data: []
  }
}

export const coursesSlice = createSlice({
  name: 'adminCourses',
  initialState,
  reducers: {
    removeCategory: (state, { payload }: PayloadAction<{ id: string }>) => {
      state.categories.data = [...state.categories.data].filter(
        ({ id }) => id !== payload.id
      )
    },
    setIsNeedRefetchCourses: (state, { payload }: PayloadAction<boolean>) => {
      state.courses.isNeedRefetch = payload
    },
    setCourse: (state, { payload }: PayloadAction<ICourse>) => {
      state.course.isPending = false
      state.course.data = payload
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getAdminCourse.pending, (state) => {
      state.courses.isPending = true
    })
    builder.addCase(getAdminCourse.fulfilled, (state, { payload }) => {
      state.courses.data.courses = payload.data ?? []
      state.courses.data.count = payload.count ?? 0
      state.courses.isPending = false
    })
    builder.addCase(getAdminCourse.rejected, (state) => {
      state.courses.isPending = false
    })

    builder.addCase(getCourseCategory.pending, (state) => {
      state.categories.isPending = true
    })
    builder.addCase(getCourseCategory.fulfilled, (state, { payload }) => {
      state.categories.data = payload ?? []
      state.categories.isPending = false
    })
    builder.addCase(getCourseCategory.rejected, (state) => {
      state.categories.isPending = false
    })

    builder.addCase(getCourse.pending, (state) => {
      state.course.isPending = true
    })
    builder.addCase(getCourse.fulfilled, (state, { payload }) => {
      state.course.data = payload
      state.course.isPending = false
    })
    builder.addCase(getCourse.rejected, (state) => {
      state.course.isPending = false
    })

    builder.addCase(createCourseCategory.fulfilled, (state, { payload }) => {
      state.categories.data = [...state.categories.data, payload]
    })

    builder.addCase(createCourse.pending, (state) => {
      state.isPendingCourseFrom = true
    })
    builder.addCase(createCourse.fulfilled, (state) => {
      state.isPendingCourseFrom = false
    })
    builder.addCase(createCourse.rejected, (state) => {
      state.isPendingCourseFrom = false
    })

    builder.addCase(patchCourse.pending, (state) => {
      state.isPendingCourseFrom = true
    })
    builder.addCase(patchCourse.fulfilled, (state) => {
      state.isPendingCourseFrom = false
    })
    builder.addCase(patchCourse.rejected, (state) => {
      state.isPendingCourseFrom = false
    })
  }
})

export const { reducer: coursesReducer, actions: coursesActions } = coursesSlice
