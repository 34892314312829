import { FormikHelpers } from 'formik/dist/types'
import { IStudentForm, IStudentFormProps } from '../StudentForm.types'
import { useCallback } from 'react'
import { useAppDispatch } from '../../../../hooks'
import {
  ICreateStudentRequest,
  ICreateStudentResponse,
  IEditStudentRequest,
  createStudent,
  editStudent,
  editStudentClasses
} from '../../../../services'
import customToast from '../../../../components/CustomToast'

export const useStudentSubmit: (
  params: IStudentFormProps
) => (
  values: IStudentForm,
  formikHelpers: FormikHelpers<IStudentForm>
) => void | Promise<any> = ({ onSuccess, type }) => {
  const dispatch = useAppDispatch()

  const editAction = (data: IEditStudentRequest) => dispatch(editStudent(data))
  const createAction = (data: ICreateStudentRequest) =>
    dispatch(createStudent(data))

  const onSuccessCallback = useCallback(
    (response: ICreateStudentResponse | void, values: IStudentForm) => {
      const responseType = !!response ? 'created' : 'edited'
      const { id, fullName, classIds } = values

      dispatch(
        editStudentClasses({
          id: response?.id || id || '',
          classIds
        })
      )
        .unwrap()
        .then(() => {
          customToast({
            type: 'success',
            message: `Student "${fullName}" ${responseType} successful`
          })
          onSuccess && onSuccess()
        })
    },
    []
  )

  return useCallback((values) => {
    const { username, fullName, email, password, id } = values
    const data = {
      username: username || fullName.toLowerCase().replaceAll(' ', '_').trim(),
      fullName,
      email: email || undefined,
      password: password || undefined
    }

    if (type === 'edit') {
      editAction({
        ...data,
        id: id as string
      })
        .unwrap()
        .then((response) => onSuccessCallback(response, values))
      return
    }

    createAction(data)
      .unwrap()
      .then((response) => onSuccessCallback(response, values))
  }, [])
}
