import { styled as muiStyled } from '@mui/system'

export const StyledFormContainer = muiStyled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  width: '100%',
  boxSizing: 'border-box',
  marginTop: 32,
  overflow: 'hidden',
  gap: 24
}))

export const StyledFormImgContainer = muiStyled('div')<{ width?: number }>(
  ({ width }) => ({
    width: width || 288,
    flexShrink: 0
  })
)

export const StyledFormMainContainer = muiStyled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  maxWidth: 350,
  gap: '12px'
}))

export const StyledFormSupportContainer = muiStyled('div')(() => ({
  marginLeft: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: '12px'
}))

export const StyledFormSupportContainerTop = muiStyled('div')(() => ({}))

export const StyledFormSupportContainerBottom = muiStyled('div')(() => ({
  marginTop: 'auto'
}))
