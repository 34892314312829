import { styled } from '@mui/system'

export const StyledPageListItemLabel = styled('div')(() => ({
  marginRight: '12px'
}))

export const StyledPageListContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'shouldMarginBottom'
})(({ shouldMarginBottom }: { shouldMarginBottom: boolean }) => ({
  marginBottom: shouldMarginBottom ? '6px' : '0'
}))

export const StyledPageList = styled('div')(() => ({}))

export const StyledPageListItem = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: '6px 0 6px 8px'
}))
