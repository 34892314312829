import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  ICommonChunkConfig,
  ICommonPageRequest,
  ICommonResponse,
  IErrorResponse
} from '..'
import { del, get, patch, post } from '../../axios'

import { API_ROUTES } from '../../core/router/apiRoutes'
import {
  ADMIN_TEACHERS_PAGE_SIZE,
  ALL_LIST_PAGE_SIZE,
  BASE_SIZE
} from '../../utils/constants'
import {
  IBaseTeacher,
  ICreateTeacherRequest,
  ICreateTeacherResponse,
  IDeleteTeacherRequest,
  IDelteTeacherResponse,
  IEditTeacherClassesRequest,
  IEditTeacherRequest,
  IGetAdminTeacherRequest,
  IGetAdminTeacherResponse,
  IGetAdminTeachersRequest,
  IGetAdminTeachersResponse,
  IGetTeacherClassRequest,
  IGetTeacherClassResponse,
  IPatchTeacherClassRequest,
  IPostAdminMessageRequest
} from './teachers.types'

export const getTeachers = createAsyncThunk<
  ICommonResponse<IBaseTeacher>,
  ICommonPageRequest,
  ICommonChunkConfig
>('find/teachers', async ({ allList, page, ..._data }, { rejectWithValue }) => {
  const params = allList
    ? {
        page: 1,
        pageSize: ALL_LIST_PAGE_SIZE,
        ..._data
      }
    : {
        page,
        pageSize: BASE_SIZE,
        ..._data
      }

  try {
    return await get<ICommonResponse<IBaseTeacher>>({
      path: API_ROUTES.FIND_TEACHERS,
      params
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const getAdminTeachers = createAsyncThunk<
  IGetAdminTeachersResponse,
  IGetAdminTeachersRequest,
  ICommonChunkConfig
>(
  'adminTeachers/getAdminTeachers',
  async ({ allList, page, sort, ..._data }, { rejectWithValue }) => {
    const params = allList
      ? {
          page: 1,
          pageSize: ALL_LIST_PAGE_SIZE,
          sorts: sort,
          ..._data
        }
      : {
          page,
          pageSize: ADMIN_TEACHERS_PAGE_SIZE,
          sorts: sort,
          ..._data
        }
    try {
      return await get<IGetAdminTeachersResponse>({
        path: API_ROUTES.GET_ADMIN_TEACHERS,
        params
      })
    } catch (error: any) {
      return rejectWithValue(error as IErrorResponse)
    }
  }
)

export const getAdminTeacher = createAsyncThunk<
  IGetAdminTeacherResponse,
  IGetAdminTeacherRequest,
  ICommonChunkConfig
>('adminTeachers/getAdminTeacher', async (_data, { rejectWithValue }) => {
  try {
    return await get<IGetAdminTeacherResponse>({
      path: API_ROUTES.GET_ADMIN_TEACHER(_data.username),
      params: {
        username: _data.username
      }
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const getAdminTeacherClass = createAsyncThunk<
  IGetTeacherClassResponse,
  IGetTeacherClassRequest,
  ICommonChunkConfig
>('adminTeachers/getAdminTeacherClass', async (_data, { rejectWithValue }) => {
  try {
    return await get<IGetTeacherClassResponse>({
      path: API_ROUTES.ADMIN_CLASS(_data.id),
      params: {
        id: _data.id
      }
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const patchAdminTeacherClass = createAsyncThunk<
  void,
  IPatchTeacherClassRequest,
  ICommonChunkConfig
>(
  'adminTeachers/patchAdminTeacherClass',
  async (_data, { rejectWithValue }) => {
    try {
      return await patch<void>({
        path: API_ROUTES.ADMIN_CLASS(_data.id),
        data: _data.data
      })
    } catch (error: any) {
      return rejectWithValue(error as IErrorResponse)
    }
  }
)

export const sendAdminMessage = createAsyncThunk<
  void,
  IPostAdminMessageRequest,
  ICommonChunkConfig
>('adminTeachers/sendAdminMessage', async (_data, { rejectWithValue }) => {
  try {
    return await post<void>({
      path: API_ROUTES.POST_ADMIN_MESSAGE(_data.id),
      data: {
        text: _data.text
      }
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const deleteOrphanedTeachers = createAsyncThunk<
  void,
  void,
  ICommonChunkConfig
>(
  'adminTeachers/deleteOrphanedTeachers',
  async (_data, { rejectWithValue }) => {
    try {
      return await del<void>(
        {
          path: API_ROUTES.DELETE_ORPHANED,
          data: {}
        },
        {
          type: 'teachers'
        }
      )
    } catch (error: any) {
      return rejectWithValue(error as IErrorResponse)
    }
  }
)

export const deleteTeacher = createAsyncThunk<
  IDelteTeacherResponse,
  IDeleteTeacherRequest,
  ICommonChunkConfig
>('adminTeachers/deleteTeacher', async (_data, { rejectWithValue }) => {
  try {
    return await del<IDelteTeacherResponse>({
      path: API_ROUTES.DELETE_ACCOUNT(_data.id),
      data: {
        id: _data.id
      }
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const createTeacher = createAsyncThunk<
  ICreateTeacherResponse,
  ICreateTeacherRequest,
  ICommonChunkConfig
>('adminTeachers/createTeacher', async (_data, { rejectWithValue }) => {
  const { username, fullName, email, password } = _data
  try {
    return await post<ICreateTeacherResponse>({
      path: API_ROUTES.CREATE_ACCOUNT,
      data: {
        type: 'teacher',
        username,
        fullName,
        email,
        password
      }
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const editTeacher = createAsyncThunk<
  void,
  IEditTeacherRequest,
  ICommonChunkConfig
>('adminTeachers/editTeacher', async (_data, { rejectWithValue }) => {
  const { id, fullName, email, password } = _data
  try {
    return await patch<void>(
      {
        path: API_ROUTES.PATCH_ACCOUNT(id),
        data: {
          fullName,
          email,
          password,
          type: 'teacher'
        }
      },
      {
        id
      }
    )
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const editTeacherClasses = createAsyncThunk<
  void,
  IEditTeacherClassesRequest,
  ICommonChunkConfig
>('adminTeachers/editTeacherClasses', async (_data, { rejectWithValue }) => {
  const { id, ...data } = _data
  try {
    return await patch<void>(
      {
        path: API_ROUTES.PATCH_TEACHER_CLASSES(id),
        data
      },
      {
        id
      }
    )
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})
