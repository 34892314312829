import { createAsyncThunk } from '@reduxjs/toolkit'
import { get, post } from '../../axios'

import {
  ICurrentUserResponse,
  ILoginRequest,
  ILoginResponse
} from './auth.types'
import { API_ROUTES } from '../../core/router/apiRoutes'
import { ICommonChunkConfig, IErrorResponse } from '..'

export const login = createAsyncThunk<
  ILoginResponse,
  ILoginRequest,
  ICommonChunkConfig
>('auth/login', async (_data, { rejectWithValue }) => {
  try {
    return await post<ILoginResponse>({ path: API_ROUTES.LOGIN, data: _data })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const getCurrentUser = createAsyncThunk<
  ICurrentUserResponse,
  void,
  ICommonChunkConfig
>('auth/fetchCurrentUser', async (_data, { rejectWithValue }) => {
  try {
    return await get<ICurrentUserResponse>({
      path: API_ROUTES.ME,
      params: {}
    })
  } catch (error) {
    return rejectWithValue(error as IErrorResponse)
  }
})
