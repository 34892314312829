import { getIn, useFormikContext } from 'formik'
import { FieldValue } from '../_Form.types'
import { ChangeEvent, memo, useCallback } from 'react'
import { FormRow } from '../FormRow/FormRow'
import { IFormTextInputProps } from './FormTextInput.types'
import { TextInput } from '../../_UI'

const FormTextInputNonMemo = <FormValues extends FieldValue>({
  name: fieldName,
  placeholder,
  label,
  onChange,
  labelType,
  ...props
}: IFormTextInputProps<FormValues>) => {
  const formContext = useFormikContext<FormValues>()
  const isTouched = getIn(formContext.touched, fieldName)
  const isError = isTouched && !!getIn(formContext.errors, fieldName)
  const message = isError
    ? (getIn(formContext.errors, fieldName) as string)
    : ''

  const onChangeHandler: (event: ChangeEvent<HTMLInputElement>) => void =
    useCallback((event) => {
      if (onChange) {
        onChange(event)
      } else {
        formContext.setFieldValue(fieldName, event.target.value)
      }
    }, [])

  const input = (
    <TextInput
      value={getIn(formContext.values, fieldName)}
      onChange={onChangeHandler}
      name={fieldName}
      error={isError}
      placeholder={placeholder}
      helperText={message}
      {...props}
    />
  )

  if (label) {
    return (
      <FormRow label={label} type={labelType}>
        {input}
      </FormRow>
    )
  }

  return input
}

export const FormTextInput = memo(
  FormTextInputNonMemo
) as typeof FormTextInputNonMemo
