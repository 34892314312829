import { styled } from '@mui/system'

export const Box = styled('div')(({ theme }) => ({
  marginTop: '24px',
  backgroundColor: theme.palette.customColors.bg,
  border: `1px solid ${theme.palette.customColors.bg}`,
  borderRadius: '24px',
  boxSizing: 'border-box',
  padding: '24px',
  width: '100%'
}))
