import { createAsyncThunk } from '@reduxjs/toolkit'
import { del, get, patch, post } from '../../axios'

import { API_ROUTES } from '../../core/router/apiRoutes'
import {
  ADMIN_COURSES_PAGE_SIZE,
  ALL_LIST_PAGE_SIZE
} from '../../utils/constants'
import {
  ICourseLogoRequest,
  ICourseLogoResponse,
  ICoursePatchRequest,
  ICourseResponse,
  IGetAdminCourseResponse,
  IGetAdminCoursesRequest,
  IGetCourseByIdRequest,
  INewCourseRequest
} from './courses.types'
import { ICourseCategory } from '../../store/courses/courses.types'
import { ICommonChunkConfig, IErrorResponse } from '..'

export const getAdminCourse = createAsyncThunk<
  IGetAdminCourseResponse,
  IGetAdminCoursesRequest,
  ICommonChunkConfig
>(
  'adminCourses/getAdminCourses',
  async ({ allList, page, ..._data }, { rejectWithValue }) => {
    const params = allList
      ? {
          page: 1,
          pageSize: ALL_LIST_PAGE_SIZE,
          ..._data
        }
      : {
          page,
          pageSize: ADMIN_COURSES_PAGE_SIZE,
          ..._data
        }

    try {
      return await get<IGetAdminCourseResponse>({
        path: API_ROUTES.ADMIN_COURSES,
        params
      })
    } catch (error: any) {
      return rejectWithValue(error as IErrorResponse)
    }
  }
)

export const getCourse = createAsyncThunk<
  ICourseResponse,
  IGetCourseByIdRequest,
  ICommonChunkConfig
>('adminCourses/getCourse', async (_data, { rejectWithValue }) => {
  try {
    return await get<ICourseResponse>({
      path: `${API_ROUTES.COURSES}/${_data.id}`,
      params: {}
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const patchCourse = createAsyncThunk<
  ICourseResponse,
  ICoursePatchRequest,
  ICommonChunkConfig
>(
  'adminCourses/patchCourse',
  async ({ id, logoPath, ..._data }, { rejectWithValue }) => {
    const logoParams = logoPath ? { logoPath } : {}
    try {
      return await patch<ICourseResponse>({
        path: `${API_ROUTES.COURSES}/${id}`,
        data: {
          ..._data,
          ...logoParams
        }
      })
    } catch (error: any) {
      return rejectWithValue(error as IErrorResponse)
    }
  }
)

export const deleteCourse = createAsyncThunk<
  void,
  { id: string },
  ICommonChunkConfig
>('adminCourses/deleteCourse', async ({ id }, { rejectWithValue }) => {
  try {
    return await del<void>({
      path: `${API_ROUTES.COURSES}/${id}`,
      data: {}
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const createCourse = createAsyncThunk<
  ICourseResponse,
  INewCourseRequest,
  ICommonChunkConfig
>(
  'adminCourses/createCourse',
  async ({ logoPath, ..._data }, { rejectWithValue }) => {
    const logoParams = logoPath ? { logoPath } : {}
    try {
      return await post<ICourseResponse>({
        path: API_ROUTES.COURSES,
        data: {
          ...logoParams,
          ..._data
        }
      })
    } catch (error: any) {
      return rejectWithValue(error as IErrorResponse)
    }
  }
)

export const uploadCourseLogo = createAsyncThunk<
  ICourseLogoResponse,
  ICourseLogoRequest,
  ICommonChunkConfig
>('adminCourses/uploadCourseLogo', async (_data, { rejectWithValue }) => {
  try {
    return await post<ICourseLogoResponse>(
      {
        path: API_ROUTES.COURSE_LOGO,
        data: _data
      },
      {},
      {
        'Content-Type': 'multipart/form-data'
      }
    )
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const getCourseCategory = createAsyncThunk<
  ICourseCategory[],
  void,
  ICommonChunkConfig
>('adminCourses/getCategories', async (_data, { rejectWithValue }) => {
  try {
    return await get<ICourseCategory[]>({
      path: API_ROUTES.COURSE_CATEGORIES,
      params: {}
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const createCourseCategory = createAsyncThunk<
  ICourseCategory,
  Omit<ICourseCategory, 'id'>,
  ICommonChunkConfig
>('adminCourses/createCourseCategory', async (_data, { rejectWithValue }) => {
  try {
    return await post<ICourseCategory>({
      path: API_ROUTES.COURSE_CATEGORIES,
      data: {
        ..._data
      }
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const deleteCourseCategory = createAsyncThunk<
  void,
  Pick<ICourseCategory, 'id'>,
  ICommonChunkConfig
>('adminCourses/deleteCourseCategory', async ({ id }, { rejectWithValue }) => {
  try {
    return await del({
      path: `${API_ROUTES.COURSE_CATEGORIES}/${id}`,
      data: {}
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})
