import { FC, memo, useEffect, useMemo } from 'react'
import { Formik } from 'formik'
import {
  IStudentFields,
  IStudentForm,
  IStudentFormProps
} from './StudentForm.types'
import {
  FormContainer,
  FormImgContainer,
  FormMainContainer,
  FormSupportContainer,
  FormSupportContainerBottom
} from '../../../components/_Page'
import {
  FormImgLoader,
  FormMultySelect,
  FormTextInput
} from '../../../components/_Form'
import { StyledButton } from '../../../components/CustomTable/styles'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { useStudentSubmit } from './hooks/useStudentSubmit'
import { getAdminClasses, getAdminSchools } from '../../../services'

const baseInitialValues: IStudentForm = {
  avatarPath: undefined,
  fullName: '',
  email: '',
  schoolIds: [],
  classIds: []
}

const StudentFields: FC<IStudentFields> = (props) => {
  const {
    initialValues,
    handleSubmit,
    setFieldValue,
    values: { schoolIds, classIds },
    dirty
  } = props

  const isPendingStudentFrom = useAppSelector(
    (state) => state.students.isPendingStudentFrom
  )

  const {
    data: { schools },
    isPending: isSchoolsPending
  } = useAppSelector((state) => state.schools.schools)

  const {
    data: { adminClasses },
    isPending: isClassesPending
  } = useAppSelector((state) => state.adminClasses.adminClasses)

  const classes = useMemo(() => {
    if (!isClassesPending && schoolIds.length) {
      return adminClasses.filter((classItem) =>
        schoolIds.includes(classItem.school.id)
      )
    }

    return []
  }, [isClassesPending, schoolIds])

  useEffect(() => {
    if (!isClassesPending && adminClasses.length) {
      if (classes.length) {
        setFieldValue(
          'classIds',
          classIds.filter((id) =>
            classes.some((classItem) => classItem.id === id)
          )
        )
      } else {
        setFieldValue('classIds', [])
      }
    }
  }, [isClassesPending, adminClasses, classes])

  return (
    <form onSubmit={handleSubmit}>
      <FormContainer>
        <FormImgContainer width={192}>
          <FormImgLoader<IStudentForm>
            name={'avatarPath'}
            type={'student'}
            currentImgSrc={initialValues?.avatarPath}
          />
        </FormImgContainer>
        <FormMainContainer>
          <FormTextInput<IStudentForm>
            name={'fullName'}
            label={'Name'}
            placeholder={'Enter full name'}
          />

          <FormTextInput<IStudentForm>
            name={'email'}
            label={'Email'}
            type={'email'}
            placeholder={'Enter email'}
          />

          <FormTextInput<IStudentForm>
            name={'password'}
            label={'Password'}
            type={'password'}
            placeholder={'Enter password'}
          />

          <FormMultySelect<IStudentForm>
            name="schoolIds"
            items={schools.map((school) => ({
              name: school.shortName,
              value: school.id
            }))}
            label="School"
            placeholder="Enter school name"
          />

          <FormMultySelect<IStudentForm>
            name="classIds"
            items={classes.map((classItem) => ({
              name: classItem.name,
              value: classItem.id
            }))}
            label="Class"
            placeholder="Enter class name"
          />
        </FormMainContainer>
        <FormSupportContainer>
          <FormSupportContainerBottom>
            <StyledButton
              type={'submit'}
              size={'large'}
              variant={'contained'}
              color={'primary'}
              disabled={
                isPendingStudentFrom ||
                isSchoolsPending ||
                isClassesPending ||
                !dirty
              }
            >
              Confirm
            </StyledButton>
          </FormSupportContainerBottom>
        </FormSupportContainer>
      </FormContainer>
    </form>
  )
}

export const StudentForm: FC<IStudentFormProps> = memo(
  ({
    initialValues = baseInitialValues,
    onSuccess,
    validationSchema,
    type
  }) => {
    const dispatch = useAppDispatch()

    const formSubmit = useStudentSubmit({
      initialValues,
      onSuccess,
      validationSchema,
      type
    })

    useEffect(() => {
      dispatch(getAdminSchools({ allList: true }))
      dispatch(getAdminClasses({ allList: true }))
    }, [])

    return (
      <Formik<IStudentForm>
        initialValues={initialValues ?? baseInitialValues}
        onSubmit={formSubmit}
        validationSchema={validationSchema}
      >
        {(data) => <StudentFields {...data} />}
      </Formik>
    )
  }
)
