import { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'

import {
  Dropdown,
  ITooltipItemsProps,
  TooltipItems
} from '../../../components/_UI'
import { IActivity } from '../../../services'
import { IUnitViewTable } from '../UnitView.types'
import { ActivityLevel } from '../../../components/ActivityLevel/ActivityLevel'
import { CustomChip } from '../../../components/CustomChip'

export const useUnitViewTableRows = (
  units: IActivity[],
  options: {
    editActivity: (id: string) => void
    deleteActivity: (id: string) => void
    copyLink: (id: string) => void
  }
) => {
  const navigate = useNavigate()

  const { editActivity, deleteActivity, copyLink } = options

  return useMemo(
    () =>
      units.map((activity) => {
        const { id, title, status, level, isDemo } = activity

        const tooltipItems: ITooltipItemsProps['items'] = [
          {
            type: 'button',
            title: 'delete',
            handler: () => deleteActivity(id)
          },
          {
            type: 'button',
            title: 'edit',
            handler: () => editActivity(id)
          }
        ]

        if (isDemo) {
          tooltipItems.push({
            type: 'button',
            title: 'Сopy link to the scene',
            handler: () => copyLink(id)
          })
        }

        const result: IUnitViewTable & {
          rowAction: () => void
          excludeAction: (keyof IUnitViewTable)[]
        } = {
          title,
          demo: isDemo && (
            <CustomChip
              aria-label="demo chip"
              label="Demo"
              bgColor="greenLight"
              textColor="greenDark"
              borderColor="greenDark"
              height="24px"
            />
          ),
          rowAction: () => navigate(`/activity/${id}`),
          excludeAction: ['actions'],
          level: <ActivityLevel activityLevel={level} />,
          status,
          actions: (
            <Dropdown>
              <TooltipItems items={tooltipItems} />
            </Dropdown>
          )
        }

        return result
      }),
    [units]
  )
}
