import { styled } from '@mui/system'

export const ModalContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '20px'
}))

export const ModalBottomContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '20px',

  '> *:first-of-type': {
    width: 'fit-content',
    flexGrow: 2
  },

  '> *:last-of-type': {
    width: 'fit-content',
    flexGrow: 3
  }
}))
