import { styled } from '@mui/material/styles'

export const StyledInputRow = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
  alignItems: 'center',
  marginTop: '12px'
}))

export const StyledErrorMessage = styled('div')(({ theme }) => ({
  margin: '3px 14px 0',
  fontSize: '12px',
  color: `${theme.palette.customColors.error}`
}))

export const StyledCategoryContainer = styled('div')(() => ({
  margin: '12px 14px',
  maxHeight: '150px',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap'
}))

export const StyledCategoryItem = styled('div')(() => ({
  display: 'flex',
  gap: '12px',
  maxWidth: '100%',
  overflow: 'hidden',
  alignItems: 'center',

  '&>*:first-of-type': {
    minWidth: 0,
    overflow: 'hidden',
    '&>*:first-of-type': {
      flexShrink: 0
    },
    '& span': {
      overflow: 'hidden',
      textOverflow: 'ellipsis'
    }
  }
}))

export const StyledDeleteIcon = styled('span')(() => ({
  marginLeft: 'auto',
  display: 'inline-block',
  background: `url(/assets/delete.svg)`,
  width: '24px',
  height: '24px',
  cursor: 'pointer',
  flexShrink: '0',
  marginRight: '12px'
}))
