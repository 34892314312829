import * as Yup from 'yup'

export const questionFormSchema = Yup.object().shape({
  questions: Yup.array().of(
    Yup.object().shape({
      text: Yup.string()
        .min(3, 'The length of the question must be more than 3 characters.')
        .max(
          120,
          'The length of the question must be less than 120 characters.'
        )
        .required('Question text is required'),
      breakpoint: Yup.number().min(1).required('Breakpoint time is required'),
      hintText: Yup.string()
        .min(3, 'The length of the hint must be more than 3 characters')
        .max(600, 'The length of the hint must be less than 600 characters')
        .nullable(),
      answers: Yup.array().of(
        Yup.object().shape({
          text: Yup.string()
            .min(3, 'The answer must be more than 3 characters long.')
            .max(
              120,
              'The length of the answer must be less than 120 characters.'
            )
            .required('Answer text is required')
        })
      ),
      wrongAnswers: Yup.array().of(
        Yup.object().shape({
          text: Yup.string()
            .min(3, 'The answer must be more than 3 characters long.')
            .max(
              120,
              'The length of the answer must be less than 120 characters.'
            )
            .required('Answer text is required')
        })
      )
    })
  )
})
