import { FC, PropsWithChildren } from 'react'
import { Props } from './types'

import {
  StyledStudentInfoItem,
  StyledStudentInfoItemContent,
  StyledStudentInfoItemLabel
} from './styles'

export const StudentInfoItem: FC<PropsWithChildren<Props>> = ({
  label,
  children
}) => {
  return (
    <StyledStudentInfoItem>
      <StyledStudentInfoItemLabel>{label}</StyledStudentInfoItemLabel>
      <StyledStudentInfoItemContent>{children}</StyledStudentInfoItemContent>
    </StyledStudentInfoItem>
  )
}
