import { createAsyncThunk } from '@reduxjs/toolkit'
import { get, put } from '../../axios'

import { API_ROUTES } from '../../core/router/apiRoutes'
import {
  ITicketsRequest,
  ITicketsResponse,
  ITicketUpdateRequest
} from './tickets.types'
import { TICKET_PAGE_SIZE } from '../../utils/constants'
import { ICommonChunkConfig, IErrorResponse } from '../service.types'

export const getTickets = createAsyncThunk<
  ITicketsResponse,
  ITicketsRequest,
  ICommonChunkConfig
>('tickets/getTickets', async (_data, { rejectWithValue }) => {
  try {
    return await get<ITicketsResponse>({
      path: API_ROUTES.GET_TICKETS,
      params: {
        pageSize: TICKET_PAGE_SIZE,
        ..._data
      }
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const updateTicket = createAsyncThunk<
  void,
  ITicketUpdateRequest,
  ICommonChunkConfig
>('tickets/updateTicket', async (_data, { rejectWithValue }) => {
  try {
    return await put({
      path: `${API_ROUTES.GET_TICKETS}/${_data.id}`,
      data: {
        status: _data.status
      }
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})
