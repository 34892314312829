export const useAdminStudentsTableHeader = () => {
  return [
    {
      name: 'Name',
      ariaLabel: 'Name',
      cellName: 'fullName',
      sortable: true
    },
    {
      name: 'Schools',
      ariaLabel: 'Schools',
      cellName: 'schools',
      sortable: false
    },
    {
      name: 'Classes',
      ariaLabel: 'Classes',
      cellName: 'classes',
      sortable: false
    },
    {
      name: 'Activities done',
      ariaLabel: 'Activities done',
      cellName: 'activitiesDone',
      sortable: false
    },
    {
      name: 'Average Score',
      ariaLabel: 'Average Score',
      cellName: 'averageScore',
      sortable: false
    },
    {
      name: 'Last activity',
      ariaLabel: 'Last activity',
      cellName: 'lastActivity',
      sortable: false
    },
    {
      name: '',
      ariaLabel: '',
      cellName: 'actions',
      sortable: false
    }
  ]
}
