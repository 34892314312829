import { useFormikContext } from 'formik'
import { FieldValue } from '../_Form.types'
import { memo, ReactNode, useCallback } from 'react'
import { FormRow } from '../FormRow/FormRow'
import { IFormSelectInputProps } from './FormSelectInput.types'
import { Select } from '../../_UI'
import { SelectChangeEvent } from '@mui/material'

const FormSelectInputNonMemo = <FormValues extends FieldValue>({
  name: fieldName,
  placeholder,
  label,
  options
}: IFormSelectInputProps<FormValues>) => {
  const formContext = useFormikContext<FormValues>()
  const isTouched = formContext.touched[fieldName]
  const isError = isTouched && !!formContext.errors[fieldName]

  const onChangeHandler: (
    event: SelectChangeEvent<unknown>,
    child: ReactNode
  ) => void = useCallback((e) => {
    formContext.setFieldValue(fieldName, e.target.value as string)
  }, [])

  return (
    <FormRow label={label}>
      <Select
        value={formContext.values[fieldName]}
        onChange={onChangeHandler}
        options={options}
        name={fieldName}
        error={isError}
        placeholder={placeholder}
      />
    </FormRow>
  )
}

export const FormSelectInput = memo(
  FormSelectInputNonMemo
) as typeof FormSelectInputNonMemo
