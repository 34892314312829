import { Typography } from '@mui/material'
import { styled } from '@mui/system'

export const StyledTitle = styled(Typography)(() => ({
  fontSize: '24px',
  fontWeight: 700,
  lineHeight: '32px',
  marginBottom: '4px'
}))

export const StyledTitleContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '12px',
  marginBottom: '12px'
}))

export const StyledTitleActionContainers = styled('div')(() => ({
  display: 'flex',
  marginLeft: 'auto',
  flexDirection: 'row',
  gap: '12px'
}))

export const StyledTitleBackContainers = styled('div')(() => ({
  alignSelf: 'center'
}))
