import { styled as muiStyled } from '@mui/system'

export const StyledStudentsPage = muiStyled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  boxSizing: 'border-box',
  marginTop: '24px'
}))

export const StyledFileInput = muiStyled('input')(() => ({
  display: 'none'
}))
