import { FC, useCallback, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Page, PageTitleBox } from '../../components/_Page'
import { useAppDispatch, useAppSelector } from '../../hooks'
import {
  deleteActivity,
  deleteUnit,
  getActivities,
  getActivity,
  getUnit,
  IActivityStatus,
  IGetActivityResponse,
  IUnitStatus
} from '../../services'
import { StyledButton } from '../../components/CustomTable/styles'
import { ISortCallbackParams } from '../../types'
import { ACTIVITIES_PAGE_SIZE } from '../../utils/constants'
import CustomTable from '../../components/CustomTable'
import { activityActions } from '../../store/activities/activities.reducer'
import { useUnitViewTableHeader } from './hooks/useUnitViewTableHeader'
import { useUnitViewTableRows } from './hooks/useUnitViewTableRows'
import { ActivityForm, IActivityForm, IUnitForm, UnitForm } from '../_Common'
import CustomModal from '../../components/CustomModal'
import { Modal } from '../../components/_UI'
import {
  StyledUnitViewActions,
  StyledUnitViewActionsTop
} from './UnitView.style'
import { APP_ROUTES } from '../../core/router/appRoutes'
import Config from '../../config'

export const UnitView: FC = () => {
  const queryParams = useParams()

  const unitId = queryParams.id!

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const unit = useAppSelector((state) => state.units.unit)
  const {
    isNeedRefetch: isNeedRefetchActivities,
    data: { activities, count: activitiesCount }
  } = useAppSelector((state) => state.activity.activities)

  const [isOpenFormModal, setIsOpeFormModal] = useState(false)
  const [isOpenEditFormModal, setIsOpenEditFormModal] = useState(false)
  const [deleteId, setDeleteId] = useState<string | undefined>(undefined)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [isOpenDeleteUnitModal, setIsOpeDeleteUnitModal] = useState(false)
  const [formInitialValues, setFormInitialValues] = useState<
    IActivityForm | undefined
  >()
  const [editFormInitialValues, setEditFormInitialValues] = useState<
    IUnitForm | undefined
  >(undefined)
  const [getActivitiesParams, setGetActivitiesParams] =
    useState<ISortCallbackParams>({
      page: 1,
      sort: 'name:asc'
    })

  const isPending = unit.isPending
  const unitName = unit.data?.name ?? ''
  const logoUrl = unit.data?.logoUrl
  const courseId = unit.data?.course.id ?? ''

  const sortCallbackHandler = useCallback((params: ISortCallbackParams) => {
    setGetActivitiesParams(params)
  }, [])

  const setDeleteActivityHandler = useCallback((courseId: string) => {
    setIsOpenDeleteModal(true)
    setDeleteId(courseId)
  }, [])

  const closeDeleteModal = useCallback(() => {
    setIsOpenDeleteModal(false)
  }, [])

  const closeDeleteUnitModal = useCallback(() => {
    setIsOpeDeleteUnitModal(false)
  }, [])

  const toggleDeleteUnitHandler = useCallback(() => {
    setIsOpeDeleteUnitModal(!isOpenDeleteUnitModal)
  }, [isOpenDeleteUnitModal])

  const deleteActivityHandler = useCallback(() => {
    if (!deleteId) return

    setIsOpenDeleteModal(false)
    dispatch(deleteActivity({ id: deleteId })).then((params) => {
      if (params.meta.requestStatus === 'fulfilled') {
        dispatch(activityActions.setIsNeedRefetchActivities(true))
      }
    })
  }, [deleteId])

  const deleteUnitHandler = useCallback(() => {
    if (!unitId) return

    setIsOpenDeleteModal(false)
    dispatch(deleteUnit({ id: unitId })).then((params) => {
      if (params.meta.requestStatus === 'fulfilled') {
        navigate(`${APP_ROUTES.COURSES}/${courseId}`)
      }
    })
  }, [unitId, courseId])

  const editActivity = useCallback((activityId: string) => {
    dispatch(getActivity({ id: activityId })).then((params) => {
      if (params.meta.requestStatus === 'fulfilled') {
        const {
          status,
          grammarTags = [],
          syntaxTags = [],
          vocabTags = [],
          ...payload
        } = params.payload as IGetActivityResponse

        setFormInitialValues({
          draft: status === IActivityStatus.draft,
          grammarTags,
          syntaxTags,
          vocabTags,
          ...payload
        })
        setIsOpeFormModal(true)
      }
    })
  }, [])

  const copyLink = useCallback((id: string) => {
    const url = Config.vrUrl + `/vr/${id}`
    navigator.clipboard.writeText(url)
  }, [])

  const tableHeader = useUnitViewTableHeader()
  const tableRows = useUnitViewTableRows(activities, {
    editActivity,
    deleteActivity: setDeleteActivityHandler,
    copyLink
  })

  const toggleFormHandler = useCallback(() => {
    setFormInitialValues(undefined)
    setIsOpeFormModal(!isOpenFormModal)
  }, [isOpenFormModal])

  const toggleEditFormHandler = useCallback(() => {
    setIsOpenEditFormModal(!isOpenEditFormModal)
  }, [isOpenEditFormModal])

  const formSubmitSuccessHandler = useCallback(() => {
    setIsOpeFormModal(false)
    dispatch(activityActions.setIsNeedRefetchActivities(true))
  }, [])

  const editFormSubmitSuccessHandler = useCallback(() => {
    setIsOpenEditFormModal(false)
    dispatch(getUnit({ id: unitId }))
  }, [])

  useEffect(() => {
    if (isNeedRefetchActivities) {
      dispatch(getActivities({ unitId, ...getActivitiesParams }))
      dispatch(activityActions.setIsNeedRefetchActivities(false))
    }
  }, [isNeedRefetchActivities, unitId, getActivitiesParams])

  useEffect(() => {
    dispatch(getActivities({ unitId, ...getActivitiesParams }))
  }, [unitId, getActivitiesParams])

  useEffect(() => {
    if (!unit.data) return

    const { logoUrl, logoPath, status, ...currentUnit } = unit.data

    setEditFormInitialValues({
      logoUrl,
      logoPath,
      draft: status === IUnitStatus.draft,
      ...currentUnit
    })
  }, [unit.data])

  useEffect(() => {
    if (unitId !== unit.data?.id) {
      dispatch(getUnit({ id: unitId }))
    }
  }, [unitId, unit.data])

  if (isPending) return <Page />

  return (
    <Page>
      <PageTitleBox
        logoSrc={logoUrl}
        title={unitName}
        actions={
          <StyledUnitViewActions>
            <StyledUnitViewActionsTop>
              <StyledButton
                type={'button'}
                size={'large'}
                variant={'outlined'}
                color={'primary'}
                onClick={toggleEditFormHandler}
              >
                Edit
              </StyledButton>
              <StyledButton
                type={'button'}
                size={'large'}
                variant={'contained'}
                color={'primary'}
                onClick={toggleFormHandler}
              >
                Add Activity
              </StyledButton>
            </StyledUnitViewActionsTop>

            <StyledButton
              type={'button'}
              size={'large'}
              variant={'outlined'}
              color={'error'}
              onClick={toggleDeleteUnitHandler}
            >
              Delete Unit
            </StyledButton>
          </StyledUnitViewActions>
        }
      ></PageTitleBox>

      <CustomTable
        tableAriaLabel={'Units table'}
        dataCount={activitiesCount}
        tableHead={tableHeader}
        rowsCount={ACTIVITIES_PAGE_SIZE}
        rows={tableRows}
        defaultSortColumn={'name'}
        sortCallback={sortCallbackHandler}
        tableSubtitle={''}
      />

      <CustomModal
        open={isOpenEditFormModal}
        title={'Edit a Unit'}
        handleClose={toggleEditFormHandler}
        width={'404px'}
      >
        <UnitForm
          courseId={courseId}
          initialValues={editFormInitialValues}
          onSuccess={editFormSubmitSuccessHandler}
          onClose={toggleEditFormHandler}
        />
      </CustomModal>

      <CustomModal
        open={isOpenFormModal}
        title={formInitialValues ? 'Edit an Activity' : 'Add an Activity'}
        handleClose={toggleFormHandler}
        width={'404px'}
      >
        <ActivityForm
          unitId={unitId}
          initialValues={formInitialValues}
          onSuccess={formSubmitSuccessHandler}
          onClose={toggleFormHandler}
        />
      </CustomModal>

      <Modal
        isOpen={isOpenDeleteModal}
        title={'Delete activity'}
        onClose={closeDeleteModal}
        onSuccess={deleteActivityHandler}
        confirmText={'Delete'}
      >
        You are about to delete activity, this action is permanent. Do you want
        to proceed?
      </Modal>

      <Modal
        isOpen={isOpenDeleteUnitModal}
        title={'Delete unit'}
        onClose={closeDeleteUnitModal}
        onSuccess={deleteUnitHandler}
        confirmText={'Delete'}
      >
        You are about to delete unit, this action is permanent. Do you want to
        proceed?
      </Modal>
    </Page>
  )
}
