import { useMemo } from 'react'
import { useAppSelector } from '../../../hooks'

export const useAdminSchoolTableFilter = () => {
  const {
    data: { countries }
  } = useAppSelector((state) => state.schools.countries)

  return useMemo(() => {
    const countryFilter = countries.map((country) => ({
      value: country,
      name: country
    }))

    return { country: countryFilter }
  }, [countries])
}
