import { useMemo } from 'react'
import { IUnitViewTable } from '../UnitView.types'

export const useUnitViewTableHeader: () => {
  name: string
  ariaLabel: string
  cellName: keyof IUnitViewTable
  sortable: boolean
}[] = () => {
  return useMemo(
    () => [
      {
        name: 'Activity',
        ariaLabel: 'Activity title',
        cellName: 'title',
        sortable: true
      },
      {
        name: 'Demo',
        ariaLabel: 'Activity demo key',
        cellName: 'demo',
        sortable: false
      },
      {
        name: 'Level',
        ariaLabel: 'Activity Level',
        cellName: 'level',
        sortable: true
      },
      {
        name: 'Status',
        ariaLabel: 'Status',
        cellName: 'status',
        sortable: false
      },
      {
        name: 'Actions',
        ariaLabel: 'Actions',
        cellName: 'actions',
        sortable: false
      }
    ],
    []
  )
}
