import { styled } from '@mui/system'
import { Form } from 'formik'
import { Link } from 'react-router-dom'

export const StyledLoginFormWrapper = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'space-around',
  alignItems: 'center',
  width: '76%',
  height: '100%',
  margin: '0 auto'
}))

export const StyledForm = styled(Form)(() => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  maxWidth: '295px'
}))

export const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.customColors.gray,
  textDecoration: 'none',
  marginTop: '10px'
}))
