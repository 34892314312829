import ReactDOM from 'react-dom/client'
import { store } from './store'
import { Provider } from 'react-redux'
import Router from './core/router'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from '@mui/system'
import { theme } from './utils/theme'
import './style.css'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
      <ToastContainer />
    </ThemeProvider>
  </Provider>
)
