import { ChangeEvent, FC, useState } from 'react'
import { Modal, TextInput } from '../../../../components/_UI'
import { useAppDispatch } from '../../../../hooks'
import { sendAdminMessage } from '../../../../services'
import { Props } from './types'

export const SendMessageToStudentModal: FC<Props> = ({
  studentId,
  closeMessageModal
}) => {
  const dispatch = useAppDispatch()

  const [message, setMessage] = useState('')

  const onMessageChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setMessage(e.target.value)
  }

  const handleSendMessage = () => {
    dispatch(sendAdminMessage({ id: studentId || '', text: message }))
    closeMessageModal()
  }

  return (
    <Modal
      isOpen={!!studentId}
      title={'Send message to student'}
      onClose={closeMessageModal}
      onSuccess={handleSendMessage}
      confirmText={'Send a message'}
      confirmDisabled={!message?.length}
    >
      <TextInput multiline value={message} onChange={onMessageChange} />
    </Modal>
  )
}
