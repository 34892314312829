import { FC } from 'react'

import { Modal } from '../../../../components/_UI'
import { useAppDispatch, useAppSelector } from '../../../../hooks'

import { Props } from './types'
import { deleteOrphanedStudents } from '../../../../services'

export const DeleteOrphanedStudentsModal: FC<Props> = ({
  isOpen,
  closeModal
}) => {
  const {
    data: { students }
  } = useAppSelector((state) => state.students.students)

  const dispatch = useAppDispatch()

  const studentsWithoutClassesCount = students.filter(
    (teacher) => teacher.classes.length === 0
  ).length

  const handleDelete = () => {
    dispatch(deleteOrphanedStudents())
    closeModal()
  }

  return (
    <Modal
      isOpen={isOpen}
      title={'Delete students without school'}
      onClose={closeModal}
      onSuccess={handleDelete}
      confirmText={'Delete'}
      confirmDisabled={!studentsWithoutClassesCount}
    >
      {!!studentsWithoutClassesCount
        ? `You are about to delete ${studentsWithoutClassesCount} users this action is permanent. Do you want to proceed?`
        : 'There are no students without school'}
    </Modal>
  )
}
