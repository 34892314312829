import { FormikHelpers } from 'formik/dist/types'
import { ICourseForm, ICourseFormProps } from '../CourseForm.types'
import {
  createCourse,
  ICourseLogoResponse,
  patchCourse,
  uploadCourseLogo
} from '../../../../services'
import customToast from '../../../../components/CustomToast'
import { useAppDispatch } from '../../../../hooks'
import { useCallback } from 'react'

type FormCbValues = Omit<ICourseForm, 'logoUrl'> & {
  logoPath: string | undefined
}
export const useCourseSubmit: (
  params: Omit<ICourseFormProps, 'onClose'>
) => (
  values: ICourseForm,
  formikHelpers: FormikHelpers<ICourseForm>
) => void | Promise<any> = ({ initialValues, onSuccess }) => {
  const dispatch = useAppDispatch()

  const pathCourseCb = useCallback(
    (values: FormCbValues) => {
      if (!initialValues?.id) return

      dispatch(
        patchCourse({
          id: initialValues.id,
          ...values
        })
      ).then((params) => {
        if (params.meta.requestStatus === 'fulfilled') {
          customToast({
            type: 'success',
            message: `Course "${values.name}" was update successful`
          })
          onSuccess && onSuccess()
        }
      })
    },
    [initialValues]
  )

  const createCourseCb = useCallback((values: FormCbValues) => {
    dispatch(
      createCourse({
        name: values.name,
        categoryId: values.categoryId,
        logoPath: values.logoPath,
        language: values.language
      })
    ).then((params) => {
      if (params.meta.requestStatus === 'fulfilled') {
        customToast({
          type: 'success',
          message: `Course "${values.name}" create successful`
        })
        onSuccess && onSuccess()
      }
    })
  }, [])

  return useCallback(
    (values) => {
      if (values.logoPath instanceof File) {
        dispatch(
          uploadCourseLogo({
            file: values.logoPath
          })
        ).then((params) => {
          if (params.meta.requestStatus === 'fulfilled' && params.payload) {
            const payload = params.payload as ICourseLogoResponse
            const logoPath = payload.logoPath

            const reqParams = {
              name: values.name,
              categoryId: values.categoryId,
              logoPath: logoPath,
              language: values.language
            }

            if (initialValues?.id) {
              pathCourseCb(reqParams)
            } else {
              createCourseCb(reqParams)
            }
          }
        })

        return
      }

      const reqParams = {
        name: values.name,
        categoryId: values.categoryId,
        logoPath: values.logoPath,
        language: values.language
      }

      if (initialValues?.id) {
        pathCourseCb(reqParams)
      } else {
        createCourseCb(reqParams)
      }
    },
    [initialValues, onSuccess]
  )
}
