import { FC, useCallback, useMemo } from 'react'
import { Box, PageTitle } from '../../components/_Page'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '../../core/router/appRoutes'
import { StyledBackButton } from '../../components/CustomTable/styles'
import { StudentForm } from '../_Common'
import { createStudentFormSchema } from '../_Common/StudentForm/schemas/StudentForm.schema'

export const NewStudent: FC = () => {
  const navigate = useNavigate()

  const handleBackButton = useCallback(() => {
    navigate(`${APP_ROUTES.STUDENTS}`)
  }, [])

  const onSuccessHandler = useCallback(() => {
    navigate(`${APP_ROUTES.STUDENTS}`)
  }, [])

  const validationSchema = useMemo(() => createStudentFormSchema(), [])

  return (
    <Box>
      <PageTitle
        back={
          <StyledBackButton
            aria-label={'Previous page button'}
            onClick={handleBackButton}
          />
        }
      >
        Add student
      </PageTitle>
      <StudentForm
        type="create"
        validationSchema={validationSchema}
        onSuccess={onSuccessHandler}
      />
    </Box>
  )
}
