import { styled } from '@mui/system'

export const StyledUnitViewActions = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  alignItems: 'flex-end'
}))

export const StyledUnitViewActionsTop = styled('div')(() => ({
  display: 'flex',
  gap: '12px'
}))
