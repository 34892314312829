import { useAppSelector } from '../../hooks'
import { ADMIN_STUDENTS_PAGE_SIZE } from '../../utils/constants'
import CustomTable from '../../components/CustomTable'
import { PageTitle } from '../../components/_Page'
import { StyledButton } from '../../components/CustomTable/styles'
import { memo, useCallback, useMemo, useState } from 'react'
import {
  Dropdown,
  ITooltipItemsProps,
  TooltipItems
} from '../../components/_UI'
import { useAdminStudentsTableHeader } from './hooks/useAdminStudentsTableHeader'
import { useAdminStudentsTableRows } from './hooks/useAdminStudentsTableRows'
import { useAdminStudentsTableFilter } from './hooks/useAdminStudentsTableFilter'
import { usePreparationStudents } from './hooks/usePreparationStudents'
import { StyledStudentsPage } from './Students.style'
import { CustomSvgIcon } from '../../components/CustomSvgIcon'
import { DeleteOrphanedStudentsModal } from './components/deleteOrphanedStudentsModal'
import { SendMessageToStudentModal } from './components/sendMessageToStudentModal'
import { APP_ROUTES } from '../../core/router/appRoutes'
import { ImportUsersModal } from '../../components/ImportUsersModal'
import { UserType } from '../../types'

export const Students = memo(() => {
  const {
    data: { students, count: studentsCount }
  } = useAppSelector((state) => state.students.students)

  const [isOpenDeleteModal, setIsOpeDeleteModal] = useState(false)
  const [isOpenImportModal, setIsOpenImportModal] = useState<boolean>(false)
  const [messageStudentId, setMessageStudentId] = useState<string | undefined>()

  const toggleImportModal = useCallback(() => {
    setIsOpenImportModal((prev) => !prev)
  }, [setIsOpenImportModal])

  const closeDeleteModal = useCallback(() => {
    setIsOpeDeleteModal(false)
  }, [])

  const closeMessageModal = useCallback(() => {
    setMessageStudentId(undefined)
  }, [])

  const tableHeader = useAdminStudentsTableHeader()
  const tableRows = useAdminStudentsTableRows(students, {
    setMessageStudentId
  })
  const tableFilters = useAdminStudentsTableFilter()

  const sortCallbackHandler = usePreparationStudents()

  const burgerItems: ITooltipItemsProps['items'] = useMemo(
    () => [
      {
        type: 'button',
        title: 'Import',
        handler: () => {
          toggleImportModal()
        }
      },
      {
        type: 'link',
        title: 'Add student',
        to: APP_ROUTES.ADD_STUDENT
      },
      {
        type: 'button',
        title: 'Delete students without school',
        handler: () => {
          setIsOpeDeleteModal(true)
        }
      }
    ],
    [toggleImportModal, setIsOpeDeleteModal]
  )

  return (
    <StyledStudentsPage>
      <PageTitle
        actions={
          <Dropdown
            title={
              <StyledButton
                type={'button'}
                variant={'outlined'}
                color={'primary'}
              >
                <CustomSvgIcon path="/assets/burger-icon.svg" />
              </StyledButton>
            }
          >
            <TooltipItems items={burgerItems} />
          </Dropdown>
        }
      >
        Students
      </PageTitle>
      <CustomTable
        tableFiltersData={tableFilters}
        tableAriaLabel={'Students table'}
        dataCount={studentsCount}
        tableHead={tableHeader}
        rowsCount={ADMIN_STUDENTS_PAGE_SIZE}
        rows={tableRows}
        defaultSortColumn={''}
        sortCallback={sortCallbackHandler}
        tableSubtitle={`You have ${studentsCount} students`}
        searchPlaceholder={'Look for a students'}
      />
      <ImportUsersModal
        isOpen={isOpenImportModal}
        toggle={toggleImportModal}
        title="Import students"
        type={UserType.student}
      />
      <DeleteOrphanedStudentsModal
        isOpen={isOpenDeleteModal}
        closeModal={closeDeleteModal}
      />
      {!!messageStudentId && (
        <SendMessageToStudentModal
          studentId={messageStudentId}
          closeMessageModal={closeMessageModal}
        />
      )}
    </StyledStudentsPage>
  )
})
