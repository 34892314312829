import { styled } from '@mui/system'

export const StyledBreakpointBlock = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column'
}))

export const StyledBreakpointItemContainer = styled('div')(() => ({
  marginTop: '12px',
  display: 'flex'
}))

export const StyledBreakpointError = styled('div')(({ theme }) => ({
  color: theme.palette.customColors.error,
  fontSize: '12px',
  marginLeft: '13px'
}))
