import { useMemo } from 'react'
import { useAppSelector } from '../../../hooks'

export const useActivitiesPageList = () => {
  const activities = useAppSelector(
    (state) => state.students.studentActivities.data
  )

  return useMemo(
    () =>
      activities?.activities.map((el) => ({ name: el.title, id: el.id })) || [],
    [activities]
  )
}
