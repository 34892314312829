import { styled } from '@mui/system'
import { Select } from '@mui/material'
import { IStyledSelectProps } from '../types'

const options = {
  shouldForwardProp: (propName: string) => {
    switch (propName) {
      case 'marginRight':
        return false
      default:
        return true
    }
  }
}

export const StyledSelectWrapper = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  marginRight: '12px',
  '&:last-of-type': {
    marginRight: '0px'
  }
}))

export const StyledSelectLabel = styled('label')(({ theme }) => ({
  marginBottom: '8px',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontStyle: 'normal',
  fontWeight: 600,
  lineHeight: '20px',
  color: theme.palette.customColors.grayMedium
}))

export const StyledSelect = styled(
  Select,
  options
)<IStyledSelectProps>(({ theme, width, marginRight }) => ({
  borderRadius: '16px',
  width: width,
  height: '32px',
  backgroundColor: theme.palette.customColors.bg,
  marginRight: marginRight
}))
