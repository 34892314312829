import { useMemo } from 'react'
import { useAppSelector } from '../../../hooks'

export const useAdminTeachersTableFilter = () => {
  const {
    data: { schools }
  } = useAppSelector((state) => state.teachers.schools)
  const {
    data: { courses }
  } = useAppSelector((state) => state.teachers.courses)
  const {
    data: { classes }
  } = useAppSelector((state) => state.teachers.classes)

  return useMemo(() => {
    const schoolFilter = schools.map((school) => ({
      value: school.id,
      name: school.shortName
    }))

    const courseFilter = courses.map((course) => ({
      value: course.id,
      name: course.name
    }))

    const classFilter = classes.map((classItem) => ({
      value: classItem.id,
      name: classItem.name
    }))

    return { school: schoolFilter, сourse: courseFilter, class: classFilter }
  }, [schools, courses, classes])
}
