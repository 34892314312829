import { TextInput } from '../../../../../components/_UI'
import { useAppDispatch, useAppSelector } from '../../../../../hooks'
import {
  ChangeEvent,
  KeyboardEvent,
  useCallback,
  useMemo,
  useState
} from 'react'
import { FormControlLabel, Radio, RadioGroup } from '@mui/material'
import {
  StyledCategoryContainer,
  StyledCategoryItem,
  StyledDeleteIcon,
  StyledErrorMessage,
  StyledInputRow
} from './FormCategoryField.styles'
import { FormRow } from '../../../../../components/_Form'
import { useFormikContext } from 'formik'
import { ICourseForm } from '../../CourseForm.types'
import { StyledButton } from '../../../../../components/CustomTable/styles'
import {
  createCourseCategory,
  deleteCourseCategory
} from '../../../../../services'
import customToast from '../../../../../components/CustomToast'
import { ICourseCategory } from '../../../../../store/courses/courses.types'
import { coursesActions } from '../../../../../store/courses/courses.reducer'

export const FormCategoryField = () => {
  const fieldName = 'categoryId'

  const { data: categories } = useAppSelector(
    (state) => state.courses.categories
  )
  const dispatch = useAppDispatch()

  const formContext = useFormikContext<ICourseForm>()
  const { setFieldValue, values } = formContext

  const [filterString, setFilterString] = useState('')
  const [isPendingForAddCategory, setIsPendingForAddCategory] = useState(false)

  const isTouched = formContext.touched[fieldName]

  const isError = isTouched && !!formContext.errors[fieldName]

  const message = isError
    ? (formContext.errors as Record<string, string>)[fieldName]
    : ''

  const categoriesItems = useMemo(() => {
    const catItems: { name: string; value: string }[] = []

    categories.forEach(({ name, id }) => {
      if (
        !filterString ||
        name.toLowerCase().includes(filterString.toLowerCase())
      ) {
        catItems.push({
          name,
          value: id
        })
      }
    })

    return catItems
  }, [filterString, categories])

  const addCategory = useCallback((categoryName: string) => {
    setIsPendingForAddCategory(true)
    dispatch(createCourseCategory({ name: categoryName })).then((params) => {
      setIsPendingForAddCategory(false)
      if (params.meta.requestStatus === 'fulfilled') {
        customToast({
          message: `Category ${categoryName} create successful`,
          type: 'success'
        })
      }
    })
  }, [])

  const removeCategory = useCallback(({ id, name }: ICourseCategory) => {
    dispatch(deleteCourseCategory({ id })).then((params) => {
      if (params.meta.requestStatus === 'fulfilled') {
        customToast({
          message: `Category "${name}" was delete`,
          type: 'success'
        })
        dispatch(coursesActions.removeCategory({ id }))
      }
    })
  }, [])

  const onChangeHandler = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => setFilterString(e.target.value),
    []
  )

  const onKeyDownHandler = useCallback(
    (e: KeyboardEvent) => {
      if (!filterString) return

      if (e.key === 'Enter') {
        e.preventDefault()
        if (categoriesItems[0]) {
          setFieldValue(fieldName, categoriesItems[0].value)
        } else {
          addCategory(filterString)
        }
      }
    },
    [filterString, categoriesItems]
  )

  const addCategoryHandler = useCallback(
    () => addCategory(filterString),
    [filterString]
  )

  return (
    <div>
      <FormRow label={'Category'} />
      <StyledInputRow>
        <TextInput
          value={filterString}
          onChange={onChangeHandler}
          onKeyDown={onKeyDownHandler}
          autoComplete={'off'}
          placeholder={'Enter category name for search or add'}
          disabled={isPendingForAddCategory}
          error={!!message}
        />
        <StyledButton
          type={'button'}
          size={'medium'}
          variant={'contained'}
          color={'primary'}
          disabled={!filterString || isPendingForAddCategory}
          onClick={addCategoryHandler}
        >
          Add
        </StyledButton>
      </StyledInputRow>

      {!!message && <StyledErrorMessage>{message}</StyledErrorMessage>}

      <StyledCategoryContainer>
        {categoriesItems.length ? (
          <RadioGroup
            onChange={(e) => {
              setFieldValue(fieldName, e.target.value)
            }}
          >
            {categoriesItems.map(({ name, value }) => (
              <StyledCategoryItem key={value}>
                <FormControlLabel
                  value={value}
                  control={<Radio />}
                  label={name}
                  checked={values[fieldName] === value}
                />

                <StyledDeleteIcon
                  onClick={() => removeCategory({ id: value, name })}
                />
              </StyledCategoryItem>
            ))}
          </RadioGroup>
        ) : (
          <div>
            <div>Categories not found.</div>

            {filterString
              ? `Press "Enter" for create new category: ${filterString}`
              : ''}
          </div>
        )}
      </StyledCategoryContainer>
    </div>
  )
}
