import { createAsyncThunk } from '@reduxjs/toolkit'
import {
  ICreateUnitRequest,
  IGetUnitRequest,
  IGetUnitResponse,
  IGetUnitsRequest,
  IGetUnitsResponse,
  IUnitLogoRequest,
  IUnitLogoResponse
} from './units.types'
import { ICommonChunkConfig, IErrorResponse, IUnitCRUD } from '..'
import { del, get, patch, post } from '../../axios'
import { API_ROUTES } from '../../core/router/apiRoutes'
import { UNITS_PAGE_SIZE } from '../../utils/constants'

export const getUnits = createAsyncThunk<
  IGetUnitsResponse,
  IGetUnitsRequest,
  ICommonChunkConfig
>('units/getUnits', async ({ courseId, ...data }, { rejectWithValue }) => {
  try {
    return await get<IGetUnitsResponse>({
      path: API_ROUTES.GET_UNITS(courseId),
      params: {
        pageSize: UNITS_PAGE_SIZE,
        ...data
      }
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const getUnit = createAsyncThunk<
  IGetUnitResponse,
  IGetUnitRequest,
  ICommonChunkConfig
>('units/getUnit', async ({ id }, { rejectWithValue }) => {
  try {
    return await get<IGetUnitResponse>({
      path: `${API_ROUTES.UNITS}/${id}`,
      params: {}
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const patchUnit = createAsyncThunk<
  IUnitCRUD,
  IUnitCRUD,
  ICommonChunkConfig
>('units/patchUnit', async ({ id, logoPath, ...data }, { rejectWithValue }) => {
  const logoRequest = logoPath ? { logoPath } : {}
  try {
    return await patch<IUnitCRUD>({
      path: `${API_ROUTES.UNITS}/${id}`,
      data: {
        ...data,
        ...logoRequest
      }
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const createUnit = createAsyncThunk<
  IUnitCRUD,
  ICreateUnitRequest,
  ICommonChunkConfig
>('units/createUnit', async ({ logoPath, ...data }, { rejectWithValue }) => {
  const logoRequest = logoPath ? { logoPath } : {}
  try {
    return await post<IUnitCRUD>({
      path: API_ROUTES.UNITS,
      data: {
        ...data,
        ...logoRequest
      }
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const uploadUnitLogo = createAsyncThunk<
  IUnitLogoResponse,
  IUnitLogoRequest,
  ICommonChunkConfig
>('units/uploadUnitLogo', async (data, { rejectWithValue }) => {
  try {
    return await post<IUnitLogoResponse>(
      {
        path: API_ROUTES.UNITS_LOGO,
        data: data
      },
      {},
      {
        'Content-Type': 'multipart/form-data'
      }
    )
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const deleteUnit = createAsyncThunk<
  void,
  { id: string },
  ICommonChunkConfig
>('units/deleteUnit', async ({ id }, { rejectWithValue }) => {
  try {
    return await del<void>({
      path: `${API_ROUTES.UNITS}/${id}`,
      data: {}
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})
