export const API_ROUTES = {
  LOGIN: '/auth/login/admin',
  ME: '/profile/info',
  GET_TICKETS: '/tickets',
  ADMIN_CLASSES: '/classes',
  ADMIN_CLASS: (id: string) => `/classes/${id}`,
  COURSES: '/courses',
  COURSE_LOGO: '/courses/logo',
  ADMIN_COURSES: '/find/courses',
  GET_UNITS: (courseId: string) => `/courses/${courseId}/units`,
  UNITS: '/units',
  GET_ACTIVITIES: (unitId: string) => `/units/${unitId}/activities`,
  PUT_QUESTIONS: (activityId: string) => `/activities/${activityId}/questions`,
  ACTIVITIES: '/activities',
  UNITS_LOGO: '/units/logo',
  COURSE_CATEGORIES: '/courses/categories',
  FIND_SCHOOLS: '/find/schools',
  FIND_TEACHERS: '/find/teachers',
  FIND_STUDENTS: '/find/students',
  SCHOOLS: '/schools',
  SCHOOL_LOGO: '/schools/logo',
  GET_ADMIN_SCHOOLS: '/admins/schools',
  GET_ADMIN_SCHOOLS_COUNTRIES: '/admins/schools/countries',
  GET_ADMIN_TEACHERS: '/admins/teachers',
  GET_ADMIN_TEACHER: (username: string) => `/profile/extended/${username}`,
  POST_ADMIN_MESSAGE: (accountId: string) =>
    `/notifications/account/${accountId}`,
  POST_TEACHERS_MESSAGE: (classId: string) =>
    `/notifications/class/${classId}/to-teachers`,
  POST_CLASS_MESSAGE: (classId: string) => `/notifications/class/${classId}`,
  DELETE_ORPHANED: '/profile/orphaned',
  IMPORT_USERS: '/import/users',
  GET_STUDENTS: '/student/result',
  GET_ADMIN_STUDENTS: '/admins/students',
  CREATE_ACCOUNT: '/accounts',
  DELETE_ACCOUNT: (id: string) => `/accounts/${id}`,
  PATCH_ACCOUNT: (id: string) => `/accounts/${id}`,
  PATCH_PROFILE: (username: string) => `/profile/${username}`,
  PATCH_TEACHER_CLASSES: (id: string) => `/teachers/${id}/classes`,
  GET_ADMIN_STUDENT: (username: string) => `/profile/extended/${username}`,
  GET_ADMIN_STUDENT_ACTIVITIES: (username: string) =>
    `/admins/students/${username}`,
  DELETE_ADMIN_STUDENT_COURSE: (id: string) => `/api/assign/course/${id}`,
  PATCH_STUDENT_CLASSES: (id: string) => `/students/${id}/classes`
}

export const IMAGE_MAX_SIZE = 3 * 1024 * 1024
