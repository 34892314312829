import { FC, useCallback, useEffect, useRef, useState } from 'react'
import { Box, Page, PageList, PageTitle } from '../../components/_Page'
import { useNavigate, useParams } from 'react-router-dom'
import { getAdminStudent, getAdminStudentActivities } from '../../services'
import { useAppDispatch, useAppSelector } from '../../hooks'
import {
  StyledStudentActions,
  StyledStudentListContainer,
  StyledStudentContent,
  StyledStudentImg,
  StyledStudentImgContainer,
  StyledStudentInfo,
  StyledStudentInfoSubtitle,
  StyledStudentInfoTitle,
  StyledStudentInfoTitleContainer,
  StyledStudentRow,
  StyledLoading
} from './StudentView.styles'
import { APP_ROUTES } from '../../core/router/appRoutes'
import {
  StyledBackButton,
  StyledButton
} from '../../components/CustomTable/styles'
import { DeleteStudentModal } from './components/deleteStudentModal'
import { StudentInfoItem } from './components/studentInfoItem'
import { useActivitiesPageList } from './hooks/useActivitiesPageList'
import { DeleteStudentCourseModal } from './components/deleteStudentCourseModal'
import CustomLoader from '../../components/CustomLoader'

const baseImg = '/assets/teacher-student-image-for-form.png'

export const StudentView: FC = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const queryParams = useParams()

  const {
    isPending: isPendingStudent,
    isNeedRefetch: isNeedStudentRefresh,
    data: student
  } = useAppSelector((state) => state.students.student)

  const {
    isPending: isPendingStudentActivities,
    isNeedRefetch: isNeedStudentActivitiesRefresh,
    data: studentActivities
  } = useAppSelector((state) => state.students.studentActivities)

  const studentUsername = queryParams.username!

  const imgRef = useRef<HTMLImageElement>(null)
  const [isError, setIsError] = useState(false)

  const [isOpenDeleteStudentModal, setIsOpeDeleteModal] = useState(false)
  const [deleteCourseId, setDeleteCourseId] = useState<string | undefined>()

  const activitiesPageList = useActivitiesPageList()

  useEffect(() => {
    dispatch(getAdminStudent({ username: studentUsername }))
  }, [studentUsername, isNeedStudentRefresh])

  useEffect(() => {
    dispatch(getAdminStudentActivities({ username: studentUsername }))
  }, [studentUsername, isNeedStudentActivitiesRefresh])

  const handleBackButton = useCallback(() => {
    navigate(`${APP_ROUTES.STUDENTS}`)
  }, [])

  const onErrorHandler = useCallback(() => {
    if (isError || !imgRef.current) return

    setIsError(true)
    imgRef.current.src = baseImg
  }, [isError])

  const onStudentEditHandler = useCallback(
    () => navigate(`${APP_ROUTES.EDIT_STUDENT}/${studentUsername}`),
    [studentUsername]
  )

  const closeDeleteStudentModal = useCallback(() => {
    setIsOpeDeleteModal(false)
  }, [])

  const openStudentDeletModal = useCallback(() => {
    setIsOpeDeleteModal(true)
  }, [])

  const closeDeleteCourseModal = useCallback(() => {
    setDeleteCourseId(undefined)
  }, [])

  if (
    isPendingStudent ||
    !student ||
    isPendingStudentActivities ||
    !studentActivities
  ) {
    return (
      <StyledLoading>
        <CustomLoader size={90} />
      </StyledLoading>
    )
  }

  return (
    <Page>
      <Box>
        <PageTitle
          back={
            <StyledBackButton
              aria-label={'Previous page button'}
              onClick={handleBackButton}
            />
          }
        >
          {student.fullName}
        </PageTitle>

        <StyledStudentContent>
          <StyledStudentRow>
            <StyledStudentImgContainer>
              <StyledStudentImg
                src={student.avatarUrl || baseImg}
                onError={onErrorHandler}
                ref={imgRef}
              />
            </StyledStudentImgContainer>
            <StyledStudentInfo>
              <StyledStudentInfoTitleContainer>
                <StyledStudentInfoTitle>
                  {student.fullName}
                </StyledStudentInfoTitle>
                <StyledStudentInfoSubtitle>
                  {student.email}
                </StyledStudentInfoSubtitle>
              </StyledStudentInfoTitleContainer>
              <StudentInfoItem label="Schools">
                {student.schools.map((school) => school.shortName).join(', ')}
              </StudentInfoItem>
              <StudentInfoItem label="Classes">
                {student.classes.map((el) => el.name).join(', ')}
              </StudentInfoItem>
              <StudentInfoItem label="Average score">
                {studentActivities.averageScore}
              </StudentInfoItem>
            </StyledStudentInfo>
            <StyledStudentActions>
              <StyledButton
                type={'button'}
                size={'large'}
                variant={'outlined'}
                color={'primary'}
                onClick={onStudentEditHandler}
              >
                Edit information
              </StyledButton>
            </StyledStudentActions>
          </StyledStudentRow>
          <StyledStudentRow>
            <StyledStudentListContainer>
              <StudentInfoItem label={'Courses'}>
                <PageList
                  items={studentActivities.courses}
                  onDelete={setDeleteCourseId}
                />
              </StudentInfoItem>
            </StyledStudentListContainer>
          </StyledStudentRow>
          <StyledStudentRow>
            <StyledStudentListContainer>
              <StudentInfoItem label={'Activities'}>
                <PageList items={activitiesPageList} />
              </StudentInfoItem>
            </StyledStudentListContainer>
          </StyledStudentRow>
          <StyledStudentRow>
            <StyledStudentActions>
              <StyledButton
                type={'button'}
                size={'large'}
                variant={'outlined'}
                color={'error'}
                onClick={openStudentDeletModal}
              >
                Delete student
              </StyledButton>
            </StyledStudentActions>
          </StyledStudentRow>
        </StyledStudentContent>
        <DeleteStudentModal
          isOpen={isOpenDeleteStudentModal}
          closeModal={closeDeleteStudentModal}
        />
        <DeleteStudentCourseModal
          courseId={deleteCourseId}
          closeModal={closeDeleteCourseModal}
        />
      </Box>
    </Page>
  )
}
