import { FormikHelpers } from 'formik/dist/types'
import { ITeacherForm, ITeacherFormProps } from '../TeacherForm.types'
import { useCallback } from 'react'
import { useAppDispatch } from '../../../../hooks'
import {
  ICreateTeacherRequest,
  ICreateTeacherResponse,
  IEditTeacherRequest,
  createTeacher,
  editTeacher,
  editTeacherClasses
} from '../../../../services'
import customToast from '../../../../components/CustomToast'

export const useTeacherSubmit: (
  params: ITeacherFormProps
) => (
  values: ITeacherForm,
  formikHelpers: FormikHelpers<ITeacherForm>
) => void | Promise<any> = ({ onSuccess, type }) => {
  const dispatch = useAppDispatch()

  const editAction = (data: IEditTeacherRequest) => dispatch(editTeacher(data))
  const createAction = (data: ICreateTeacherRequest) =>
    dispatch(createTeacher(data))

  const onSuccessCallback = useCallback(
    (response: ICreateTeacherResponse | void, values: ITeacherForm) => {
      const responseType = !!response ? 'created' : 'edited'
      const { id, fullName, classIds } = values

      dispatch(
        editTeacherClasses({
          id: response?.id || id || '',
          classIds
        })
      )
        .unwrap()
        .then(() => {
          customToast({
            type: 'success',
            message: `Teacher "${fullName}" ${responseType} successful`
          })
          onSuccess && onSuccess()
        })
    },
    []
  )

  return useCallback((values) => {
    const { username, fullName, email, password, id } = values
    const data = {
      username: username || fullName.toLowerCase().replaceAll(' ', '_').trim(),
      fullName,
      email: email || undefined,
      password: password || undefined
    }

    if (type === 'edit') {
      editAction({
        ...data,
        id: id as string
      })
        .unwrap()
        .then((response) => onSuccessCallback(response, values))
      return
    }

    createAction(data)
      .unwrap()
      .then((response) => onSuccessCallback(response, values))
  }, [])
}
