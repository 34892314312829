import { memo } from 'react'
import {
  StyledMenu,
  StyledMenuLink,
  StyledNotificationsLink,
  StyledUser,
  StyledUserName
} from './styles'
import { Avatar } from '@mui/material'
import { CustomSvgIcon } from '../CustomSvgIcon'
import { useAppSelector } from '../../hooks'
import { getCurrentUserSelector } from '../../core/selectors'

const UserMenu = memo(() => {
  const currentUser = useAppSelector(getCurrentUserSelector)

  return (
    <StyledMenu aria-label={'User menu'}>
      <StyledUser>
        <StyledMenuLink aria-label={'Link to my profile'} to={'/profile'}>
          <Avatar
            aria-label={'User avatar'}
            alt={currentUser?.fullName}
            src={currentUser?.avatarUrl}
          />
          <StyledUserName aria-label={'Tooltip actions button'}>
            {currentUser?.fullName}
          </StyledUserName>
        </StyledMenuLink>
      </StyledUser>
      <StyledNotificationsLink
        aria-label={'Link to my notifications'}
        to={'/notifications'}
      >
        <CustomSvgIcon
          path={'/assets/bell-icon.svg'}
          title={'Notification icon'}
        />
      </StyledNotificationsLink>
    </StyledMenu>
  )
})

export default UserMenu
