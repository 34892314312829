import { useAppDispatch, useAppSelector } from '../../../hooks'
import { useEffect, useState } from 'react'
import { ISortCallbackParams } from '../../../types'
import {
  getAdminClasses,
  getAdminCourse,
  getAdminSchools,
  getAdminStudents,
  getTeachers
} from '../../../services'

export const usePreparationStudents = () => {
  const dispatch = useAppDispatch()

  const { isNeedRefetch } = useAppSelector((state) => state.students.students)

  const [getStudentsParams, setGetStudentsParams] =
    useState<ISortCallbackParams>({
      page: 1
    })

  useEffect(() => {
    dispatch(getAdminSchools({ allList: true }))
    dispatch(getAdminCourse({ allList: true }))
    dispatch(getAdminClasses({ allList: true }))
    dispatch(getTeachers({ allList: true }))
  }, [])

  useEffect(() => {
    dispatch(getAdminStudents(getStudentsParams))
  }, [isNeedRefetch, getStudentsParams])

  return (params: ISortCallbackParams) => {
    setGetStudentsParams(params)
  }
}
