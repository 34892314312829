import { memo, StrictMode, useEffect } from 'react'
import Sidebar from './Sidebar'
import { StyledContentWrapper, StyledMainLayout } from './styles/style'
// import { getCurrentUser } from '../../../containers/Login/services'
import { getCurrentUserSelector } from '../../selectors'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { ACCESS_TOKEN_NAME } from '../../../utils/constants'
import Cookies from 'js-cookie'
import UserMenu from '../../../components/UserMenu'

interface IBaseLayoutProps {
  children: JSX.Element
}

const MainLayout = memo<IBaseLayoutProps>(({ children }) => {
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(getCurrentUserSelector)
  const accessToken = Cookies.get(ACCESS_TOKEN_NAME)

  useEffect(() => {
    if (!currentUser && accessToken) {
      // dispatch(getCurrentUser())
    }
  }, [currentUser, accessToken, dispatch])

  return (
    <StrictMode>
      <StyledMainLayout>
        <Sidebar />
        <StyledContentWrapper>
          <UserMenu />
          {children}
        </StyledContentWrapper>
      </StyledMainLayout>
    </StrictMode>
  )
})

export default MainLayout
