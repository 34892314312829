import { ICourseViewTable } from '../CourseView.types'
import { useMemo } from 'react'

export const useCourseViewTableHeader: () => {
  name: string
  ariaLabel: string
  cellName: keyof ICourseViewTable
  sortable: boolean
}[] = () => {
  return useMemo(
    () => [
      {
        name: 'Unit name',
        ariaLabel: 'Unit name',
        cellName: 'name',
        sortable: true
      },
      {
        name: 'Activities Counts',
        ariaLabel: 'Activities Counts',
        cellName: 'activityCounts',
        sortable: false
      },
      {
        name: 'Unit status',
        ariaLabel: 'Unit Status',
        cellName: 'status',
        sortable: false
      },
      {
        name: 'Actions',
        ariaLabel: 'Actions',
        cellName: 'actions',
        sortable: false
      }
    ],
    []
  )
}
