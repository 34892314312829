import { ITicket } from '../../../store/tickets/tickets.types'
import { useMemo } from 'react'
import { RowCustomStatusSelect } from '../components/RowCustomStatusSelect'

export const useTicketTableRows = (tickets: ITicket[]) => {
  return useMemo(
    () =>
      tickets.map(
        ({
          id,
          ticketNumber,
          status,
          message,
          category: { name: categoryName },
          school: { shortName: schoolShortName },
          teacher: { fullName: teacherFullName },
          createdAt
        }) => ({
          ticketNumber,
          school: schoolShortName,
          teacher: teacherFullName,
          category: categoryName,
          sentOn: createdAt,
          commentary: message,
          status: <RowCustomStatusSelect initStatus={status} ticketId={id} />
        })
      ),
    [tickets]
  )
}
