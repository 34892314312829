import { styled } from '@mui/system'
import { Select } from '@mui/material'
import { ISelectProps } from './Select.types'

export const StyledSelect = styled(Select)<Omit<ISelectProps, 'options'>>(
  ({ theme, width }) => ({
    borderRadius: '18px',
    width: width,
    height: '36px',
    backgroundColor: theme.palette.customColors.white
  })
)
