import { FC, memo, useEffect, useState } from 'react'
import {
  StyledLabel,
  StyledRadio,
  StyledRadioGroupContainer
} from './RadioGroup.style'
import { IRadioGroupProps } from './RadioGroup.types'

export const RadioGroup: FC<IRadioGroupProps> = memo(
  ({ options, value: controlledValue, onChange, ...props }) => {
    const [currentValue, setCurrentValue] = useState(controlledValue)

    useEffect(() => {
      setCurrentValue(controlledValue)
    }, [controlledValue])

    return (
      <StyledRadioGroupContainer {...props}>
        {options.map((item) => {
          const { name, value } = item
          return (
            <StyledLabel
              key={value}
              onChange={(event) => {
                setCurrentValue(value)
                onChange && onChange(event, value)
              }}
            >
              <StyledRadio value={value} checked={currentValue === value} />{' '}
              {name}
            </StyledLabel>
          )
        })}
      </StyledRadioGroupContainer>
    )
  }
)
