import { useAppDispatch, useAppSelector } from '../../../hooks'
import { useCallback, useEffect, useState } from 'react'
import { ISortCallbackParams } from '../../../types'
import { getAdminTeachers } from '../../../services/teachers/teachers.service'
import { teachersActions } from '../../../store/teachers/teachers.reducer'
import {
  getAdminClasses,
  getAdminCourse,
  getAdminSchools
} from '../../../services'

export const usePreparationTeachers = () => {
  const dispatch = useAppDispatch()

  const { isNeedRefetch: isNeedRefetchTeachers } = useAppSelector(
    (state) => state.teachers.teachers
  )

  const [getTeachersParams, setGetTeachersParams] =
    useState<ISortCallbackParams>({
      page: 1
    })

  useEffect(() => {
    dispatch(getAdminSchools({ allList: true }))
    dispatch(getAdminCourse({ allList: true }))
    dispatch(getAdminClasses({ allList: true }))
  }, [])

  useEffect(() => {
    dispatch(getAdminTeachers(getTeachersParams))
  }, [getTeachersParams])

  useEffect(() => {
    if (isNeedRefetchTeachers) {
      dispatch(getAdminTeachers(getTeachersParams))
      dispatch(teachersActions.setIsNeedRefetchTeachers(false))
    }
  }, [isNeedRefetchTeachers, getTeachersParams])

  return useCallback((params: ISortCallbackParams) => {
    setGetTeachersParams(params)
  }, [])
}
