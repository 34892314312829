import { useCallback, useEffect, useMemo } from 'react'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import {
  IBaseSchool,
  IBaseStudent,
  IBaseTeacher,
  createAdminClass,
  getAdminClass,
  getSchools,
  getStudents,
  getTeachers,
  patchAdminClass
} from '../../../../services'
import { IAdminClassForm } from '../AdminClassForm.types'
import customToast from '../../../../components/CustomToast'
import { useNavigate, useParams } from 'react-router-dom'
import { APP_ROUTES } from '../../../../core/router/appRoutes'

const initialValues: IAdminClassForm = {
  name: '',
  schoolId: '',
  teacherIds: [],
  studentIds: []
}

export const useAdminClassForm = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const queryParams = useParams()

  const {
    isPendingAdminClass,
    adminClass,
    schools,
    teachers,
    students,
    isPendingAdminClassesFrom
  } = useAppSelector((state) => {
    return {
      isPendingAdminClass: state.adminClasses.adminClass.isPending,
      adminClass: state.adminClasses.adminClass.data,
      schools: state.adminClasses.schools.data.schools,
      teachers: state.adminClasses.teachers.data.teachers,
      students: state.adminClasses.students.data.students,
      isPendingAdminClassesFrom: state.adminClasses.isPendingAdminClassesFrom
    }
  })

  const isNew = useMemo(() => {
    return !queryParams.id
  }, [])

  useEffect(() => {
    if (queryParams.id) {
      dispatch(getAdminClass({ id: queryParams.id }))
    }

    dispatch(getSchools({ allList: true }))
    dispatch(getTeachers({ allList: true }))
    dispatch(getStudents({ allList: true }))
  }, [])

  const schoolItems = useMemo(() => {
    return schools.map((school: IBaseSchool) => ({
      name: school.shortName,
      value: school.id
    }))
  }, [schools])

  const studentItems = useMemo(() => {
    return students.map((student: IBaseStudent) => ({
      name: student.fullName,
      value: student.id
    }))
  }, [students])

  const teacherItems = useMemo(() => {
    return teachers.map((teacher: IBaseTeacher) => ({
      name: teacher.fullName,
      value: teacher.id
    }))
  }, [teachers])

  const formSubmit = useCallback(
    (payload: IAdminClassForm) => {
      if (queryParams.id) {
        dispatch(patchAdminClass({ id: queryParams.id, ...payload })).then(
          (params) => {
            if (params.meta.requestStatus === 'fulfilled') {
              customToast({
                type: 'success',
                message: `Class "${payload.name}" update successful`
              })

              navigate(`${APP_ROUTES.ADMIN_CLASSES}`)
            }
          }
        )
      } else {
        dispatch(createAdminClass(payload)).then((params) => {
          if (params.meta.requestStatus === 'fulfilled') {
            customToast({
              type: 'success',
              message: `Class "${payload.name}" create successful`
            })

            navigate(`${APP_ROUTES.ADMIN_CLASSES}`)
          }
        })
      }
    },
    [queryParams]
  )

  const formValues = useMemo(() => {
    if (isNew) {
      return initialValues
    } else if (adminClass) {
      return {
        name: adminClass.name,
        schoolId: adminClass.school.id,
        teacherIds: adminClass.teachers.map((teacher) => teacher.value),
        studentIds: adminClass.students.map((student) => student.value)
      }
    }
  }, [isNew, adminClass])

  return {
    isNew,
    isPendingAdminClass,
    formValues,
    isPendingAdminClassesFrom,
    schoolItems,
    studentItems,
    teacherItems,
    formSubmit
  }
}
