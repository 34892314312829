import { FC, useCallback } from 'react'
import { Box, PageTitle } from '../../components/_Page'
import { AdminClassForm } from '../_Common'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '../../core/router/appRoutes'
import { StyledBackButton } from '../../components/CustomTable/styles'

export const EditAdminClass: FC = () => {
  const navigate = useNavigate()

  const onBackHandler = useCallback(() => {
    navigate(APP_ROUTES.ADMIN_CLASSES)
  }, [])

  return (
    <Box>
      <PageTitle
        back={
          <StyledBackButton
            aria-label={'Previous page button'}
            onClick={onBackHandler}
          />
        }
      >
        Edit class
      </PageTitle>
      <AdminClassForm />
    </Box>
  )
}
