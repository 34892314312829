export const APP_ROUTES = {
  MAIN: '/',
  LOGIN: '/login',
  TICKETS: '/tickets',
  ADMIN_CLASSES: '/classes',
  NEW_ADMIN_CLASS: '/classes/new',
  EDIT_ADMIN_CLASS: '/classes/edit',
  COURSES: '/courses',
  UNITS: '/units',
  SCHOOLS: '/schools',
  NEW_SCHOOL: '/schools/new',
  EDIT_SCHOOL: '/schools/edit',
  ACTIVITY: '/activity',
  TEACHERS: '/teachers',
  NEW_TEACHER: '/teachers/new',
  EDIT_TEACHER: '/teachers/edit',
  STUDENTS: '/students',
  EDIT_STUDENT: '/students/edit',
  ADD_STUDENT: '/students/new'
}
