import { getIn, useFormikContext } from 'formik'
import { FieldValue } from '../_Form.types'
import { memo } from 'react'
import { FormRow } from '../FormRow/FormRow'
import { IFormDatePickerProps } from './FormDatePicker.types'
import { DatePicker } from '../../_UI'
import dayjs from 'dayjs'

const FormDatePickerNonMemo = <FormValues extends FieldValue>({
  name: fieldName,
  label,
  labelType,
  onChange,
  ...props
}: IFormDatePickerProps<FormValues>) => {
  const formContext = useFormikContext<FormValues>()
  const isTouched = formContext.touched[fieldName]
  const isError = !!isTouched && !!formContext.errors[fieldName]

  const message = isError
    ? (getIn(formContext.errors, fieldName) as string)
    : ''

  const value = getIn(formContext.values, fieldName)

  const input = (
    <DatePicker
      value={value ? dayjs(value) : value}
      onChange={(dayjs) => {
        if (onChange) {
          onChange(dayjs)
        } else {
          formContext.setFieldValue(fieldName, dayjs?.format('YYYY-MM-DD'))
        }
      }}
      slotProps={{
        textField: {
          helperText: message,
          error: isError
        }
      }}
      {...props}
    />
  )

  if (label) {
    return (
      <FormRow label={label} type={labelType}>
        {input}
      </FormRow>
    )
  }

  return input
}

export const FormDatePicker = memo(
  FormDatePickerNonMemo
) as typeof FormDatePickerNonMemo
