import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { ITicketState, TicketStatusType } from './tickets.types'
import { getTickets } from '../../services/tickets/tickets.service'
import { ITicketsResponse } from '../../services/tickets/tickets.types'

const initialState: ITicketState = {
  isPending: false,
  data: {
    tickets: [],
    count: 0
  }
}

export const ticketSlice = createSlice({
  name: 'tickets',
  initialState,
  reducers: {
    updateTicket: (
      state,
      {
        payload: { ticketId, status }
      }: PayloadAction<{ ticketId: string; status: TicketStatusType }>
    ) => {
      const tickets = [...state.data.tickets]
      const ticketIndex = tickets.findIndex(({ id }) => id === ticketId)
      if (~ticketIndex) {
        tickets[ticketIndex].status = status
        state.data.tickets = tickets
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(getTickets.pending, (state) => {
      state.isPending = true
    })
    builder.addCase(
      getTickets.fulfilled,
      (state, { payload }: PayloadAction<ITicketsResponse>) => {
        state.data.tickets = payload.data ?? []
        state.data.count = payload.count ?? 0
        state.isPending = false
      }
    )
    builder.addCase(getTickets.rejected, (state) => {
      state.isPending = false
    })
  }
})

export const { reducer: ticketReducer, actions: ticketActions } = ticketSlice
