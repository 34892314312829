import { styled as muiStyled } from '@mui/system'

export const StyledActions = muiStyled('div')(() => ({
  display: 'flex',
  height: '100%',
  gap: '12px'
}))

export const StyledForm = muiStyled('form')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px'
}))
