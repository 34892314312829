import * as Yup from 'yup'
import { IActivityForm } from '../ActivityForm.types'
import { IActivityLevel } from '../../../../services'

export const activityFormSchema: Yup.ObjectSchema<IActivityForm> =
  Yup.object().shape({
    id: Yup.string(),
    title: Yup.string()
      .min(3, 'The length of the name must be more than 3 characters.')
      .max(100, 'The length of the name must be less than 100 characters.')
      .required('Name is required'),
    description: Yup.string()
      .min(3, 'The length of the description must be more than 3 characters.')
      .max(
        200,
        'The length of the description must be less than 200 characters.'
      )
      .required('Description is required'),
    level: Yup.string()
      .oneOf(Object.values(IActivityLevel))
      .required('Level is required'),
    draft: Yup.boolean().required(),
    sceneUrl: Yup.string()
      .url('URL should by valid')
      .required('URL is required'),
    grammarTags: Yup.array()
      .of(Yup.string().required())
      .min(1, 'Grammar is required')
      .required(),
    syntaxTags: Yup.array()
      .of(Yup.string().required())
      .min(1, 'Syntax is required')
      .required(),
    vocabTags: Yup.array()
      .of(Yup.string().required())
      .min(1, 'Vocab is required')
      .required(),
    isDemo: Yup.boolean().required()
  })
