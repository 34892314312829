import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IAdminStudentsState } from './students.types'
import {
  getAdminClasses,
  getAdminCourse,
  getAdminSchools,
  getTeachers,
  IGetAdminSchoolsResponse,
  deleteOrphanedStudents,
  getAdminStudent,
  getAdminStudents,
  IGetAdminStudentsResponse,
  getAdminStudentActivities,
  deleteAdminStudentCourse,
  createStudent,
  editStudentClasses,
  editStudent,
  importUsers
} from '../../services'

const initialState: IAdminStudentsState = {
  isPendingStudentFrom: false,
  student: {
    isPending: false,
    isNeedRefetch: false,
    data: undefined
  },
  studentActivities: {
    isPending: false,
    isNeedRefetch: false,
    data: undefined
  },
  students: {
    isPending: false,
    isNeedRefetch: true,
    data: {
      count: 0,
      students: []
    }
  },
  schools: {
    isPending: false,
    isNeedRefetch: false,
    data: {
      schools: [],
      count: 0
    }
  },
  courses: {
    isPending: false,
    isNeedRefetch: false,
    data: {
      courses: [],
      count: 0
    }
  },
  classes: {
    isPending: false,
    isNeedRefetch: false,
    data: {
      classes: [],
      count: 0
    }
  },
  teachers: {
    isPending: false,
    isNeedRefetch: false,
    data: {
      teachers: [],
      count: 0
    }
  }
}

export const studentsSlice = createSlice({
  name: 'students',
  initialState,
  reducers: {
    setIsNeedRefetchStudents: (state, { payload }: PayloadAction<boolean>) => {
      state.students.isNeedRefetch = payload
    }
  },
  extraReducers: (builder) => {
    //get students
    builder.addCase(getAdminStudents.pending, (state) => {
      state.students.isPending = true
      state.students.isNeedRefetch = false
    })
    builder.addCase(
      getAdminStudents.fulfilled,
      (state, { payload }: PayloadAction<IGetAdminStudentsResponse>) => {
        state.students.isPending = false
        state.students.isNeedRefetch = false
        state.students.data = { count: payload.count, students: payload.data }
      }
    )
    builder.addCase(getAdminStudents.rejected, (state) => {
      state.students.isPending = false
      state.students.isNeedRefetch = false
    })
    //import students
    builder.addCase(importUsers.fulfilled, (state) => {
      state.students.isNeedRefetch = true
    })
    //delete orphaned students
    builder.addCase(deleteOrphanedStudents.fulfilled, (state) => {
      state.students.isNeedRefetch = true
    })
    //get schools
    builder.addCase(getAdminSchools.pending, (state) => {
      state.schools.isPending = true
    })
    builder.addCase(
      getAdminSchools.fulfilled,
      (state, { payload }: PayloadAction<IGetAdminSchoolsResponse>) => {
        state.schools.data.schools = payload.data ?? []
        state.schools.data.count = payload.count ?? 0
        state.schools.isPending = false
      }
    )
    builder.addCase(getAdminSchools.rejected, (state) => {
      state.schools.isPending = false
    })
    //get courses
    builder.addCase(getAdminCourse.pending, (state) => {
      state.courses.isPending = true
    })
    builder.addCase(getAdminCourse.fulfilled, (state, { payload }) => {
      state.courses.data.courses = payload.data ?? []
      state.courses.data.count = payload.count ?? 0
      state.courses.isPending = false
    })
    builder.addCase(getAdminCourse.rejected, (state) => {
      state.courses.isPending = false
    })
    //get classes
    builder.addCase(getAdminClasses.pending, (state) => {
      state.classes.isPending = true
    })
    builder.addCase(getAdminClasses.fulfilled, (state, { payload }) => {
      state.classes.data.classes = payload.data ?? []
      state.classes.data.count = payload.count ?? 0
      state.classes.isPending = false
    })
    builder.addCase(getAdminClasses.rejected, (state) => {
      state.classes.isPending = false
    })
    //get teachers
    builder.addCase(getTeachers.pending, (state) => {
      state.teachers.isPending = true
    })
    builder.addCase(getTeachers.fulfilled, (state, { payload }) => {
      state.teachers.data.teachers = payload.data ?? []
      state.teachers.data.count = payload.count ?? 0
      state.teachers.isPending = false
    })
    builder.addCase(getTeachers.rejected, (state) => {
      state.teachers.isPending = false
    })
    //get student
    builder.addCase(getAdminStudent.pending, (state) => {
      state.student.isPending = true
      state.student.isNeedRefetch = false
    })
    builder.addCase(getAdminStudent.fulfilled, (state, { payload }) => {
      state.student.data = payload
      state.student.isPending = false
      state.student.isNeedRefetch = false
    })
    builder.addCase(getAdminStudent.rejected, (state) => {
      state.student.isPending = false
      state.student.isNeedRefetch = false
    })
    //get student activities
    builder.addCase(getAdminStudentActivities.pending, (state) => {
      state.studentActivities.isPending = true
      state.studentActivities.isNeedRefetch = false
    })
    builder.addCase(
      getAdminStudentActivities.fulfilled,
      (state, { payload }) => {
        state.studentActivities.data = payload
        state.studentActivities.isPending = false
        state.studentActivities.isNeedRefetch = false
      }
    )
    builder.addCase(getAdminStudentActivities.rejected, (state) => {
      state.studentActivities.isPending = false
      state.studentActivities.isNeedRefetch = false
    })
    //delete student course
    builder.addCase(deleteAdminStudentCourse.fulfilled, (state) => {
      state.studentActivities.isNeedRefetch = true
    })
    //create student
    builder.addCase(createStudent.pending, (state) => {
      state.isPendingStudentFrom = true
    })
    builder.addCase(createStudent.fulfilled, (state) => {
      state.isPendingStudentFrom = false
    })
    builder.addCase(createStudent.rejected, (state) => {
      state.isPendingStudentFrom = false
    })
    //edit student
    builder.addCase(editStudent.pending, (state) => {
      state.isPendingStudentFrom = true
    })
    builder.addCase(editStudent.fulfilled, (state) => {
      state.isPendingStudentFrom = false
    })
    builder.addCase(editStudent.rejected, (state) => {
      state.isPendingStudentFrom = false
    })
    //edit student classes
    builder.addCase(editStudentClasses.pending, (state) => {
      state.isPendingStudentFrom = true
    })
    builder.addCase(editStudentClasses.fulfilled, (state) => {
      state.isPendingStudentFrom = false
    })
    builder.addCase(editStudentClasses.rejected, (state) => {
      state.isPendingStudentFrom = false
    })
  }
})

export const { reducer: studentsReducer, actions: studentsActions } =
  studentsSlice
