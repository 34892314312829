import { FC } from 'react'

import { useAppDispatch } from '../../hooks'

import CustomModal from '../CustomModal'
import { Formik } from 'formik'
import { getDefaultFormValues } from '../../utils/helpers'
import { Button } from '@mui/material'
import { StyledActions, StyledForm } from './style'
import { FormSelectInput } from '../_Form'
import { FormFileLoader } from '../_Form/FormFileLoader/FormFileLoader'
import { importUsers } from '../../services'
import { separatorOptions } from './constants'
import { IForm, Props } from './types'
import { schema } from './schema'

export const ImportUsersModal: FC<Props> = ({
  isOpen,
  toggle,
  type,
  title
}) => {
  const dispatch = useAppDispatch()

  const onSubmit = ({ file, separator }: IForm) => {
    const formData = new FormData()

    formData.append('name', file.name)
    formData.append('file', file)

    dispatch(importUsers({ formData, separator, type }))
    toggle()
  }

  const fields = ['separator', 'file']
  const values = getDefaultFormValues(
    { separator: separatorOptions[0].value },
    fields
  )

  return (
    <CustomModal open={isOpen} title={title} handleClose={toggle} width="404px">
      <div>
        <Formik
          onSubmit={onSubmit}
          initialValues={values}
          validationSchema={schema}
          isInitialValid={false}
        >
          {({ handleSubmit, isValid }) => {
            return (
              <StyledForm onSubmit={handleSubmit}>
                <FormFileLoader<IForm>
                  name="file"
                  accept=".csv"
                  label="Csv file"
                />
                <FormSelectInput<IForm>
                  label="Separator"
                  name="separator"
                  options={separatorOptions}
                />
                <StyledActions>
                  <Button
                    type="button"
                    size="large"
                    variant="outlined"
                    color="primary"
                    onClick={toggle}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!isValid}
                    type="submit"
                    size="large"
                    variant="contained"
                    color="primary"
                  >
                    Import
                  </Button>
                </StyledActions>
              </StyledForm>
            )
          }}
        </Formik>
      </div>
    </CustomModal>
  )
}
