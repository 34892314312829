import { styled } from '@mui/system'

import { FormLabel, Radio } from '@mui/material'
import { IRadioGroupProps } from './RadioGroup.types'

export const StyledRadioGroupContainer = styled('div')<
  Pick<IRadioGroupProps, 'direction' | 'width'>
>(({ direction = 'row' }) => ({
  display: 'flex',
  flexDirection: direction
}))
export const StyledLabel = styled(FormLabel)(({ theme }) => ({
  color: theme.palette.customColors.black
}))

export const StyledRadio = styled(Radio)(() => ({}))
