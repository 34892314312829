import { createAsyncThunk } from '@reduxjs/toolkit'
import { del, get, patch, post } from '../../axios'
import { API_ROUTES } from '../../core/router/apiRoutes'
import { BASE_SIZE, ALL_LIST_PAGE_SIZE } from '../../utils/constants'
import { IAdminClass } from '../../store/adminClasses/adminClasses.types'
import {
  IAdminClassCreateRequest,
  IAdminClassResponse,
  IAdminClassGetByIdRequest,
  ICommonChunkConfig,
  ICommonPageRequest,
  ICommonResponse,
  IErrorResponse,
  IAdminClassPatchRequest,
  IAdminClassSendMessageRequest,
  IAdminClassSendMessageToTeachersResponse
} from '..'

export const getAdminClasses = createAsyncThunk<
  ICommonResponse<IAdminClass>,
  ICommonPageRequest,
  ICommonChunkConfig
>(
  'adminClasses/getAdminClasses',
  async ({ allList, page, ..._data }, { rejectWithValue }) => {
    const params = allList
      ? {
          page: 1,
          pageSize: ALL_LIST_PAGE_SIZE,
          ..._data
        }
      : {
          page,
          pageSize: BASE_SIZE,
          ..._data
        }

    try {
      return await get<ICommonResponse<IAdminClass>>({
        path: API_ROUTES.ADMIN_CLASSES,
        params
      })
    } catch (error: any) {
      return rejectWithValue(error as IErrorResponse)
    }
  }
)

export const getAdminClass = createAsyncThunk<
  IAdminClassResponse,
  IAdminClassGetByIdRequest,
  ICommonChunkConfig
>('adminClasses/getAdminClass', async (_data, { rejectWithValue }) => {
  try {
    return await get<IAdminClassResponse>({
      path: `${API_ROUTES.ADMIN_CLASSES}/${_data.id}`,
      params: {}
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const createAdminClass = createAsyncThunk<
  IAdminClassResponse,
  IAdminClassCreateRequest,
  ICommonChunkConfig
>('adminClasses/createClass', async (_data, { rejectWithValue }) => {
  try {
    return await post<IAdminClassResponse>({
      path: API_ROUTES.ADMIN_CLASSES,
      data: _data
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const deleteAdminClass = createAsyncThunk<
  void,
  { id: string },
  ICommonChunkConfig
>('adminClasses/deleteClass', async ({ id }, { rejectWithValue }) => {
  try {
    return await del<void>({
      path: `${API_ROUTES.ADMIN_CLASSES}/${id}`,
      data: {}
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const sendAdminClassTeachersMessage = createAsyncThunk<
  IAdminClassSendMessageToTeachersResponse[],
  IAdminClassSendMessageRequest,
  ICommonChunkConfig
>('adminClasses/sendTeachersMessage', async (_data, { rejectWithValue }) => {
  try {
    return await post<IAdminClassSendMessageToTeachersResponse[]>({
      path: API_ROUTES.POST_TEACHERS_MESSAGE(_data.id),
      data: { text: _data.text }
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const sendAdminClassMessage = createAsyncThunk<
  void,
  IAdminClassSendMessageRequest,
  ICommonChunkConfig
>('adminClasses/sendClassMessage', async (_data, { rejectWithValue }) => {
  try {
    return await post<void>({
      path: API_ROUTES.POST_CLASS_MESSAGE(_data.id),
      data: { text: _data.text }
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})

export const patchAdminClass = createAsyncThunk<
  IAdminClassResponse,
  IAdminClassPatchRequest,
  ICommonChunkConfig
>('adminClasses/updateClass', async ({ id, ..._data }, { rejectWithValue }) => {
  try {
    return await patch<IAdminClassResponse>({
      path: `${API_ROUTES.ADMIN_CLASSES}/${id}`,
      data: _data
    })
  } catch (error: any) {
    return rejectWithValue(error as IErrorResponse)
  }
})
