import { memo, useEffect, useState } from 'react'
import {
  Pagination,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  TableSortLabel
} from '@mui/material'
import {
  StyledButton,
  StyledButtonWrapper,
  StyledPaginationWrapper,
  StyledPaper,
  StyledTableActionsWrapper,
  StyledTableBodyCell,
  StyledTableFiltersWrapper,
  StyledTableHeadCell,
  StyledTableRow,
  StyledTableSubtitle,
  StyledTableWrapper,
  StyledWrapper
} from './styles'
import { ICustomTableProps, IFilterStates } from './types'
import CustomSelect from '../CustomSelect'
import CustomOutlinedInput from '../CustomOutlinedInput'

const CustomTable = <T extends Record<string, any>>(
  props: ICustomTableProps<T>
) => {
  const {
    style,
    tableHead,
    rows,
    defaultSortColumn,
    sortCallback,
    rowsCount,
    dataCount,
    tableFiltersData,
    tableSubtitle,
    searchPlaceholder,
    tableAriaLabel,
    exportDataCallback
  } = props

  const [sortBy, setSortBy] = useState(defaultSortColumn)
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc')
  const [isInitial, setIsInitial] = useState(true)
  const [page, setPage] = useState(1)
  const [filterStates, setFilterStates] = useState<IFilterStates>({})
  const [search, setSearch] = useState('')
  const [searchValueToSend, setSearchValueToSend] = useState('')

  const handleFilterChange = (filterName: string, selectedValue: string) => {
    setDirection('asc')
    setPage(1)
    setSortBy(defaultSortColumn)
    setFilterStates((prevStates) => ({
      ...prevStates,
      [filterName]: {
        selectedFilter: filterName,
        filterValue: selectedValue
      }
    }))
  }

  const handleSetSortedBy = (name: string) => {
    setSortBy((prev) => {
      if (prev === name) {
        return name
      } else {
        setDirection('asc')
        return name
      }
    })
  }

  const handleSetDirection = () => {
    setDirection((prev) => {
      return prev === 'desc' ? 'asc' : 'desc'
    })
  }

  const sortHandler = (name: string) => {
    handleSetSortedBy(name)
    handleSetDirection()
  }

  const searchOnClickHandler = () => {
    setDirection('asc')
    setPage(1)
    setSortBy(defaultSortColumn)
    setSearchValueToSend(search)
  }

  const onKeyDown = (key: string) => {
    if (key === 'Enter') {
      searchOnClickHandler()
    }
  }

  useEffect(() => {
    if (isInitial) {
      setIsInitial(false)
    } else if (sortCallback) {
      const preparedFilters = Object.keys(filterStates)
        .map((filter) => {
          return `${filter}:${filterStates[filter].filterValue}`
        })
        .filter((el) => !el.includes('reset'))

      const params: {
        page: number
        sort?: string
        filters: string[]
        search: string | undefined
      } = {
        page,
        filters: preparedFilters,
        search: search ? search : undefined
      }

      if (sortBy && direction) {
        params.sort = `${sortBy}:${direction}`
      }

      sortCallback(params)
    }
  }, [sortBy, direction, page, filterStates, searchValueToSend])

  const getExportData = (format: 'pdf' | 'csv') => {
    const preparedFilters = Object.keys(filterStates)
      .map((filter) => {
        return `${filter}:${filterStates[filter].filterValue}`
      })
      .filter((el) => !el.includes('reset'))

    return {
      format,
      page,
      sort: `${sortBy}:${direction}`,
      search: search ? search : undefined,
      filter: preparedFilters
    }
  }

  return (
    <StyledWrapper>
      {exportDataCallback && (
        <StyledButtonWrapper>
          <StyledButton
            size={'large'}
            variant={'contained'}
            color={'primary'}
            onClick={() => exportDataCallback(getExportData('pdf'))}
          >
            exportPDF
          </StyledButton>
          <StyledButton
            size={'large'}
            variant={'contained'}
            color={'primary'}
            onClick={() => exportDataCallback(getExportData('csv'))}
          >
            exportExcel
          </StyledButton>
        </StyledButtonWrapper>
      )}
      <StyledTableActionsWrapper style={style && { marginBottom: '16px' }}>
        <div>
          <StyledTableSubtitle style={style}>
            {tableSubtitle}
          </StyledTableSubtitle>
        </div>
        <StyledTableFiltersWrapper>
          {searchPlaceholder && (
            <CustomOutlinedInput
              placeholder={searchPlaceholder}
              value={search}
              onChange={(val) => {
                setSearch(val)
              }}
              iconOnClick={searchOnClickHandler}
              iconPath={'/assets/search-icon.svg'}
              adornmentBg={'bg'}
              onKeyDown={onKeyDown}
            />
          )}
          {tableFiltersData &&
            Object.keys(tableFiltersData).map((filter) => {
              const displayName =
                filter.charAt(0).toUpperCase() + filter.slice(1)

              return (
                <CustomSelect
                  key={filterStates[filter]?.filterValue || `${filter}-reset`}
                  aria-label={'Select'}
                  value={filterStates[filter]?.filterValue || 'reset'}
                  options={[
                    { value: 'reset', name: `${displayName}` },
                    ...(tableFiltersData ? tableFiltersData[filter] : [])
                  ]}
                  onChange={(event) => {
                    handleFilterChange(filter, event.target.value as string)
                  }}
                  width={'140px'}
                />
              )
            })}
        </StyledTableFiltersWrapper>
      </StyledTableActionsWrapper>
      <StyledTableWrapper>
        <TableContainer component={StyledPaper}>
          <Table aria-label={tableAriaLabel}>
            <TableHead aria-label={'Table head'}>
              <TableRow>
                {tableHead.map((cellHead) => {
                  return (
                    <StyledTableHeadCell
                      key={`tableHead_${cellHead.name}`}
                      aria-label={cellHead.ariaLabel}
                      align={cellHead.cellAlign}
                    >
                      {cellHead.sortable ? (
                        <TableSortLabel
                          active={sortBy === cellHead.cellName}
                          direction={direction}
                          onClick={() => sortHandler(String(cellHead.cellName))}
                        >
                          {cellHead.name}
                        </TableSortLabel>
                      ) : (
                        cellHead.name
                      )}
                    </StyledTableHeadCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody
              aria-label={'Table body'}
              sx={{
                borderRadius: '50px'
              }}
            >
              {rows?.map((row, i) => {
                const fields = Object.keys(row)
                return (
                  <StyledTableRow
                    key={row[fields[0]] + i}
                    hovered={row.rowAction ? 'true' : 'false'}
                  >
                    {tableHead.map((item, i) => {
                      const excludeAction = row.excludeAction ?? []

                      return (
                        <StyledTableBodyCell
                          key={`${row.name}_${String(item.cellName)}_${i}`}
                          align={item.cellAlign ? item.cellAlign : 'left'}
                          onClick={
                            row.rowAction &&
                            !excludeAction.includes(item.cellName)
                              ? row.rowAction
                              : undefined
                          }
                        >
                          {row[item.cellName]}
                        </StyledTableBodyCell>
                      )
                    })}
                  </StyledTableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
        {rowsCount && Math.ceil(dataCount / rowsCount) > 1 && (
          <StyledPaginationWrapper>
            <Pagination
              count={Math.ceil(dataCount / rowsCount)}
              page={page}
              onChange={(_, num) => setPage(num)}
              variant="outlined"
              color="primary"
            />
          </StyledPaginationWrapper>
        )}
      </StyledTableWrapper>
    </StyledWrapper>
  )
}

export default memo(CustomTable)
