import { styled } from '@mui/system'
import Switch from '@mui/material/Switch'

export const StyledSwitch = styled(Switch)(({ theme }) => ({
  '&.MuiSwitch-root': {
    padding: 7
  },

  '> .MuiSwitch-track': {
    border: `1px solid ${theme.palette.customColors.linear} !important`,
    background: `${theme.palette.customColors.white} !important`,
    borderRadius: 12
  },

  '& .MuiSwitch-switchBase': {
    color: `${theme.palette.customColors.violetGrey}`
  },

  '&.Mui-checked .MuiSwitch-switchBase': {
    color: `${theme.palette.customColors.blue}`
  }
}))
