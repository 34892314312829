import { Page, PageTitle } from '../../components/_Page'
import { StyledButton } from '../../components/CustomTable/styles'
import { BASE_SIZE } from '../../utils/constants'
import CustomTable from '../../components/CustomTable'
import { useAdminClasses } from './hooks/useAdminClasses'
import { Modal } from '../../components/_UI'
import { TextInput } from '../../components/_UI'

export const AdminClasses = () => {
  const {
    teachersMessageModalId,
    classMessageModalId,
    teachersMessageText,
    classMessageText,
    tableFilters,
    tableHeader,
    tableRows,
    classesCount,
    sortCallbackHandler,
    addClassHandler,
    closeTeachersMessageModal,
    closeClassMessageModal,
    onTeachersMessageChange,
    onClassMessageChange,
    sendMessageToTeachers,
    sendMessageToClass
  } = useAdminClasses()

  return (
    <Page>
      <PageTitle
        actions={
          <StyledButton
            type={'button'}
            size={'large'}
            variant={'contained'}
            color={'primary'}
            onClick={addClassHandler}
          >
            Add class
          </StyledButton>
        }
      >
        Classes
      </PageTitle>

      <CustomTable
        tableFiltersData={tableFilters}
        tableAriaLabel={'Courses table'}
        dataCount={classesCount}
        tableHead={tableHeader}
        rowsCount={BASE_SIZE}
        rows={tableRows}
        defaultSortColumn={'name'}
        sortCallback={sortCallbackHandler}
        tableSubtitle={`You have ${classesCount} classes`}
        searchPlaceholder={'Look for a class'}
      />

      <Modal
        isOpen={!!teachersMessageModalId}
        title="Send message to teachers"
        onClose={closeTeachersMessageModal}
        onSuccess={sendMessageToTeachers}
        confirmText="Send a message"
        confirmDisabled={!teachersMessageText?.length}
      >
        <TextInput
          multiline
          value={teachersMessageText}
          onChange={onTeachersMessageChange}
        />
      </Modal>

      <Modal
        isOpen={!!classMessageModalId}
        title="Send message to class"
        onClose={closeClassMessageModal}
        onSuccess={sendMessageToClass}
        confirmText="Send a message"
        confirmDisabled={!classMessageText?.length}
      >
        <TextInput
          multiline
          value={classMessageText}
          onChange={onClassMessageChange}
        />
      </Modal>
    </Page>
  )
}
