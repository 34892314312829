import { FormikHelpers } from 'formik/dist/types'
import { ISchoolForm, ISchoolFormProps } from '../SchoolForm.types'
import {
  createSchool,
  ISchoolLogoResponse,
  patchSchool,
  SchoolType,
  uploadSchoolLogo
} from '../../../../services'
import customToast from '../../../../components/CustomToast'
import { useAppDispatch } from '../../../../hooks'
import { useCallback } from 'react'

type FormCbValues = Omit<ISchoolForm, 'logoPath' | 'logoUrl'> & {
  logoPath: string | undefined
}
export const useSchoolSubmit: (
  params: Omit<ISchoolFormProps, 'onClose'>
) => (
  values: ISchoolForm,
  formikHelpers: FormikHelpers<ISchoolForm>
) => void | Promise<any> = ({ initialValues, onSuccess }) => {
  const dispatch = useAppDispatch()

  const pathSchoolCb = useCallback(
    ({ isCompany, licenseEnd, licenseStart, ...values }: FormCbValues) => {
      if (!initialValues?.id) return

      dispatch(
        patchSchool({
          id: initialValues.id,
          type: isCompany ? SchoolType.company : SchoolType.school,
          licenseEnd: licenseEnd,
          licenseStart: licenseStart,
          ...values
        })
      ).then((params) => {
        if (params.meta.requestStatus === 'fulfilled') {
          customToast({
            type: 'success',
            message: `School "${values.shortName}" was update successful`
          })
          onSuccess && onSuccess()
        }
      })
    },
    [initialValues]
  )

  const createSchoolCb = useCallback(
    ({ isCompany, licenseStart, licenseEnd, ...values }: FormCbValues) => {
      dispatch(
        createSchool({
          type: isCompany ? SchoolType.company : SchoolType.school,
          licenseEnd: licenseEnd,
          licenseStart: licenseStart,
          ...values
        })
      ).then((params) => {
        if (params.meta.requestStatus === 'fulfilled') {
          customToast({
            type: 'success',
            message: `School "${values.shortName}" create successful`
          })
          onSuccess && onSuccess()
        }
      })
    },
    []
  )

  return useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    ({ logoUrl, ...values }) => {
      if (values.logoPath instanceof File) {
        dispatch(
          uploadSchoolLogo({
            file: values.logoPath
          })
        ).then((params) => {
          if (params.meta.requestStatus === 'fulfilled' && params.payload) {
            const payload = params.payload as ISchoolLogoResponse
            const logoPath = payload.logoPath

            const reqParams = {
              ...values,
              logoPath: logoPath
            }

            if (initialValues?.id) {
              pathSchoolCb(reqParams)
            } else {
              createSchoolCb(reqParams)
            }
          }
        })

        return
      }

      const reqParams = {
        ...values,
        logoPath: values.logoPath
      }

      if (initialValues?.id) {
        pathSchoolCb(reqParams)
      } else {
        createSchoolCb(reqParams)
      }
    },
    [initialValues, onSuccess]
  )
}
