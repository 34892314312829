import { ICoursesTable } from '../Courses.types'

export const useCoursesTableHeader: () => {
  name: string
  ariaLabel: string
  cellName: keyof ICoursesTable
  sortable: boolean
}[] = () => {
  return [
    {
      name: 'Name of Course',
      ariaLabel: 'Name of Course',
      cellName: 'name',
      sortable: false
    },
    {
      name: 'Units',
      ariaLabel: 'Units',
      cellName: 'units',
      sortable: false
    },
    {
      name: 'Activities',
      ariaLabel: 'Activities',
      cellName: 'activities',
      sortable: false
    },
    {
      name: 'Actions',
      ariaLabel: 'Actions',
      cellName: 'actions',
      sortable: false
    }
  ]
}
