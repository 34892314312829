import { useMemo } from 'react'

import { IAdminTeacher } from '../../../store/teachers/teachers.types'

import {
  Dropdown,
  ITooltipItemsProps,
  TooltipItems
} from '../../../components/_UI'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '../../../core/router/appRoutes'

export const useAdminTeachersTableRows = (
  teachers: IAdminTeacher[],
  { sendMessage }: { sendMessage: (id: string) => void }
) => {
  const navigate = useNavigate()

  return useMemo(
    () =>
      teachers.map((teacher) => {
        const {
          id,
          fullName,
          username,
          schools,
          classes,
          studentsCount,
          courses
        } = teacher

        const tooltipItems: ITooltipItemsProps['items'] = [
          {
            type: 'button',
            title: 'Send message to teacher',
            handler: () => {
              sendMessage(id)
            }
          },
          {
            type: 'link',
            title: 'Go to teacher details',
            to: `${APP_ROUTES.TEACHERS}/${username}`
          },
          {
            type: 'link',
            title: 'Edit teacher',
            to: `${APP_ROUTES.EDIT_TEACHER}/${username}`
          }
        ]

        const getContentString = (
          array: { [key: string]: any }[],
          paramName: string
        ) => {
          if (Array.isArray(array)) {
            return array.map((item) => item[paramName]).join(', ')
          }

          return ''
        }

        return {
          fullName: fullName,
          school: getContentString(schools, 'shortName'),
          classes: getContentString(classes, 'name'),
          students: studentsCount,
          courses: getContentString(courses, 'name'),
          rowAction: () => navigate(`${APP_ROUTES.TEACHERS}/${username}`),
          excludeAction: ['actions'],
          actions: (
            <Dropdown>
              <TooltipItems items={tooltipItems} />
            </Dropdown>
          )
        }
      }),
    [teachers]
  )
}
