import { FC, useCallback, useEffect, useMemo } from 'react'
import { Box, Page, PageTitle } from '../../components/_Page'
import { StyledBackButton } from '../../components/CustomTable/styles'
import { useNavigate, useParams } from 'react-router-dom'
import { APP_ROUTES } from '../../core/router/appRoutes'
import { StudentForm } from '../_Common'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { getAdminStudent } from '../../services'
import { editStudentFormSchema } from '../_Common/StudentForm/schemas/StudentForm.schema'

export const EditStudent: FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const queryParams = useParams()

  const studentUsername = queryParams.username!

  const { isPending: isPendingStudent, data: student } = useAppSelector(
    (state) => state.students.student
  )

  const handleBackButton = useCallback(() => {
    navigate(`${APP_ROUTES.STUDENTS}`)
  }, [])

  const onSuccessHandler = useCallback(() => {
    navigate(`${APP_ROUTES.STUDENTS}`)
  }, [])

  const validationSchema = useMemo(
    () => editStudentFormSchema(!!student?.email),
    [student]
  )

  useEffect(() => {
    dispatch(getAdminStudent({ username: studentUsername }))
  }, [studentUsername])

  if (isPendingStudent || !student) {
    return <Page />
  }

  return (
    <Box>
      <PageTitle
        back={
          <StyledBackButton
            aria-label={'Previous page button'}
            onClick={handleBackButton}
          />
        }
      >
        {student.fullName}
      </PageTitle>
      <StudentForm
        type="edit"
        validationSchema={validationSchema}
        onSuccess={onSuccessHandler}
        initialValues={{
          id: student.id,
          username: student.username,
          avatarPath: student.avatarUrl,
          fullName: student.fullName,
          email: student.email || '',
          schoolIds: student.schools.map((school) => school.id),
          classIds: student.classes.map((classItem) => classItem.id)
        }}
      />
    </Box>
  )
}
