export const useTicketTableHeader = () => {
  return [
    {
      name: '№ Ticket',
      ariaLabel: '№ Ticket',
      cellName: 'ticketNumber',
      sortable: false
    },
    {
      name: 'School',
      ariaLabel: 'School',
      cellName: 'school',
      sortable: false
    },
    {
      name: 'Teacher',
      ariaLabel: 'Teacher',
      cellName: 'teacher',
      sortable: false
    },
    {
      name: 'Category',
      ariaLabel: 'Category',
      cellName: 'category',
      sortable: false
    },
    {
      name: 'Commentary',
      ariaLabel: 'Commentary',
      cellName: 'commentary',
      sortable: false
    },
    {
      name: 'Sent on',
      ariaLabel: 'Sent on',
      cellName: 'sentOn',
      sortable: false
    },
    {
      name: 'Status',
      ariaLabel: 'Status',
      cellName: 'status',
      sortable: false
    }
  ]
}
