import { FC, memo, useEffect, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { IGuard } from './types'
import { useAppSelector } from '../../hooks'
import { getCurrentUserSelector, getLoadingUserSelector } from '../selectors'
import { APP_ROUTES } from '../router/appRoutes'
import CustomLoader from '../../components/CustomLoader'
import { StyledLoading } from './styles'
import { ACCESS_TOKEN_NAME } from '../../utils/constants'
import Cookies from 'js-cookie'

export const RegisteredUserGuard: FC<IGuard> = memo(({ children }: IGuard) => {
  const currentUser = useAppSelector(getCurrentUserSelector)
  const isLoading = useAppSelector(getLoadingUserSelector)
  const [isRendered, setIsRendered] = useState(false)
  const accessToken = Cookies.get(ACCESS_TOKEN_NAME)

  useEffect(() => {
    if (currentUser || !accessToken) {
      setIsRendered(true)
    }
  })

  if (isLoading || currentUser === undefined) {
    return (
      <StyledLoading>
        <CustomLoader size={90} />
      </StyledLoading>
    )
  }

  if (!isRendered) {
    return null
  }

  return currentUser ? children : <Navigate to={APP_ROUTES.LOGIN} />
})

export const UnregisteredUserGuard: FC<IGuard> = memo(
  ({ children }: IGuard) => {
    const currentUser = useAppSelector(getCurrentUserSelector)
    const isLoading = useAppSelector(getLoadingUserSelector)

    if (isLoading || currentUser === undefined) {
      return (
        <StyledLoading>
          <CustomLoader size={90} />
        </StyledLoading>
      )
    }

    return currentUser === null ? children : <Navigate to={APP_ROUTES.MAIN} />
  }
)
