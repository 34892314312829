import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { StyledDatePicker } from './DatePicker.style'
import { FC } from 'react'
import { IDatePickerPops } from './DatePicker.types'

export const DatePicker: FC<IDatePickerPops> = ({
  onChange,
  value,
  ...props
}) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <StyledDatePicker
        value={value ?? null}
        onChange={(val, context) => {
          if (onChange) {
            onChange(val, context)
          }
        }}
        {...props}
      />
    </LocalizationProvider>
  )
}
