import { FormikHelpers } from 'formik/dist/types'
import {
  createActivity,
  IActivityStatus,
  patchActivity
} from '../../../../services'
import customToast from '../../../../components/CustomToast'
import { useAppDispatch } from '../../../../hooks'
import { useCallback } from 'react'
import { IActivityForm, IActivityFormProps } from '../ActivityForm.types'

export const useActivitySubmit: (
  params: Omit<IActivityFormProps, 'onClose'>
) => (
  values: IActivityForm,
  formikHelpers: FormikHelpers<IActivityForm>
) => void | Promise<any> = ({ initialValues, onSuccess, unitId }) => {
  const dispatch = useAppDispatch()

  return useCallback(
    ({ draft, ...values }) => {
      if (values.id) {
        dispatch(
          patchActivity({
            id: values.id,
            unitId,
            status: draft ? IActivityStatus.draft : IActivityStatus.published,
            ...values
          })
        ).then((params) => {
          if (params.meta.requestStatus === 'fulfilled') {
            customToast({
              type: 'success',
              message: `Activity "${values.title}" was update successful`
            })
            onSuccess && onSuccess()
          }
        })
      } else {
        dispatch(
          createActivity({
            unitId,
            status: draft ? IActivityStatus.draft : IActivityStatus.published,
            ...values
          })
        ).then((params) => {
          if (params.meta.requestStatus === 'fulfilled') {
            customToast({
              type: 'success',
              message: `Activity "${values.title}" create successful`
            })
            onSuccess && onSuccess()
          }
        })
      }
    },
    [initialValues, onSuccess]
  )
}
