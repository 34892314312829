import { StyledSchoolPage } from './Schools.style'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { ADMIN_SCHOOLS_PAGE_SIZE } from '../../utils/constants'
import CustomTable from '../../components/CustomTable'
import { useAdminSchoolTableHeader } from './hooks/useAdminSchoolTableHeader'
import { useAdminSchoolTableRows } from './hooks/useAdminSchoolTableRows'
import { useAdminSchoolTableFilter } from './hooks/useAdminSchoolTableFilter'
import { PageTitle } from '../../components/_Page/'
import { usePreparationSchools } from './hooks/usePreparationSchools'
import { StyledButton } from '../../components/CustomTable/styles'
import { useNavigate } from 'react-router-dom'
import { useCallback, useState } from 'react'
import { APP_ROUTES } from '../../core/router/appRoutes'
import { Modal } from '../../components/_UI'
import { deleteSchool } from '../../services'
import { schoolsActions } from '../../store/schools/schools.reducer'

export const Schools = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const {
    data: { schools, count: schoolsCount }
  } = useAppSelector((state) => state.schools.schools)

  const [isOpenDeleteModal, setIsOpeDeleteModal] = useState(false)
  const [deleteId, setDeleteId] = useState<string | undefined>(undefined)

  const closeDeleteModal = useCallback(() => {
    setIsOpeDeleteModal(false)
  }, [])

  const setDeleteSchoolHandler = useCallback((schoolId: string) => {
    setIsOpeDeleteModal(true)
    setDeleteId(schoolId)
  }, [])

  const deleteSchoolHandler = useCallback(() => {
    if (!deleteId) return

    setIsOpeDeleteModal(false)
    dispatch(deleteSchool({ id: deleteId })).then((params) => {
      if (params.meta.requestStatus === 'fulfilled') {
        dispatch(schoolsActions.setIsNeedRefetchSchools(true))
      }
    })
  }, [deleteId])

  const tableHeader = useAdminSchoolTableHeader()
  const tableRows = useAdminSchoolTableRows(schools, {
    deleteSchool: setDeleteSchoolHandler
  })
  const tableFilters = useAdminSchoolTableFilter()
  const sortCallbackHandler = usePreparationSchools()

  const addSchoolHandler = useCallback(() => {
    navigate(APP_ROUTES.NEW_SCHOOL)
  }, [])

  return (
    <StyledSchoolPage>
      <PageTitle
        actions={
          <StyledButton
            type={'button'}
            size={'large'}
            variant={'contained'}
            color={'primary'}
            onClick={addSchoolHandler}
          >
            Add school
          </StyledButton>
        }
      >
        Schools
      </PageTitle>

      <CustomTable
        tableFiltersData={tableFilters}
        tableAriaLabel={'Schools table'}
        dataCount={schoolsCount}
        tableHead={tableHeader}
        rowsCount={ADMIN_SCHOOLS_PAGE_SIZE}
        rows={tableRows}
        defaultSortColumn={''}
        sortCallback={sortCallbackHandler}
        tableSubtitle={`You have ${schoolsCount} schools`}
        searchPlaceholder={'Look for a school'}
      />

      <Modal
        isOpen={isOpenDeleteModal}
        title={'Delete school'}
        onClose={closeDeleteModal}
        onSuccess={deleteSchoolHandler}
        confirmText={'Delete'}
      >
        You are about to delete school, this action is permanent. Do you want to
        proceed?
      </Modal>
    </StyledSchoolPage>
  )
}
