import { styled } from '@mui/system'

export const StyledSchoolContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '24px',
  marginTop: '24px'
}))

export const StyledSchoolImg = styled('img')(({ theme }) => ({
  width: '288px',
  flexShrink: '0',
  borderRadius: '8px',
  overflow: 'hidden',
  display: 'block',
  backgroundColor: theme.palette.customColors.white,
  minHeight: '90px',
  maxHeight: '200px',
  marginBottom: 'auto'
}))

export const StyledSchoolDetailList = styled('div')(() => ({
  display: 'grid',
  gridTemplateColumns: '1fr 2fr',
  gap: '16px'
}))

export const StyledSchoolDetailedLabel = styled('div')(({ theme }) => ({
  fontSize: '16px',
  fontWeight: '600',
  lineHeight: '20px',
  color: theme.palette.customColors.grayMedium
}))

export const StyledSchoolDetailedContent = styled('div')(({ theme }) => ({
  fontSize: '16px',
  fontWeight: '600',
  lineHeight: '20px',
  color: theme.palette.customColors.black,

  '& a': {
    color: theme.palette.customColors.black,
    '&:hover': {
      textDecoration: 'none'
    }
  }
}))

export const StyledSchoolContainerActions = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  flexShrink: 0,
  marginLeft: 'auto'
}))

export const StyledSchoolContainerActionsTop = styled('div')(() => ({
  textAlign: 'right'
}))

export const StyledSchoolContainerActionsBottom = styled('div')(() => ({
  marginTop: 'auto',
  textAlign: 'right'
}))
