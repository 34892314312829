import {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { Box, Page, PageTitle } from '../../components/_Page'
import { useNavigate, useParams } from 'react-router-dom'
import { deleteSchool, getSchool } from '../../services'
import { useAppDispatch, useAppSelector } from '../../hooks'
import dayjs from 'dayjs'
import {
  StyledSchoolContainer,
  StyledSchoolContainerActions,
  StyledSchoolContainerActionsBottom,
  StyledSchoolContainerActionsTop,
  StyledSchoolDetailedContent,
  StyledSchoolDetailedLabel,
  StyledSchoolDetailList,
  StyledSchoolImg
} from './SchoolView.style'
import { StyledButton } from '../../components/CustomTable/styles'
import { APP_ROUTES } from '../../core/router/appRoutes'
import { Modal } from '../../components/_UI'
import { schoolsActions } from '../../store/schools/schools.reducer'

const baseImg = '/assets/school-image-for-form.png'

const SchoolDetailedItem: FC<PropsWithChildren<{ label: string }>> = ({
  label,
  children
}) => {
  return (
    <>
      <StyledSchoolDetailedLabel>{label}</StyledSchoolDetailedLabel>
      <StyledSchoolDetailedContent>{children}</StyledSchoolDetailedContent>
    </>
  )
}
export const SchoolView: FC = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const queryParams = useParams()

  const [isOpenDeleteModal, setIsOpeDeleteModal] = useState(false)
  const { isPending: isPendingSchool, data: school } = useAppSelector(
    (state) => state.schools.school
  )
  const [isError, setIsError] = useState(false)
  const imgRef = useRef<HTMLImageElement>(null)

  const onErrorHandler = useCallback(() => {
    if (isError || !imgRef.current) return

    setIsError(true)
    imgRef.current.src = baseImg
  }, [isError])

  const closeDeleteModal = useCallback(() => {
    setIsOpeDeleteModal(false)
  }, [])

  const schoolId = queryParams.id!

  const onDeleteSchoolHandler = useCallback(() => {
    setIsOpeDeleteModal(true)
  }, [])

  const onEditHandler = useCallback(
    () => navigate(`${APP_ROUTES.EDIT_SCHOOL}/${schoolId}`),
    [schoolId]
  )

  const deleteSchoolHandler = useCallback(() => {
    setIsOpeDeleteModal(false)
    dispatch(deleteSchool({ id: schoolId })).then((params) => {
      if (params.meta.requestStatus === 'fulfilled') {
        dispatch(schoolsActions.setIsNeedRefetchSchools(true))
        navigate(`${APP_ROUTES.SCHOOLS}`)
      }
    })
  }, [schoolId])

  useEffect(() => {
    dispatch(getSchool({ id: schoolId }))
  }, [schoolId])

  if (isPendingSchool || !school) {
    return <Page />
  }

  return (
    <Page>
      <Box>
        <PageTitle>{school.longName}</PageTitle>

        <StyledSchoolContainer>
          <StyledSchoolImg
            src={school.logoUrl || baseImg}
            onError={onErrorHandler}
            ref={imgRef}
          />

          <StyledSchoolDetailList>
            <SchoolDetailedItem label={'Name'}>
              {school.longName}
            </SchoolDetailedItem>

            <SchoolDetailedItem label={'Short name'}>
              {school.shortName}
            </SchoolDetailedItem>

            <SchoolDetailedItem label={'Country'}>
              {school.country}
            </SchoolDetailedItem>

            <SchoolDetailedItem label={'Type of school'}>
              {school.level}
            </SchoolDetailedItem>

            <SchoolDetailedItem label={'Name of manager'}>
              {school.managerFullName}
            </SchoolDetailedItem>

            <SchoolDetailedItem label={'Email of the manager'}>
              <a href={`mailto:${school.managerEmail}`}>
                {school.managerEmail}
              </a>
            </SchoolDetailedItem>

            <SchoolDetailedItem label={'Phone number of the manager'}>
              <a href={`tel:${school.managerPhone}`}>{school.managerPhone}</a>
            </SchoolDetailedItem>

            <SchoolDetailedItem label={'License start date'}>
              {dayjs(school.licenseStart).format('DD.MM.YYYY')}
            </SchoolDetailedItem>

            <SchoolDetailedItem label={'License duration'}>
              {school.licenseDuration} Month
            </SchoolDetailedItem>

            <SchoolDetailedItem label={'Courses'}>
              {school.courses?.map(({ id, name }) => (
                <div key={id}>{name}</div>
              ))}
            </SchoolDetailedItem>
          </StyledSchoolDetailList>

          <StyledSchoolContainerActions>
            <StyledSchoolContainerActionsTop>
              <StyledButton
                type={'button'}
                size={'large'}
                variant={'outlined'}
                color={'primary'}
                onClick={onEditHandler}
              >
                Edit information
              </StyledButton>
            </StyledSchoolContainerActionsTop>
            <StyledSchoolContainerActionsBottom>
              <StyledButton
                type={'button'}
                size={'large'}
                variant={'outlined'}
                color={'error'}
                onClick={onDeleteSchoolHandler}
              >
                Delete school
              </StyledButton>
            </StyledSchoolContainerActionsBottom>
          </StyledSchoolContainerActions>
        </StyledSchoolContainer>

        <Modal
          isOpen={isOpenDeleteModal}
          title={'Delete school'}
          onClose={closeDeleteModal}
          onSuccess={deleteSchoolHandler}
          confirmText={'Delete'}
        >
          You are about to delete school, this action is permanent. Do you want
          to proceed?
        </Modal>
      </Box>
    </Page>
  )
}
