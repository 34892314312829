export enum colors {
  bg = '#F0F7FC',
  black = '#010121',
  blue = '#2626EA',
  blueDark = '#09095D',
  blueHover = '#5C5CEF',
  blueLight = '#D0D0FA',
  gray = '#374957',
  grayLight = '#D0DAE1',
  grayMedium = '#7B8E9C',
  green = '#35ED99',
  greenDark = '#085E37',
  greenLight = '#D0FBE7',
  linear = '#C7DAFE',
  sky = '#12A5FF',
  skyDark = '#003F66',
  skyLight = '#CCECFF',
  violet = '#7A27F2',
  violetDark = '#2A0660',
  violetGrey = '#9E9DB0',
  violetLight = '#E1CFFC',
  white = '#FFFFFF',
  red = '#FF3A52',
  error = '#d32f2f',
  redLight = '#FFDFE3',
  redDark = '#9F2433'
}

export enum ScoreFormatType {
  percent = 'percent',
  ten = 'ten',
  twenty = 'twenty',
  thirty = 'thirty',
  letters_af = 'letters_af'
}

export enum Language {
  en = 'en',
  fr = 'fr',
  it = 'it',
  ko = 'ko',
  lv = 'lv',
  ru = 'ru',
  sp = 'sp'
}

export enum UserType {
  admin = 'admin',
  teacher = 'teacher',
  student = 'student'
}

export enum SchoolLevel {
  primary = 'primary',
  middle = 'middle',
  high = 'high',
  university = 'university'
}

export interface ISortCallbackParams {
  page: number
  sort?: string
  filter?: string[]
  search?: string
}

export interface IExportCallbackParams extends ISortCallbackParams {
  format: 'csv' | 'pdf'
}

export enum GrammarTags {
  present = 'present',
  past = 'past',
  future = 'future',
  subjunctive = 'subjunctive',
  conditional = 'conditional',
  comparatives = 'comparatives',
  superlatives = 'superlatives'
}

export enum SyntaxTags {
  affirmativeSentences = 'affirmativeSentences',
  negativeSentences = 'negativeSentences',
  interrogativeSentences = 'interrogativeSentences',
  wordOrder = 'wordOrder'
}

export enum VocabTags {
  nouns = 'nouns',
  verbs = 'verbs',
  adjectives = 'adjectives',
  adverbs = 'adverbs',
  pronouns = 'pronouns',
  conjunctions = 'conjunctions',
  prepositions = 'prepositions',
  workVocabulary = 'workVocabulary',
  technicalVocabulary = 'technicalVocabulary'
}
