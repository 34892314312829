import { GrammarTags, Language, SyntaxTags, VocabTags, colors } from '../types'
import { IActivityLevel } from '../services'
import { Item } from '../components/CustomMultySelect/types'

export const ACCESS_TOKEN_NAME = 'accessToken'
export const REFRESH_TOKEN_NAME = 'refreshToken'

export const BASE_SIZE = 10
export const TICKET_PAGE_SIZE = 10
export const ADMIN_SCHOOLS_PAGE_SIZE = 10
export const ADMIN_TEACHERS_PAGE_SIZE = 10
export const ADMIN_COURSES_PAGE_SIZE = 10
export const UNITS_PAGE_SIZE = 10
export const ACTIVITIES_PAGE_SIZE = 10
export const ALL_LIST_PAGE_SIZE = 1000000
export const INITIAL_SHOWED_ITEMS = 2
export const ADMIN_STUDENTS_PAGE_SIZE = 10

export const TICKET_STATUSES = {
  pending: {
    mainColor: colors.sky,
    supportColor: colors.skyLight
  },
  unsolved: {
    mainColor: colors.red,
    supportColor: colors.redLight
  },
  solved: {
    mainColor: colors.green,
    supportColor: colors.greenLight
  }
}

export const ACTIVITY_LEVELS_MAP: Record<
  IActivityLevel,
  {
    name: string
    level: string
    bgColor: colors
    textColor: colors
    borderColor: colors
  }
> = {
  [IActivityLevel.a1_beginner]: {
    name: 'Beginner',
    level: '(A1)',
    bgColor: colors.greenLight,
    textColor: colors.greenDark,
    borderColor: colors.greenDark
  },
  [IActivityLevel.a2_elementary]: {
    name: 'Elementary',
    level: '(A2)',
    bgColor: colors.skyLight,
    textColor: colors.sky,
    borderColor: colors.sky
  },
  [IActivityLevel.b1_preintermediate]: {
    name: 'Pre-intermediate',
    level: '(B1)',
    bgColor: colors.blueLight,
    textColor: colors.blue,
    borderColor: colors.blue
  },
  [IActivityLevel.b2_intermediate]: {
    name: 'Intermediate',
    level: '(B2)',
    bgColor: colors.violetLight,
    textColor: colors.violet,
    borderColor: colors.violet
  },
  [IActivityLevel.c1_upperIntermediate]: {
    name: 'Upper-intermediate',
    level: '(C1)',
    bgColor: colors.redLight,
    textColor: colors.redDark,
    borderColor: colors.red
  },
  [IActivityLevel.c2_advanced]: {
    name: 'Advanced',
    level: '(C2)',
    bgColor: colors.redLight,
    textColor: colors.redDark,
    borderColor: colors.red
  }
}

export const COURSE_LANGUAGES = {
  [Language.en]: 'English',
  [Language.fr]: 'French',
  [Language.it]: 'Italian',
  [Language.ko]: 'Korean',
  [Language.sp]: 'Spanish',
  ge: 'German',
  ch: 'Chinese',
  ru: 'Russian'
}

export const GRAMMARS: Item<GrammarTags>[] = [
  {
    name: 'Present',
    value: GrammarTags.present
  },
  {
    name: 'Past',
    value: GrammarTags.past
  },
  {
    name: 'Future',
    value: GrammarTags.future
  },
  {
    name: 'Subjunctive',
    value: GrammarTags.subjunctive
  },
  {
    name: 'Conditional',
    value: GrammarTags.conditional
  },
  {
    name: 'Comparatives',
    value: GrammarTags.comparatives
  },
  {
    name: 'Superlatives',
    value: GrammarTags.superlatives
  }
]

export const SYNTAX: Item<SyntaxTags>[] = [
  {
    name: 'Affirmative sentences',
    value: SyntaxTags.affirmativeSentences
  },
  {
    name: 'Negative sentences',
    value: SyntaxTags.negativeSentences
  },
  {
    name: 'Interrogative sentences',
    value: SyntaxTags.interrogativeSentences
  },
  {
    name: 'Word order',
    value: SyntaxTags.wordOrder
  }
]

export const VOCAB: Item<VocabTags>[] = [
  {
    name: 'Nouns',
    value: VocabTags.nouns
  },
  {
    name: 'Verbs',
    value: VocabTags.verbs
  },
  {
    name: 'Adjectives',
    value: VocabTags.adjectives
  },
  {
    name: 'Adverbs',
    value: VocabTags.adverbs
  },
  {
    name: 'Pronouns',
    value: VocabTags.pronouns
  },
  {
    name: 'Conjunctions',
    value: VocabTags.conjunctions
  },
  {
    name: 'Prepositions',
    value: VocabTags.prepositions
  },
  {
    name: 'Work vocabulary',
    value: VocabTags.workVocabulary
  },
  {
    name: 'Technical vocabulary',
    value: VocabTags.technicalVocabulary
  }
]
