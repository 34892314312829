import { FC, PropsWithChildren, useMemo } from 'react'
import { Page, PageTitle, Box } from '../../components/_Page'
import {
  StyledAdminClassViewContainer,
  StyledAdminClassViewContainerActions,
  StyledAdminClassViewContainerActionsBottom,
  StyledAdminClassViewContainerActionsTop,
  StyledAdminClassViewDetailList,
  StyledAdminClassViewDetailedContent,
  StyledAdminClassViewDetailedLabel
} from './AdminClassView.style'
import {
  StyledBackButton,
  StyledButton
} from '../../components/CustomTable/styles'
import { Modal } from '../../components/_UI'
import { useAdminClassView } from './hooks/useAdminClassView'
import { INITIAL_SHOWED_ITEMS } from '../../utils/constants'

const DetailedItem: FC<PropsWithChildren<{ label: string }>> = ({
  label,
  children
}) => {
  return (
    <>
      <StyledAdminClassViewDetailedLabel>
        {label}
      </StyledAdminClassViewDetailedLabel>
      <StyledAdminClassViewDetailedContent>
        {children}
      </StyledAdminClassViewDetailedContent>
    </>
  )
}

export const AdminClassView: FC = () => {
  const {
    isPendingAdminClass,
    isOpenDeleteModal,
    showAllStudents,
    showAllTeachers,
    showStudentsHandler,
    showTeachersHandler,
    adminClass,
    onEditHandler,
    onDeleteHandler,
    deleteHandler,
    onBackHandler,
    closeDeleteModal
  } = useAdminClassView()

  const students = useMemo(() => {
    if (isPendingAdminClass || !adminClass) {
      return []
    }

    if (adminClass.students.length > INITIAL_SHOWED_ITEMS && !showAllStudents) {
      return adminClass.students.slice(0, INITIAL_SHOWED_ITEMS)
    }

    return adminClass.students
  }, [adminClass, showAllStudents])

  const teachers = useMemo(() => {
    if (isPendingAdminClass || !adminClass) {
      return []
    }

    if (adminClass.teachers.length > INITIAL_SHOWED_ITEMS && !showAllTeachers) {
      return adminClass.teachers.slice(0, INITIAL_SHOWED_ITEMS)
    }

    return adminClass.teachers
  }, [adminClass, showAllTeachers])

  if (isPendingAdminClass || !adminClass) {
    return <Page />
  }

  return (
    <Page>
      <Box>
        <PageTitle
          back={
            <StyledBackButton
              aria-label={'Previous page button'}
              onClick={onBackHandler}
            />
          }
        >
          Classes list
        </PageTitle>

        <StyledAdminClassViewContainer>
          <StyledAdminClassViewDetailList>
            <DetailedItem label="Name of class">{adminClass.name}</DetailedItem>

            <DetailedItem label="School">
              {adminClass.school.longName}
            </DetailedItem>

            <DetailedItem label="Students">
              {students.map(({ name, value }) => (
                <div key={value}>{name}</div>
              ))}

              {adminClass.students.length > INITIAL_SHOWED_ITEMS && (
                <StyledButton
                  variant="outlined"
                  size="large"
                  onClick={showStudentsHandler}
                >
                  {showAllStudents
                    ? 'Show less'
                    : `Show more ${
                        adminClass.students.length - INITIAL_SHOWED_ITEMS
                      }`}
                </StyledButton>
              )}
            </DetailedItem>

            <DetailedItem label="Teachers">
              {teachers.map(({ name, value }) => (
                <div key={value}>{name}</div>
              ))}

              {adminClass.teachers.length > INITIAL_SHOWED_ITEMS && (
                <StyledButton
                  variant="outlined"
                  size="large"
                  onClick={showTeachersHandler}
                >
                  {showAllTeachers
                    ? 'Show less'
                    : `Show more ${
                        adminClass.teachers.length - INITIAL_SHOWED_ITEMS
                      }`}
                </StyledButton>
              )}
            </DetailedItem>
          </StyledAdminClassViewDetailList>

          <StyledAdminClassViewContainerActions>
            <StyledAdminClassViewContainerActionsTop>
              <StyledButton
                type={'button'}
                size={'large'}
                variant={'outlined'}
                color={'primary'}
                onClick={onEditHandler}
              >
                Edit information
              </StyledButton>
            </StyledAdminClassViewContainerActionsTop>
            <StyledAdminClassViewContainerActionsBottom>
              <StyledButton
                type={'button'}
                size={'large'}
                variant={'outlined'}
                color={'error'}
                onClick={onDeleteHandler}
              >
                Delete class
              </StyledButton>
            </StyledAdminClassViewContainerActionsBottom>
          </StyledAdminClassViewContainerActions>
        </StyledAdminClassViewContainer>

        <Modal
          isOpen={isOpenDeleteModal}
          title={'Delete school'}
          onClose={closeDeleteModal}
          onSuccess={deleteHandler}
          confirmText={'Delete'}
        >
          You are about to delete class, this action is permanent. Do you want
          to proceed?
        </Modal>
      </Box>
    </Page>
  )
}
