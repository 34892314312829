import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import Cookies from 'js-cookie'
import { getCurrentUser, login } from '../../services/auth/auth.service'

import {
  IAuthState,
  IUpdateCurrentUSerFullNamePayload,
  IUser
} from './auth.types'

import { ACCESS_TOKEN_NAME, REFRESH_TOKEN_NAME } from '../../utils/constants'
import { setStorageValue } from '../../utils/helpers'

const initialState: IAuthState = {
  isLoading: false,
  isLogIn: false,
  isAdmin: false,
  user: null,
  accessToken: null,
  refreshToken: null
}

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetCurrentUser: (state) => {
      state.user = initialState.user
    },
    updateCurrentUserFullName: (
      state,
      { payload }: PayloadAction<IUpdateCurrentUSerFullNamePayload>
    ) => {
      if (state.user) {
        state.user.fullName = payload.fullName
      }
    }
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      login.fulfilled,
      (state, { payload }: PayloadAction<any>) => {
        const { accessToken, refreshToken } = payload
        state.accessToken = accessToken
        state.refreshToken = refreshToken
        Cookies.set(ACCESS_TOKEN_NAME, accessToken)
        Cookies.set(REFRESH_TOKEN_NAME, refreshToken)
        setStorageValue(ACCESS_TOKEN_NAME, accessToken)
        setStorageValue(REFRESH_TOKEN_NAME, refreshToken)
        state.isLoading = false
        state.isLogIn = true
      }
    )
    builder.addCase(login.rejected, (state) => {
      state.isLoading = false
    })
    builder.addCase(getCurrentUser.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(
      getCurrentUser.fulfilled,
      (state, { payload }: PayloadAction<IUser>) => {
        state.isLoading = false
        state.user = payload
      }
    )
    builder.addCase(getCurrentUser.rejected, (state) => {
      state.isLoading = false
    })
  }
})

export const { reducer: authReducer, actions: authActions } = authSlice
