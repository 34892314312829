import { FC, memo, useEffect, useMemo } from 'react'
import { Formik } from 'formik'
import {
  ITeacherFields,
  ITeacherForm,
  ITeacherFormProps
} from './TeacherForm.types'
import {
  FormContainer,
  FormImgContainer,
  FormMainContainer,
  FormSupportContainer,
  FormSupportContainerBottom
} from '../../../components/_Page'
import {
  FormImgLoader,
  FormMultySelect,
  FormTextInput
} from '../../../components/_Form'
import { StyledButton } from '../../../components/CustomTable/styles'
import { useAppDispatch, useAppSelector } from '../../../hooks'
import { useTeacherSubmit } from './hooks/useTeacherSubmit'
import { getAdminClasses, getAdminSchools } from '../../../services'

const baseInitialValues: ITeacherForm = {
  avatarPath: undefined,
  fullName: '',
  email: '',
  schoolIds: [],
  classIds: []
}

const TeacherFields: FC<ITeacherFields> = (props) => {
  const {
    initialValues,
    handleSubmit,
    setFieldValue,
    values: { schoolIds, classIds },
    dirty
  } = props

  const isPendingTeacherFrom = useAppSelector(
    (state) => state.teachers.isPendingTeacherFrom
  )

  const {
    data: { schools },
    isPending: isSchoolsPending
  } = useAppSelector((state) => state.schools.schools)

  const {
    data: { adminClasses },
    isPending: isClassesPending
  } = useAppSelector((state) => state.adminClasses.adminClasses)

  const classes = useMemo(() => {
    if (!isClassesPending && schoolIds.length) {
      return adminClasses.filter((classItem) =>
        schoolIds.includes(classItem.school.id)
      )
    }

    return []
  }, [isClassesPending, schoolIds])

  useEffect(() => {
    if (!isClassesPending && adminClasses.length) {
      if (classes.length) {
        setFieldValue(
          'classIds',
          classIds.filter((id) =>
            classes.some((classItem) => classItem.id === id)
          )
        )
      } else {
        setFieldValue('classIds', [])
      }
    }
  }, [isClassesPending, adminClasses, classes])

  return (
    <form onSubmit={handleSubmit}>
      <FormContainer>
        <FormImgContainer width={192}>
          <FormImgLoader<ITeacherForm>
            name={'avatarPath'}
            type={'teacher'}
            currentImgSrc={initialValues?.avatarPath}
          />
        </FormImgContainer>
        <FormMainContainer>
          <FormTextInput<ITeacherForm>
            name={'fullName'}
            label={'Name'}
            placeholder={'Enter full name'}
          />

          <FormTextInput<ITeacherForm>
            name={'email'}
            label={'Email'}
            type={'email'}
            placeholder={'Enter email'}
          />

          <FormTextInput<ITeacherForm>
            name={'password'}
            label={'Password'}
            type={'password'}
            placeholder={'Enter password'}
          />

          <FormMultySelect<ITeacherForm>
            name="schoolIds"
            items={schools.map((school) => ({
              name: school.shortName,
              value: school.id
            }))}
            label="School"
            placeholder="Enter school name"
          />

          <FormMultySelect<ITeacherForm>
            name="classIds"
            items={classes.map((classItem) => ({
              name: classItem.name,
              value: classItem.id
            }))}
            label="Class"
            placeholder="Enter class name"
          />
        </FormMainContainer>
        <FormSupportContainer>
          {/*<FormSupportContainerTop></FormSupportContainerTop>*/}
          <FormSupportContainerBottom>
            <StyledButton
              type={'submit'}
              size={'large'}
              variant={'contained'}
              color={'primary'}
              disabled={
                isPendingTeacherFrom ||
                isSchoolsPending ||
                isClassesPending ||
                !dirty
              }
            >
              Confirm
            </StyledButton>
          </FormSupportContainerBottom>
        </FormSupportContainer>
      </FormContainer>
    </form>
  )
}

export const TeacherForm: FC<ITeacherFormProps> = memo(
  ({
    initialValues = baseInitialValues,
    onSuccess,
    validationSchema,
    type
  }) => {
    const dispatch = useAppDispatch()

    const formSubmit = useTeacherSubmit({
      initialValues,
      onSuccess,
      validationSchema,
      type
    })

    useEffect(() => {
      dispatch(getAdminSchools({ allList: true }))
      dispatch(getAdminClasses({ allList: true }))
    }, [])

    return (
      <Formik<ITeacherForm>
        initialValues={initialValues ?? baseInitialValues}
        onSubmit={formSubmit}
        validationSchema={validationSchema}
      >
        {(data) => <TeacherFields {...data} />}
      </Formik>
    )
  }
)
