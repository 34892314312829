import { memo } from 'react'
import { MenuItem } from '@mui/material'
import { ICustomSelectProps } from './types'
import { StyledSelect, StyledSelectLabel, StyledSelectWrapper } from './styles'

const CustomSelect = memo((props: ICustomSelectProps) => {
  return (
    <StyledSelectWrapper>
      {props.label && <StyledSelectLabel>{props.label}</StyledSelectLabel>}
      <StyledSelect
        value={props.value}
        onChange={props.onChange}
        width={props.width}
        aria-label={props['aria-label']}
        disabled={props.disabled}
        marginRight={props.marginRight}
      >
        {props.options.map((opt) => {
          return (
            <MenuItem key={opt.value} value={opt.value}>
              {opt.name}
            </MenuItem>
          )
        })}
      </StyledSelect>
    </StyledSelectWrapper>
  )
})

export default CustomSelect
