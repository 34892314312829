import { FC, memo } from 'react'
import { Props } from './types'
import { Modal } from '../../../../components/_UI'
import { useAppDispatch, useAppSelector } from '../../../../hooks'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '../../../../core/router/appRoutes'
import { deleteStudent } from '../../../../services'

export const DeleteStudentModal: FC<Props> = memo(({ isOpen, closeModal }) => {
  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const student = useAppSelector((state) => state.students.student.data)

  const handleDelete = () => {
    if (student && student.id) {
      dispatch(deleteStudent({ id: student.id }))
        .unwrap()
        .finally(() => {
          closeModal()
          navigate(APP_ROUTES.STUDENTS)
        })
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      title={'Delete user'}
      onClose={closeModal}
      onSuccess={handleDelete}
      confirmText={'Delete'}
    >
      You are about to delete user, this action is permanent. Do you want to
      proceed?
    </Modal>
  )
})
