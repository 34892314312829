import { AxiosRequestConfig } from 'axios'
import customToast from '../components/CustomToast'
import axios from './interceptors'
import Config from '../config'

interface IPostOptions {
  path: string
  data: { [key: string]: any }
}

interface IAxiosResponse {
  [key: string]: any
}

export default async function post<T>(
  options: IPostOptions,
  params?: any,
  headers?: any,
  includeStatus?: boolean
): Promise<T> {
  const { path, data = {} } = options
  const axiosOptions: AxiosRequestConfig = {
    withCredentials: false,
    params,
    headers
  }

  let res: IAxiosResponse
  try {
    res = await axios.post<IAxiosResponse>(
      `${Config.apiUrl + path}`,
      data,
      axiosOptions
    )
  } catch (e: any) {
    if (e.response.data.message) {
      customToast({ message: e.response.data.message, type: 'error' })
    } else customToast({ message: 'Something went wrong...', type: 'error' })
    throw e.response.data
  }

  if (res.data.error) {
    customToast({ message: 'Something went wrong...', type: 'error' })
    throw res.data.error
  }

  return includeStatus ? { data: res.data, status: res.status } : res.data
}
