export const useAdminTeachersTableHeader = () => {
  return [
    {
      name: 'Name',
      ariaLabel: 'Name',
      cellName: 'fullName',
      sortable: true
    },
    {
      name: 'School',
      ariaLabel: 'School',
      cellName: 'school',
      sortable: false
    },
    {
      name: 'Classes',
      ariaLabel: 'Classes',
      cellName: 'classes',
      sortable: false
    },
    {
      name: 'Students',
      ariaLabel: 'Students',
      cellName: 'students',
      sortable: false
    },
    {
      name: 'Courses',
      ariaLabel: 'Courses',
      cellName: 'courses',
      sortable: false
    },
    {
      name: '',
      ariaLabel: '',
      cellName: 'actions',
      sortable: false
    }
  ]
}
