import { styled } from '@mui/system'
import { Button } from '@mui/material'

export const StyledQuestion = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  gap: '40px 80px',
  maxWidth: '900px'
}))

export const StyledQuestionItem = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '16px',
  flexShrink: '0',
  width: 'calc(50% - 40px)'
}))

export const StyledFieldLabel = styled('span')(({ theme }) => ({
  fontSize: '16px',
  fontWeight: 600,
  paddingLeft: `16px`,
  color: theme.palette.customColors.black
}))

export const StyledDeleteIcon = styled('span')(() => ({
  background: `url(/assets/delete.svg)`,
  width: '24px',
  height: '24px',
  cursor: 'pointer',
  flexShrink: '0'
}))

export const StyledPreviewIcon = styled('span')(() => ({
  background: `url(/assets/eye-icon.svg)`,
  width: '24px',
  height: '24px',
  cursor: 'pointer',
  flexShrink: '0'
}))

export const StyledDeleteForTextField = styled(StyledDeleteIcon)(() => ({
  marginTop: '5px'
}))

export const StyledEmptyDeleteIcon = styled('span')(() => ({
  width: '24px',
  flexShrink: '0'
}))

export const StyledQuestionContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  flexGrow: '1'
}))

export const StyledAnswersContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px'
}))

export const StyledAnswersContainerItem = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  gap: '12px'
}))

export const StyledAddQuestionContainer = styled('div')(() => ({
  marginTop: '40px'
}))

export const StyledSubmitButton = styled(Button)(() => ({
  width: 'fit-content',
  paddingLeft: '70px',
  paddingRight: '70px',
  marginTop: '44px'
}))

export const StyledHintsContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '12px',
  marginTop: '12px'
}))
