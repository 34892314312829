import {
  FC,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useRef,
  useState
} from 'react'
import {
  StyledTitle,
  StyledTitleActionContainers,
  StyledTitleBoxContainer,
  StyledTitleBoxContent,
  StyledTitleBoxMain,
  StyledTitleLogo
} from './PageTitleBox.style'

const defaultLogo = '/assets/defaultLogo.svg'
export const PageTitleBox: FC<
  PropsWithChildren<{
    title: string
    actions: ReactNode
    logoSrc?: string | null
  }>
> = ({ children, title, actions, logoSrc }) => {
  const [isError, setIsError] = useState(false)
  const imgRef = useRef<HTMLImageElement>(null)

  const onErrorHandler = useCallback(() => {
    if (isError || !imgRef.current) return

    setIsError(true)
    imgRef.current.src = defaultLogo
  }, [isError])

  return (
    <StyledTitleBoxContainer>
      {logoSrc !== null && (
        <StyledTitleLogo
          ref={imgRef}
          src={!!logoSrc ? logoSrc : defaultLogo}
          alt={title}
          onError={onErrorHandler}
        />
      )}
      <StyledTitleBoxContent>
        <StyledTitleBoxMain>
          <StyledTitle>{title}</StyledTitle>
          {!!children && <div>{children}</div>}
        </StyledTitleBoxMain>
        {!!actions && (
          <StyledTitleActionContainers>{actions}</StyledTitleActionContainers>
        )}
      </StyledTitleBoxContent>
    </StyledTitleBoxContainer>
  )
}
