import { styled } from '@mui/system'

export const StyledLabelText = styled('div')(() => ({
  fontSize: '14px',
  fontWeight: '500',
  textAlign: 'center',
  margin: '8px auto',
  cursor: 'pointer'
}))

export const ErrorText = styled('div')(({ theme }) => ({
  margin: '3px 14px 0',
  fontSize: '12px',
  color: `${theme.palette.customColors.error}`
}))
