import { formatDuration } from 'date-fns'

export const setStorageValue = (key: string, value: string) => {
  localStorage.setItem(key, value)
}

const getSingleProperty = (object: any, key: any) =>
  object[key] === null || !object.hasOwnProperty(key) ? '' : object[key]

export const getDefaultFormValues = (object: any = {}, keys: any[] = []) =>
  keys.reduce(
    (accumulator, currentKey) => ({
      ...accumulator,
      [currentKey]: getSingleProperty(object, currentKey)
    }),
    {}
  )

const zeroPad = (num: number) => String(num).padStart(2, '0')
export const formatSecondsToHours = (seconds: number) => {
  if (!seconds) {
    return 'N/A'
  }

  const duration = {
    seconds: seconds % 60,
    minutes: Math.floor((seconds / 60) % 60),
    hours: Math.floor(seconds / 3600)
  }

  return formatDuration(duration, {
    format: ['hours', 'minutes', 'seconds'],
    zero: true,
    delimiter: ':',
    locale: {
      formatDistance: (_token, count) => zeroPad(count)
    }
  })
}
