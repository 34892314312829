import { IQuestionForm } from '../QuestionForm.type'
import {
  IAnswerForActivity,
  IQuestionType,
  updateQuestions
} from '../../../../../services'
import { activityActions } from '../../../../../store/activities/activities.reducer'
import { useCallback } from 'react'
import { useAppDispatch } from '../../../../../hooks'

export const useFormQuestionSubmit = (activityId: string) => {
  const dispatch = useAppDispatch()

  return useCallback((values: IQuestionForm) => {
    const questions = values.questions.map(
      ({
        answers,
        wrongAnswers,
        breakpoint,
        text,
        id: questionId,
        hintText
      }) => {
        const answersForRequest: IAnswerForActivity[] = []

        answers.forEach(({ text, id }) => {
          const answerId = id ? { id } : {}
          answersForRequest.push({
            ...answerId,
            text,
            isCorrect: true
          })
        })

        wrongAnswers.forEach(({ text, id }) => {
          const answerId = id ? { id } : {}
          answersForRequest.push({
            ...answerId,
            text,
            isCorrect: false
          })
        })

        const questionRequestId = questionId ? { id: questionId } : {}
        return {
          text,
          type: IQuestionType.voice,
          hintText: hintText ? hintText.trim() : '',
          breakpoint: breakpoint as number,
          answers: answersForRequest,
          ...questionRequestId
        }
      }
    )
    dispatch(updateQuestions({ activityId, questions })).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        dispatch(activityActions.setIsNeedRefetchActivity(true))
      }
    })
  }, [])
}
