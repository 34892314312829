import { FC, useCallback } from 'react'
import { Box, PageTitle } from '../../components/_Page'
import { SchoolForm } from '../_Common'
import { useNavigate } from 'react-router-dom'
import { APP_ROUTES } from '../../core/router/appRoutes'

export const NewSchool: FC = () => {
  const navigate = useNavigate()

  const onSuccessHandler = useCallback(() => {
    navigate(`${APP_ROUTES.SCHOOLS}`)
  }, [])

  return (
    <Box>
      <PageTitle>Add School</PageTitle>
      <SchoolForm onSuccess={onSuccessHandler} />
    </Box>
  )
}
